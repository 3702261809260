<template>
  <div class="drop_down_wrap" :class="[effect]" v-click-outside="onClickOutside">
    <div v-if="title" class="title">
      <span>{{ title }}</span>
      <span v-if="required" class="required">*</span>
    </div>
    <div class="select-wrap" :style="valueStyle">
      <template v-if="options.length <= 0">
        <div class="empty-options">
          <span>{{ $t('no_data') }}</span>
        </div>
      </template>
      <template v-else>
        <button class="value-wrap"
          :disabled="disabled"
          @click="onToggleOption"
        >
          <div class="content-wrap">
            <template v-if="placeholder">
              <span>{{ placeholder }}</span>
            </template>
            <template v-else>
              <img v-if="valueOption ? valueOption.icon : false" :src="valueOption ? valueOption.icon : null" alt="">
              <span>{{ valueOption.label }}</span>
            </template>
          </div>
          <img class="drop-icon" :class="{up: bOption}" src="@/assets/icons/Drop.svg" alt="">
        </button>
        <div v-if="bOption" class="option-wrap">
          <button
            class="option-item"
            v-for="(option, idx) in options"
            :key="`${option.id}_${idx}_${Date.now()}`"
            :disabled="option.disabled"
            :class="{selected: isSelected(option)}"
            @click="onSelect(option)"
          >
            <img v-if="option.icon" :src="option.icon" alt="">
            <span>{{ option.label }}</span>
          </button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'

export default {
  name: 'SingleSelect',
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    title: {
      type: String,
      default: null
    },
    required: { // 與 title 一起使用
      type: Boolean,
      default: false
    },

    value: {
      required: true,
      // type: [String, Number, Array],
      // default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    // multiple: {
    //   type: Boolean,
    //   default: false
    // },
    options: {
      required: true,
      type: Array,
      default() {
        return [
          // {
          //   id: [Number, String],
          //   label: String,
          //   value: any,
          //   disabled: false,
          //   icon: require('url')
          // }
        ]
      }
    },
    placeholder: {
      type: String,
      default: null
    },
    effect: {
      type: String,
      default: 'light', // 'dark' || 'light'
    },
    paddingX: {
      type: Number,
      default: 16, // 1rem
    },
    // paddingY: { // TODO
    //   type: Number,
    //   default: 0,
    // },
  },
  data() {
    return {
      bOption: false,
    }
  },
  computed: {
    valueStyle() {
      return {
        '--paddingX': `${this.paddingX}px`,
        // '--paddingY': `${this.paddingY}px`,
      }
    },
    valueOption() {
      if (this.options.length > 0 && this.value !== null && this.value !== undefined) {
        return this.options.find((_o) => {
          return this.isSelected(_o)
        })
      }

      return {
        // id: [Number, String],
        label: null,
        // value: any,
        // disabled: false,
        icon: null
      }
    }
  },
  watch: {
  },
  methods: {
    isSelected(option) {
      if (Array.isArray(this.value)) {
        return this.value.includes(option.value)
      } else {
        return this.value === option.value
      }
    },
    // 事件
    onClickOutside() {
      this.bOption = false
    },
    onToggleOption() {
      this.bOption = !this.bOption
    },
    onSelect(option) {
      this.$emit('input', option.value)
      this.onClickOutside()
    }
  },
  created() {
    // console.log(`[SingleSelect.created] value:`, this.value)
    // console.log(`[SingleSelect.created] options:`, this.options)
  },
  mounted() {
    // window.addEventListener('resize', this.checkDropdownUp)
  },
  beforeDestroy() {
    // window.removeEventListener('resize', this.checkDropdownUp)
  },
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
  // user-select: none;
}
.drop_down_wrap {
  width: 100%;
  height: 100%;
  color: $color_black;

  .title {
    span {
      font-size: 0.875rem; // 14px
    }
    .required {
      @include required;
    }
  }

  .select-wrap {
    position: relative;
    width: 100%;
    height: 100%;

    &:disabled {
      @include disabled;
    }

    .empty-options {
      display: flex;
      align-items: center;
      padding-left: var(--paddingX);
      padding-right: var(--paddingX);
      width: 100%;
      height: 100%;
      // background-color: #f00;
      color: $color_placeholder;
    }

    .value-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      padding-left: var(--paddingX);
      padding-right: var(--paddingX);
      width: 100%;
      height: 100%;

      &:disabled {
        @include disabled;
      }

      &:not(:disabled) {
        cursor: pointer;
      }

      img {
        width: 1rem;
        height: 1rem;
      }

      .drop-icon {
        @include filter_black;
        transition: $AnimateSec cubic-bezier(0.75, 0.05, 0.07, 1.05);

        &.up {
          transform: rotate(180deg);
        }
      }

      img + span {
        margin-left: 0.5rem;
      }
    }

    .option-wrap {
      position: absolute;
      border-radius: 0.5rem;
      border: 1px solid $color_4A5C78;
      width: 100%;
      // max-height: 30hv;
      color: $color_FFF;
      background-color: $color_151B35;
      z-index: 1;

      .option-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 1rem;
        padding-right: 1rem;
        width: 100%;
        line-height: 2;

        &:first-child {
          border-top-left-radius: 0.5rem;
          border-top-right-radius: 0.5rem;
          padding-top: 0.125rem;
        }
        &:last-child {
          border-bottom-left-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
          padding-bottom: 0.125rem;
        }
        img {
          width: 1rem;
          height: 1rem;
        }
        img + span {
          margin-left: 0.5rem;
        }

        &:hover {
          background-color: $color_4A5C78;
        }

        &.selected {
          background-color: $color_6E7D93;
        }
      }

      &:disabled {
        @include disabled;
      }
    }
  }

  &.dark {
    color: $color_FFF;
    .select-wrap {
      .value-wrap {
        background-color: $color_39425D;
        .drop-icon {
          filter: unset;
        }
      }
    }
  }
}
</style>
