<template>
  <fragment>
    <PropertyItem :title="'發布'" />
    <PropertyItemEdit
      v-if="bEdit"
      :hint="null"
      :hintTooltiop="null"
      :labelIcon="null"
      :label="'啟用'"
      :helper="null"
      :error="null"
    >
      <template #property-value></template>
    </PropertyItemEdit>
    <PropertyItem
      v-else
      :labelIcon="null"
      :label="'啟用'"
      :value="null"
      :valueIcon="null"
      :error="null"
      :helper="null /*require(`@/assets/icons/refresh-white.svg`)*/"
      @helper="() => null"
    />
    <PropertyItemEdit
      v-if="bEdit"
      :hint="null"
      :hintTooltiop="null"
      :labelIcon="null"
      :label="'串流網址'"
      :helper="null"
      :error="null"
    >
      <template #property-value></template>
    </PropertyItemEdit>
    <PropertyItem v-else :label="'串流網址'" :value="null" />
  </fragment>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} from 'vuex'
import PropertyItem from '@/components/AccountManagement/tools/PropertyItem.vue'
import PropertyItemEdit from '@/components/AccountManagement/tools/PropertyItemEdit.vue'

export default {
  name: 'DeviceParamVideoVirtual',
  components: {
    PropertyItem,
    PropertyItemEdit,
  },
  props: {
    bEdit: {
      type: Boolean,
      default: false
    },
    account: {
      type: Object,
      default: null
    },
    config: {
      type: Object,
      default: null
    },
    // ui
    paddingX: {
      type: Number,
      default: 20
    }
  },
  data() {
    return {
      // ...mapState([]),
    }
  },
  computed: {
    ...mapGetters([]),
    ...mapState([]), // state 放這裡可以維持唯一性, 不能用 this.comp = xxx 做修改
    deviceModel() {
      return 'patrol'
      // return 'bovicast'
      // return 'aicam'
      // return 'virtual'
      // return null
    }
  },
  watch: {},
  methods: {
    ...mapActions([]), // API query and update
    ...mapMutations([]),
  },
  created() {},
  mounted() {},
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
  // user-select: none;
}
</style>