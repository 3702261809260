<template>
  <transition name="modal">
    <div class="kanban-modal" @click.self="$emit('close')">
      <KanbanAuo
        :account="account"
        :evtIdx="evtIdx"
        :event="currEvent"
        @next="onNext"
      />
    </div>
  </transition>
</template>

<script>
import {
  apiCheckStatus, apiErrorMsg,
  apiGetBoviaLprEventsDashboard
} from '@/api/index.js'

import KanbanAuo from '@/components/Kanban/KanbanAuo.vue'

const eventLimit = 100

export default {
  name: 'Kanban',
  components: {
    KanbanAuo
  },
  props: {
    account: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      evtIdx: -1,
      eventList: null, // []
    }
  },
  computed: {
    watchList() {
      return this.eventList
    },
    currEvent() {
      return this.evtIdx >= 0 ? this.eventList[this.evtIdx] : null
    },
    latestEvent() {
      return this.eventList[this.eventList.length - 1]
    }
  },
  watch: {},
  methods: {
    // data
    sortTime(evtList) {
      return evtList.sort((a, b) => {
        return (
          new Date(b.detectTime).getTime() - new Date(a.detectTime).getTime()
        )
      })
    },
    async apiLprEventList(afterId) {
      // let list = []
      try {
        let params = {
          matched: 1,
          userIds: [`${this.account.index}`],
          limit: eventLimit
        }
        if (afterId) {
          params = { ...params, ...{ afterId } }
        }
        // console.log(`[apiLprEventList] params:`, params)
        const res = await apiGetBoviaLprEventsDashboard(params)
        if (!apiCheckStatus(res)) throw res

        // console.log(`[apiLprEventList] res.data:`, res.data)
        return this.sortTime(res.data)
      } catch (err) {
        console.error(`[apiLprEventList] err:`, err)
        this.$notify({
          type: 'error',
          // title: '',
          message: err ? this.$t(apiErrorMsg(err)) : this.$t('api_error'),
        })
        return null
      }
    },
    async fetchLprEventList() {
      try {
        let eventPool = []
        let events = []
        do {
          if (eventPool.length <= 0) {
            events = await this.apiLprEventList()
          } else {
            // TODO: 帶入 afterID
            let lastEvt = eventPool[eventPool.length - 1]
            console.log(`[apiLprEventList] lastEvt:`, lastEvt)
            events = await this.apiLprEventList(lastEvt.id)
          }

          if (events) {
            eventPool = [...eventPool, ...events]
          }
        } while (events && events.length >= eventLimit)

        this.eventList = [...JSON.parse(JSON.stringify(eventPool))]

        // ＊ 若有新陌生/VIP車輛 evtIdx = 0; 反之, evtIdx++ 或 重頭開始
        if (this.evtIdx < 0 /*|| oldEventListLen < this.eventList.length*/) {
          this.evtIdx = 0
        } else {
          this.evtIdx =
            this.evtIdx === this.eventList.length - 1 ? 0 : this.evtIdx + 1
        }
      } catch (err) {
        console.error(`[fetchLprEventList] err:`, err)
      }
      // finally {}
    },
    async fetchEventList() {
      await this.fetchLprEventList()
    },
    // 事件
    async onNext() {
      // console.log(`[onNext] oldIdx:`, this.evtIdx, new Date())
      // ＊ 發 API, 要新的資訊(AUO:新的陌生/VIP車輛事件) => 更新 eventList
      await this.fetchEventList()

      console.log(`[onNext] eventList:`, this.eventList)
    }
  },
  // created() {},
  mounted() {
    this.fetchEventList()
  },
  beforeDestroy() {
    this.evtIdx = -1
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
  // user-select: none;
}

.kanban-modal {
  @include modal-wrap;
  z-index: 4;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  color: $color_FFF;
  background-color: $color_black;
}
</style>
