<template>
  <fragment>
    <PropertyItem :title="$t('device_param_setting_disk' /*儲存裝置*/)" />
    <PropertyItemEdit
      v-if="bEdit"
      :label="$t('device_param_setting_disk_threshold' /*保留空間*/)"
    >
      <template #property-value>
        <DeviceParamInputRange
          class="slider"
          v-model="disk.threshold"
          :paddingX="paddingX"
          :disabled="false"
          :label="diskThresholdLabel"
          :min="0.5"
          :max="10.0"
          :step="0.1"
        />
      </template>
    </PropertyItemEdit>
    <PropertyItem
      v-else
      :label="$t('device_param_setting_disk_threshold' /*保留空間*/)"
      :value="diskThresholdLabel"
    />
    <PropertyItemEdit
      v-if="bEdit"
      :label="$t('device_param_setting_disk_cleanup_type' /*刪檔順序*/)"
    >
      <template #property-value>
        <SingleSelect
          class="drop-down"
          :paddingX="paddingX"
          :disabled="false"
          v-model="disk.cleanupType"
          :options="cleanupTypeList"
        />
      </template>
    </PropertyItemEdit>
    <PropertyItem
      v-else
      :label="$t('device_param_setting_disk_cleanup_type' /*刪檔順序*/)"
      :value="optionLabel(cleanupTypeList, disk.cleanupType)"
    />

    <PropertyItem :title="$t('device_param_setting_locate' /*定位*/)" />
    <PropertyItemEdit v-if="bEdit" :label="$t('enable' /*啟用*/)">
      <template #property-value>
        <ToggleSwitch
          class="switch"
          v-model="gps.isEnabled"
          id="gpsIsEnabled"
        />
      </template>
    </PropertyItemEdit>
    <PropertyItem
      v-else
      :label="$t('enable' /*啟用*/)"
      :value="switchLabel(gps.isEnabled)"
      :valueIcon="switchIcon(gps.isEnabled)"
    />
    <PropertyItemEdit
      v-if="bEdit"
      :label="$t('device_param_setting_post_duration' /*最小上傳間隔*/)"
    >
      <template #property-value>
        <DeviceParamInputRange
          class="slider"
          v-model="gps.postDuration"
          :paddingX="paddingX"
          :disabled="false"
          :label="gpsPostDurationLabel"
          :min="gpsPostDurations[0]"
          :max="gpsPostDurations[gpsPostDurations.length - 1]"
          :step="1"
        />
      </template>
    </PropertyItemEdit>
    <PropertyItem
      v-else
      :label="$t('device_param_setting_post_duration' /*最小上傳間隔*/)"
      :value="gpsPostDurationLabel"
    />

    <PropertyItem :title="$t('device_param_setting_log' /*工作日誌*/)" />
    <PropertyItemEdit v-if="bEdit" :label="$t('enable' /*啟用*/)">
      <template #property-value>
        <ToggleSwitch
          class="switch"
          v-model="system.isSavingLog"
          id="systemIsSavingLog"
        />
      </template>
    </PropertyItemEdit>
    <PropertyItem
      v-else
      :label="$t('enable' /*啟用*/)"
      :value="switchLabel(system.isSavingLog)"
      :valueIcon="switchIcon(system.isSavingLog)"
    />
    <!-- <PropertyItemEdit
      v-if="bEdit && false /*TODO*/"
      :label="$t('device_param_setting_log_keep_days' /*保存天數*/)"
    >
      <template #property-value>
        <DeviceParamInputRange
          class="slider"
          v-model="system.logKeepDays"
          :paddingX="paddingX"
          :disabled="false"
          :label="systemLogKeepDaysLabel"
          :min="0"
          :max="10"
          :step="1"
        />
      </template>
    </PropertyItemEdit>
    <PropertyItem
      v-else
      :label="$t('device_param_setting_log_keep_days' /*保存天數*/)"
      :value="systemLogKeepDaysLabel"
    /> -->

    <template v-if="staff">
      <!-- FR -->
      <!-- {{ `fr: ${JSON.stringify(fr)}` }} -->
      <PropertyItem :title="$t('device_param_setting_fr' /*人物*/)" />
      <PropertyItemEdit
        v-if="bEdit"
        :label="$t('device_param_setting_jpg_quality' /*JPG畫質*/)"
      >
        <template #property-value>
          <DeviceParamInputRange
            class="slider"
            v-model="fr.jpgQuality"
            :paddingX="paddingX"
            :disabled="false"
            :label="`${fr.jpgQuality}`"
            :min="jpgQualityRange.min"
            :max="jpgQualityRange.max"
            :step="jpgQualityRange.step"
          />
        </template>
      </PropertyItemEdit>
      <PropertyItem
        v-else
        :label="$t('device_param_setting_jpg_quality' /*JPG畫質*/)"
        :value="`${fr.jpgQuality}`"
      />
      <PropertyItemEdit
        v-if="bEdit"
        :label="$t('device_param_setting_crop_jpg_quality' /*截圖JPG畫質*/)"
      >
        <template #property-value>
          <DeviceParamInputRange
            class="slider"
            v-model="fr.cropJpgQuality"
            :paddingX="paddingX"
            :disabled="false"
            :label="`${fr.cropJpgQuality}`"
            :min="cropJpgQualityRange.min"
            :max="cropJpgQualityRange.max"
            :step="cropJpgQualityRange.step"
          />
        </template>
      </PropertyItemEdit>
      <PropertyItem
        v-else
        :label="$t('device_param_setting_crop_jpg_quality' /*截圖JPG畫質*/)"
        :value="`${fr.cropJpgQuality}`"
      />

      <!-- LPR -->
      <!-- {{ `lpr: ${JSON.stringify(lpr)}` }} -->
      <template v-for="(key, idx) in Object.keys(lpr)">
        <!-- <div :key="`${key}_${idx}_${Date.now()}_debug`" style="color: red">
          {{ `lpr[${key}] ${JSON.stringify(lpr[`${key}`])}` }}
        </div> -->
        <PropertyItem
          :title="$t(`device_param_setting_${key}`)"
          :key="`${key}_${idx}_${Date.now()}_title`"
        />
        <PropertyItemEdit
          v-if="bEdit"
          :label="$t('device_param_setting_jpg_quality' /*JPG畫質*/)"
          :key="`${key}_${idx}_${Date.now()}_jpgQuality_edit`"
        >
          <template #property-value>
            <DeviceParamInputRange
              class="slider"
              v-model="lpr[`${key}`].jpgQuality"
              :paddingX="paddingX"
              :disabled="false"
              :label="`${lpr[`${key}`].jpgQuality}`"
              :min="jpgQualityRange.min"
              :max="jpgQualityRange.max"
              :step="jpgQualityRange.step"
            />
          </template>
        </PropertyItemEdit>
        <PropertyItem
          v-else
          :label="$t('device_param_setting_jpg_quality' /*JPG畫質*/)"
          :value="`${lpr[`${key}`].jpgQuality}`"
          :key="`${key}_${idx}_${Date.now()}_jpgQuality_view`"
        />

        <PropertyItemEdit
          v-if="bEdit"
          :label="$t('device_param_setting_crop_jpg_quality' /*截圖JPG畫質*/)"
          :key="`${key}_${idx}_${Date.now()}_cropJpgQuality_edit`"
        >
          <template #property-value>
            <DeviceParamInputRange
              class="slider"
              v-model="lpr[`${key}`].cropJpgQuality"
              :paddingX="paddingX"
              :disabled="false"
              :label="`${lpr[`${key}`].cropJpgQuality}`"
              :min="cropJpgQualityRange.min"
              :max="cropJpgQualityRange.max"
              :step="cropJpgQualityRange.step"
            />
          </template>
        </PropertyItemEdit>
        <PropertyItem
          v-else
          :label="$t('device_param_setting_crop_jpg_quality' /*截圖JPG畫質*/)"
          :value="`${lpr[`${key}`].cropJpgQuality}`"
          :key="`${key}_${idx}_${Date.now()}_cropJpgQuality_view`"
        />
      </template>

      <!-- OR -->
      <!-- <PropertyItem :title="$t('device_param_setting_or' /*物件*/)" />
      <PropertyItemEdit v-if="bEdit" :label="$t('device_param_setting_jpg_quality' /*JPG畫質*/)">
        <template #property-value>
          <DeviceParamInputRange
            class="slider"
            v-model="or.jpgQuality"
            :paddingX="paddingX"
            :disabled="false"
            :label="`${or.jpgQuality}`"
            :min="jpgQualityRange.min"
            :max="jpgQualityRange.max"
            :step="jpgQualityRange.step"
          />
        </template>
      </PropertyItemEdit>
      <PropertyItem v-else :label="$t('device_param_setting_jpg_quality' /*JPG畫質*/)" :value="`${or.jpgQuality}`" />
      <PropertyItemEdit v-if="bEdit" :label="$t('device_param_setting_crop_jpg_quality' /*截圖JPG畫質*/)">
        <template #property-value>
          <DeviceParamInputRange
            class="slider"
            v-model="or.cropJpgQuality"
            :paddingX="paddingX"
            :disabled="false"
            :label="`${or.cropJpgQuality}`"
            :min="cropJpgQualityRange.min"
            :max="cropJpgQualityRange.max"
            :step="cropJpgQualityRange.step"
          />
        </template>
      </PropertyItemEdit>
      <PropertyItem v-else :label="$t('device_param_setting_crop_jpg_quality' /*截圖JPG畫質*/)" :value="`${or.cropJpgQuality}`" /> -->
    </template>
  </fragment>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} from 'vuex'
import {
  videoCodecList,
  cleanupTypeList,
  switchIcon,
  // parseQuality,
  cropJpgQualityRange,
  jpgQualityRange,
  lprOrder,
  gpsPostDurations,
} from '@/utils/parameter.js'

import PropertyItem from '@/components/AccountManagement/tools/PropertyItem.vue'
import PropertyItemEdit from '@/components/AccountManagement/tools/PropertyItemEdit.vue'
import ToggleSwitch from '@/components/tools/ToggleSwitch.vue'
import SingleSelect from '@/components/Base/SingleSelect.vue'
import DeviceParamInputRange from '@/components/DeviceParam/DeviceParamInputRange.vue'

export default {
  name: 'DeviceParamSettingAicam',
  components: {
    PropertyItem,
    PropertyItemEdit,
    ToggleSwitch,
    SingleSelect,
    DeviceParamInputRange,
  },
  props: {
    bEdit: {
      type: Boolean,
      default: false
    },
    account: {
      type: Object,
      default: null
    },
    config: {
      type: Object,
      default: null
    },
    // ui
    paddingX: {
      type: Number,
      default: 20
    }
  },
  data() {
    return {
      // ...mapState([]),
      cropJpgQualityRange,
      jpgQualityRange,
      gpsPostDurations,

      // data
      disk: {
        threshold: 2.0,
        cleanupType: 1,
      },
      gps: {
        isEnabled: false,
        postDuration: 5,
      },
      system: {
        isSavingLog: false,
        // logKeepDays: 0,
      },
      fr: {
        jpgQuality: 0.9,
        cropJpgQuality: 0.95,
      },
      lpr: {
        // Tw: {
        //   jpgQuality: 0.3,
        //   cropJpgQuality: 0.3,
        // },
        // Jp: {
        //   jpgQuality: 0.3,
        //   cropJpgQuality: 0.3,
        // },
        // Vn: {
        //   jpgQuality: 0.3,
        //   cropJpgQuality: 0.3,
        // },
      },
      // or: {
      //   jpgQuality: 0.3,
      //   cropJpgQuality: 0.3,
      // },
    }
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['staff']), // state 放這裡可以維持唯一性, 不能用 this.comp = xxx 做修改
    // ui ---
    diskThresholdLabel() {
      return `${parseFloat(this.disk.threshold).toFixed(1)} G`
    },
    gpsPostDurationLabel() {
      return this.$t('n_sec', { sec: this.gps.postDuration })
    },
    // systemLogKeepDaysLabel() {
    //   let day = this.system.logKeepDays ? this.system.logKeepDays : 0
    //   return this.$t('n_day', { day })
    // },
    // data ---
    setting() {
      if (!this.config) return null

      return this.config.setting
    },
    // deviceConfig ...
    deviceConfig() {
      if (!this.setting) return null

      return this.setting.deviceConfig
    },
    videoCodecIndex() {
      if (!this.deviceConfig || !this.deviceConfig.publish) return 0

      return this.deviceConfig.publish.videoCodecIndex
    },
    videoCodec() {
      return videoCodecList[this.videoCodecIndex]
    },
    cleanupTypeList() {
      return cleanupTypeList.map((type, idx) => {
        return {
          id: idx,
          label: this.$t(`device_param_setting_disk_cleanup_type_${type}`),
          value: type,
          disabled: false,
          // icon: this.enabledIcon
        }
      })
    },
  },
  watch: {
    bEdit() {
      if (!this.bEdit) {
        this.rollback()
      }
    }
  },
  methods: {
    ...mapActions([]), // API query and update
    ...mapMutations([]),
    // UI
    switchIcon,
    switchLabel(value) {
      return value === true ? this.$t('on') : this.$t('off')
    },
    optionLabel(list, value) {
      if (!list || list.length <= 0) return this.$t('no_data')

      const option = list.find((_o) => _o.value === value)
      return option ? option.label : ''
    },
    // 資料
    initDisk() {
      if (!this.deviceConfig) return

      this.disk = { ...this.deviceConfig.disk }
    },
    initGps() {
      if (!this.deviceConfig) return

      const { isEnabled, postDuration } = this.deviceConfig.gps
      this.gps = {
        isEnabled: isEnabled ? true : false,
        postDuration,
      }
    },
    initSystem() {
      if (!this.deviceConfig) return

      const {
        isSavingLog,
        // logKeepDays
      } = this.deviceConfig.system
      this.system = {
        isSavingLog: isSavingLog ? true : false,
        // logKeepDays: logKeepDays ? logKeepDays : 0
      }
    },
    initFr() {
      if (!this.deviceConfig) return

      const { cropJpgQuality, jpgQuality } = this.deviceConfig.fr
      // console.log(`[initFr] cropJpgQuality, jpgQuality:`, cropJpgQuality, jpgQuality)
      this.fr = {
        cropJpgQuality,
        jpgQuality
      }
    },
    initLpr() {
      if (!this.deviceConfig) return

      // console.log(`[initLpr] lprOrder:`, lprOrder)
      for (const key of lprOrder) {
        if (this.deviceConfig[key]) {
          const { cropJpgQuality, jpgQuality } = this.deviceConfig[key]

          this.lpr[key] = { cropJpgQuality, jpgQuality }
        }
      }

      // console.log(`[initLpr] lpr:`, JSON.stringify(this.lpr))
    },
    // initOr() {
    //   if (!this.deviceConfig) return

    //   const { cropJpgQuality, jpgQuality } = this.deviceConfig.or
    //   // console.log(`[initOr] cropJpgQuality, jpgQuality:`, cropJpgQuality, jpgQuality)
    //   this.or = {
    //     cropJpgQuality,
    //     jpgQuality
    //   }
    // },
    init() {
      // console.log(`[SettingAicam.init] config:`, JSON.stringify(this.config, '', 2))
      // console.log(`[SettingAicam.init] setting:`, JSON.stringify(this.setting, '', 2))
      // console.log(`[SettingAicam.init] deviceConfig:`, JSON.stringify(this.deviceConfig, '', 2))
      console.log(`[DeviceParamSettingAicam.init]`)
      if (!this.setting) return

      this.initDisk()
      this.initGps()
      this.initSystem()
      this.initFr()
      this.initLpr()
      // this.initOr()
    },
    rollback() {
      this.init()
    },
    // 事件
    get() {
      // console.log(`[DeviceParamSettingAicam.get]`)
      let param = {
        disk: { ...this.disk },
        gps: { ...this.gps, ...{ isEnabled: this.gps.isEnabled ? 1 : 0 } },
        system: {
          ...this.system,
          ...{ isSavingLog: this.system.isSavingLog ? 1 : 0 }
        },
        fr: { ...this.fr }
        // lpr[type]: {...this.lpr[type]},
        // or: {...this.or},
      }

      for (const key of Object.keys(this.lpr)) {
        if (!param[key]) {
          param[key] = { ...this.lpr[key] }
        }
      }

      console.log(`[DeviceParamSettingAicam.get] param:`, param)

      return param
    },
  },
  created() {},
  mounted() {
    this.init()
  },
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
$LeftRightPadding: px2rem(20);

* {
  box-sizing: border-box;
  // user-select: none;
}

.switch {
  margin-left: $LeftRightPadding;
}
.drop-down {}
.slider {}
</style>