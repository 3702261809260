<template>
  <fragment>
    <PropertyItem :title="$t('device_param_setting_charging_control' /*充電狀態連動控制*/)" />
    <PropertyItemEdit
      v-if="bEdit"
      :hint="null"
      :hintTooltiop="null"
      :labelIcon="null"
      :label="$t('enable' /*啟用*/)"
      :value="isChargingControl"
      :helper="null"
      :error="null"
    >
      <template #property-value>
        <ToggleSwitch
          class="switch"
          v-model="isChargingControl"
          :id="'isChargingControl'"
        />
      </template>
    </PropertyItemEdit>
    <PropertyItem
      v-else
      :label="$t('enable' /*啟用*/)"
      :value="switchLabel(isChargingControl)"
      :valueIcon="switchIcon(isChargingControl)"
    />

    <PropertyItem :title="$t('device_param_setting_interface' /*介面*/)" />
    <PropertyItemEdit
      v-if="bEdit"
      :label="$t('device_param_setting_btn_long_press_time' /*按鈕長按觸發時間*/)"
      :value="btnLongPressTime"
    >
      <template #property-value>
<!--         <SingleSelect
          class="drop-down"
          :paddingX="paddingX"
          :disabled="false"
          v-model="btnLongPressTime"
          :options="longPressTimeList"
        /> -->
        <DeviceParamInputRange
          class="slider"
          v-model="btnLongPressTime"
          :paddingX="paddingX"
          :disabled="false"
          :label="$t('n_sec', {sec: btnLongPressTime})"
          :min="1"
          :max="5"
          :step="1"
        />
      </template>
    </PropertyItemEdit>
    <PropertyItem
      v-else
      :label="$t('device_param_setting_btn_long_press_time' /*按鈕長按觸發時間*/)"
      :value="optionLabel(longPressTimeList, btnLongPressTime)"
    />

    <PropertyItem :title="$t('device_param_setting_voice_call' /*語音通話*/)" />
    <PropertyItemEdit
      v-if="bEdit"
      :hint="null"
      :hintTooltiop="null"
      :labelIcon="null"
      :label="$t('device_param_setting_auto_answer' /*自動接聽*/)"
      :helper="null"
      :error="null"
    >
      <template #property-value>
        <ToggleSwitch
          class="switch"
          v-model="isAutoAnswer"
          :id="'isAutoAnswer'"
        />
      </template>
    </PropertyItemEdit>
    <PropertyItem
      v-else
      :label="$t('device_param_setting_auto_answer' /*自動接聽*/)"
      :value="switchLabel(isAutoAnswer)"
      :valueIcon="switchIcon(isAutoAnswer)"
    />

    <PropertyItem :title="$t('alarm_announcement' /*公告*/)" />
    <PropertyItemEdit
      v-if="bEdit"
      :hint="null"
      :hintTooltiop="null"
      :labelIcon="null"
      :label="$t('device_param_setting_announcement_notify_type' /*語音播報*/)"
      :helper="null"
      :error="null"
    >
      <template #property-value>
        <SingleSelect
          class="drop-down"
          :paddingX="paddingX"
          :disabled="false"
          v-model="announcementNotifyType"
          :options="announcementNotifyList"
        />
      </template>
    </PropertyItemEdit>
    <PropertyItem
      v-else
      :label="$t('device_param_setting_announcement_notify_type' /*語音播報*/)"
      :value="optionLabel(announcementNotifyList, announcementNotifyType)"
    />

    <PropertyItemEdit
      v-if="bEdit"
      :hint="null"
      :hintTooltiop="null"
      :labelIcon="null"
      :label="$t('device_param_setting_announcement_display_type' /*顯示*/)"
      :helper="null"
      :error="null"
    >
      <template #property-value>
        <SingleSelect
          class="drop-down"
          :paddingX="paddingX"
          :disabled="false"
          v-model="announcementDisplayType"
          :options="announcementDisplayList"
        />
      </template>
    </PropertyItemEdit>
    <PropertyItem
      v-else
      :label="$t('device_param_setting_announcement_display_type' /*顯示*/)"
      :value="optionLabel(announcementDisplayList, announcementDisplayType)"
    />


    <PropertyItem :title="$t('event' /*事件*/)" />
    <PropertyItemEdit
      v-if="bEdit"
      :label="$t('device_param_setting_audio_notify_type' /*提示音*/)"
    >
      <template #property-value>
        <SingleSelect
          class="drop-down"
          :paddingX="paddingX"
          :disabled="false"
          v-model="audioNotifyType"
          :options="audioNotifyList"
        />
      </template>
    </PropertyItemEdit>
    <PropertyItem
      v-else
      :label="$t('device_param_setting_audio_notify_type'/*提示音*/)"
      :value="optionLabel(audioNotifyList, audioNotifyType)"
    />

    <PropertyItemEdit
      v-if="bEdit"
      :label="$t('device_param_setting_announce_mission_note' /*播報任務推播訊息*/)"
    >
      <template #property-value>
        <ToggleSwitch
          class="switch"
          v-model="isAnnounceMissionNote"
          :id="'isAnnounceMissionNote'"
        />
      </template>
    </PropertyItemEdit>
    <PropertyItem
      v-else
      :label="$t('device_param_setting_announce_mission_note' /*播報任務推播訊息*/)"
      :value="switchLabel(isAnnounceMissionNote)"
      :valueIcon="switchIcon(isAnnounceMissionNote)"
    />

    <PropertyItemEdit
      v-if="bEdit"
      :label="$t('device_param_setting_announce_tag_note' /*播報車輛備註訊息*/)"
    >
      <template #property-value>
        <ToggleSwitch
          class="switch"
          v-model="isAnnounceTagNote"
          :id="'isAnnounceTagNote'"
        />
      </template>
    </PropertyItemEdit>
    <PropertyItem
      v-else
      :label="$t('device_param_setting_announce_tag_note' /*播報車輛備註訊息*/)"
      :value="switchLabel(isAnnounceTagNote)"
      :valueIcon="switchIcon(isAnnounceTagNote)"
    />

    <PropertyItemEdit
      v-if="bEdit"
      :hint="null"
      :hintTooltiop="null"
      :labelIcon="null"
      :label="$t('device_param_setting_chase_event_popup_time' /*圍捕視窗自動關閉*/)"
      :helper="null"
      :error="null"
    >
      <template #property-value>
        <DeviceParamInputRange
          class="slider"
          v-model="chaseEventPopupTime"
          :paddingX="paddingX"
          :disabled="false"
          :label="$t('n_sec', {sec: chaseEventPopupTime})"
          :min="1"
          :max="600"
          :step="1"
        />
      </template>
    </PropertyItemEdit>
    <PropertyItem
      v-else :label="$t('device_param_setting_chase_event_popup_time' /*圍捕視窗自動關閉*/)"
      :value="$t('n_sec', {sec: chaseEventPopupTime})"
    />

    <PropertyItemEdit
      v-if="bEdit"
      :hint="null"
      :hintTooltiop="null"
      :labelIcon="null"
      :label="$t('device_param_setting_jpg_quality' /*JPG畫質*/)"
      :helper="null"
      :error="null"
    >
      <template #property-value>
        <DeviceParamInputRange
          class="slider"
          v-model="jpgQuality"
          :paddingX="paddingX"
          :disabled="false"
          :label="`${jpgQuality}`"
          :min="0.01"
          :max="0.99"
          :step="0.01"
        />
      </template>
    </PropertyItemEdit>
    <PropertyItem v-else :label="$t('device_param_setting_jpg_quality' /*JPG畫質*/)" :value="`${jpgQuality}`" />

    <PropertyItem :title="$t('device_param_setting_diagnose' /*診斷*/)" />
    <PropertyItemEdit
      v-if="bEdit"
      :hint="null"
      :hintTooltiop="null"
      :labelIcon="null"
      :label="$t('device_param_setting_min_audio_volume' /*最小提醒音量*/)"
      :helper="null"
      :error="null"
    >
      <template #property-value>
        <DeviceParamInputRange
          class="slider"
          v-model="minAudioVolume"
          :paddingX="paddingX"
          :disabled="false"
          :label="`${minAudioVolume}`"
          :min="0"
          :max="1"
          :step="0.1"
        />
      </template>
    </PropertyItemEdit>
    <PropertyItem v-else :label="$t('device_param_setting_min_audio_volume' /*最小提醒音量*/)" :value="`${minAudioVolume}`" />

  </fragment>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} from 'vuex'

import {
  switchIcon,
  audioNotifyList,
} from '@/utils/parameter.js'

import PropertyItem from '@/components/AccountManagement/tools/PropertyItem.vue'
import PropertyItemEdit from '@/components/AccountManagement/tools/PropertyItemEdit.vue'
import ToggleSwitch from '@/components/tools/ToggleSwitch.vue'
import SingleSelect from '@/components/Base/SingleSelect.vue'
import DeviceParamInputRange from '@/components/DeviceParam/DeviceParamInputRange.vue'

export default {
  name: 'DeviceParamSettingPatrol',
  components: {
    PropertyItem,
    PropertyItemEdit,
    ToggleSwitch,
    SingleSelect,
    DeviceParamInputRange,
  },
  props: {
    bEdit: {
      type: Boolean,
      default: false
    },
    account: {
      type: Object,
      default: null
    },
    aiModelType: {
      type: String,
      default: null
    },
    config: {
      type: Object,
      default: null
    },
    // ui
    paddingX: {
      type: Number,
      default: 20
    }
  },
  data() {
    return {
      // 充電狀態連動控制
      isChargingControl: false, // 充電狀態連動控制>啟用: 0(否),1(是)

      // 介面
      btnLongPressTime: 2, // 介面>長按觸發時間: 1~5 / 2

      // 語音通話
      isAutoAnswer: false, // 語音通話>自動接聽: 0(否),1(是)

      // 公告
      announcementNotifyType: 1, // 公告>語音播報: 0(無),1(語音),2(提示音) / 1(語音)
      announcementDisplayType: 2, // 公告>顯示: 0(不顯示),1(顯示一次),2(永遠) / 2(永遠)

      // 事件
      audioNotifyType: 0, // 事件>提示音: 0(無),1(警示音),2(語音提示)
      isAnnounceMissionNote: false, // 事件>播報任務推播訊息: 0(否),1(是)
      isAnnounceTagNote: false, // 事件>播報車輛備註訊息: 0(否),1(是)
      chaseEventPopupTime: 180, // 事件>圍捕視窗自動關閉: 1~600 / 180
      jpgQuality: 0.01, // 事件>JPG畫質: 0.01 ~ 0.99

      // 診斷
      minAudioVolume: 0, // 最小音量提醒: 0 ~ 1
    }
  },
  computed: {
    ...mapGetters([]),
    ...mapState([]), // state 放這裡可以維持唯一性, 不能用 this.comp = xxx 做修改
    enabledIcon() {
      return require(`@/assets/icons/success-check.svg`)
    },
    disabledIcon() {
      return require(`@/assets/icons/Error.svg`)
    },
    longPressTimeList() {
      return [1, 2, 3, 4, 5].map((item) => {
        return {
            id: item,
            label: this.$t(`n_sec`, {sec: item}),
            value: item,
            disabled: false,
            // icon: this.enabledIcon
        }
      })
    },
    announcementNotifyList() {
      // 公告>語音播報: 0(無),1(語音),2(提示音) / 1(語音)
      return [0, 1, 2].map((item) => {
        return {
          id: item,
          label: this.$t(`device_param_setting_announcement_notify_type_${item}`),
          value: item,
          disabled: false,
          // icon: this.enabledIcon
        }
      })
    },
    announcementDisplayList() {
      // 公告>顯示: 0(不顯示),1(顯示一次),2(永遠) / 2(永遠)
      return [0, 1, 2].map((item) => {
        return {
            id: item,
            label: this.$t(`device_param_setting_announcement_display_type_${item}`),
            value: item,
            disabled: false,
            // icon: this.enabledIcon
        }
      })
    },
    audioNotifyList() {
      // 事件>提示音: 0(無),1(警示音),2(語音提示)
      return audioNotifyList.map((item) => {
        return {
          id: item,
          label: this.$t(`device_param_setting_audio_notify_type_${item}`),
          value: item,
          disabled: false,
          // icon: this.enabledIcon
        }
      })
    },
    setting() {
      if (!this.config) return null

      return this.config.setting
    },
    deviceConfig() {
      if (!this.setting) return null

      return this.setting.deviceConfig
    },
  },
  watch: {
    bEdit() {
      if (!this.bEdit) {
        this.rollback()
      }
    }
  },
  methods: {
    ...mapActions([]), // API query and update
    ...mapMutations([]),
    // UI
    switchIcon,
    switchLabel(value) {
      return value === true ? this.$t('on') : this.$t('off')
    },
    // 資料
    init() {
      // console.log(`[init] config:`, JSON.stringify(this.config, '', 2))
      // console.log(`[init] setting:`, JSON.stringify(this.setting, '', 2))
      // console.log(`[init] deviceConfig:`, JSON.stringify(this.deviceConfig, '', 2))
      if (this.deviceConfig) {
        const {
          isChargingControl,
          announcementNotifyType,
          announcementDisplayType,
          isAnnounceMissionNote,
          isAnnounceTagNote,
          chaseEventPopupTime,
          jpgQuality,
          minAudioVolume
        } = this.deviceConfig[`lpr${this.aiModelType}`]
        const { btnLongPressTime } = this.deviceConfig.system
        const { isAutoAnswer } = this.deviceConfig.webrtc
        const { audioNotifyType } = this.deviceConfig.notify

        // 充電狀態連動控制
        this.isChargingControl = isChargingControl ? true : false
        // 介面
        this.btnLongPressTime = Number(btnLongPressTime)
        // 語音通話
        this.isAutoAnswer = isAutoAnswer ? true : false
        // 公告
        this.announcementNotifyType = announcementNotifyType
        this.announcementDisplayType = announcementDisplayType
        // 事件
        this.audioNotifyType = audioNotifyType
        this.isAnnounceMissionNote = isAnnounceMissionNote ? true : false
        this.isAnnounceTagNote = isAnnounceTagNote ? true : false
        this.chaseEventPopupTime = Number(chaseEventPopupTime)
        this.jpgQuality = Number(jpgQuality)
        // 診斷
        this.minAudioVolume = Number(minAudioVolume)
      }
    },
    rollback() {
      this.init()
    },
    optionLabel(list, value) {
      if (!list || list.length <= 0) return null

      return list.find((_o) => _o.value === value)?.label
    },
    // 事件
    get() {
      return {
        // 充電狀態連動控制
        isChargingControl: this.isChargingControl ? 1 : 0,

        // 介面
        btnLongPressTime: this.btnLongPressTime,

        // 語音通話
        isAutoAnswer: this.isAutoAnswer ? 1 : 0,

        // 公告
        announcementNotifyType: this.announcementNotifyType,
        announcementDisplayType: this.announcementDisplayType,

        // 事件
        audioNotifyType: this.audioNotifyType,
        isAnnounceMissionNote: this.isAnnounceMissionNote ? 1 : 0,
        isAnnounceTagNote: this.isAnnounceTagNote ? 1 : 0,
        chaseEventPopupTime: this.chaseEventPopupTime,
        jpgQuality: this.jpgQuality,

        // 診斷
        minAudioVolume: this.minAudioVolume,
      }
    },
  },
  created() {},
  mounted() {
    this.init()
  },
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
$LeftRightPadding: px2rem(20);

* {
  box-sizing: border-box;
  // user-select: none;
}

.switch {
  margin-left: $LeftRightPadding;
}
.drop-down {}
.slider {}
</style>