<template>
  <div class="param-setting-wrap">
    <DeviceParamSettingPatrol
      :ref="deviceModelClass.patrol"
      v-if="deviceModel === deviceModelClass.patrol"
      :bEdit="bEdit"
      :account="account"
      :aiModelType="aiModelType"
      :config="config"
      :paddingX="20 /*UI*/"
    />
    <DeviceParamSettingBovicast
      :ref="deviceModelClass.bovicast"
      v-else-if="deviceModel === deviceModelClass.bovicast"
      :bEdit="bEdit"
      :account="account"
      :aiModelType="aiModelType"
      :config="config"
      :paddingX="20 /*UI*/"
    />
    <DeviceParamSettingAicam
      :ref="deviceModelClass.aicam"
      v-else-if="deviceModel === deviceModelClass.aicam"
      :bEdit="bEdit"
      :account="account"
      :aiModelType="aiModelType"
      :config="config"
      :paddingX="20 /*UI*/"
    />
    <div v-else>{{ `Not Support ${deviceModelId}` }}</div>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} from 'vuex'

import {
  deviceModelClass,
  // iosBitrateModeList,
  // androidBitrateModeList,
} from '@/utils/parameter.js'
import DeviceParamSettingPatrol from '@/components/DeviceParam/setting/DeviceParamSettingPatrol.vue';
import DeviceParamSettingBovicast from '@/components/DeviceParam/setting/DeviceParamSettingBovicast.vue';
import DeviceParamSettingAicam from '@/components/DeviceParam/setting/DeviceParamSettingAicam.vue';

export default {
  name: 'DeviceParamSetting',
  components: {
    DeviceParamSettingPatrol,
    DeviceParamSettingBovicast,
    DeviceParamSettingAicam,
  },
  props: {
    bEdit: {
      type: Boolean,
      default: false
    },
    account: {
      type: Object,
      default: null
    },
    deviceModel: {
      type: String,
      default: null
    },
    deviceModelId: {
      type: Number,
      default: 0
    },
    aiModelType: {
      type: String,
      default: null
    },
    config: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      // ...mapState([]),
    }
  },
  computed: {
    ...mapGetters([]),
    ...mapState([]),
    deviceModelClass() {
      return deviceModelClass
    },
  },
  watch: {},
  methods: {
    ...mapActions([]), // API query and update
    ...mapMutations([]),
    get() {
      return this.$refs[this.deviceModel].get()
    }
  },
  created() {},
  mounted() {
    console.log(`[DeviceParamSetting] config:`, this.config)
  },
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
  // user-select: none;
}
.param-setting-wrap {
  margin-right: px2rem(2);
  padding-left: px2rem(32);
  padding-right: px2rem(6); // mr-2 + pr-6
  padding-bottom: 0.5rem;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  // background-color: #00f;
}
</style>