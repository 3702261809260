<template>
  <fragment>
    <PropertyItem :title="$t('device_param_video_publish' /*發布*/)" />
    <PropertyItemEdit
      v-if="bEdit"
      :hint="null"
      :hintTooltiop="null"
      :labelIcon="null"
      :label="$t('device_param_video_channel_index' /*發布頻道*/)"
      :helper="null"
      :error="null"
    >
      <template #property-value>
        <SingleSelect
          class="drop-down"
          :paddingX="paddingX"
          :disabled="false"
          v-model="channelIndex"
          :options="channelList"
        />
      </template>
    </PropertyItemEdit>
    <PropertyItem
      v-else
      :label="$t('device_param_video_channel_index' /*發布頻道*/)"
      :value="channelLabel(channelIndex + 1)"
    />

    <PropertyItemEdit
      v-if="bEdit"
      :label="$t('device_param_video_auto_start' /*自動發布*/)"
    >
      <template #property-value>
        <ToggleSwitch
          class="switch"
          v-model="isAutoStart"
          :id="'isAutoStart'"
        />
      </template>
    </PropertyItemEdit>
    <PropertyItem
      v-else
      :label="$t('device_param_video_auto_start' /*自動發布*/)"
      :value="switchLabel(isAutoStart)"
      :valueIcon="switchIcon(isAutoStart)"
    />

    <PropertyItemEdit
      v-if="bEdit"
      :label="$t('device_param_video_audio_mute' /*靜音*/)"
    >
      <template #property-value>
        <ToggleSwitch
          class="switch"
          v-model="isAudioMute"
          :id="'isAudioMute'"
        />
      </template>
    </PropertyItemEdit>
    <PropertyItem
      v-else
      :labelIcon="null"
      :label="$t('device_param_video_audio_mute' /*靜音*/)"
      :value="switchLabel(isAudioMute)"
      :valueIcon="switchIcon(isAudioMute)"
    />
    <PropertyItemEdit
      v-if="bEdit"
      :label="$t('device_param_video_code_codec' /*影像編碼*/)"
    >
      <template #property-value>
        <SingleSelect
          class="drop-down"
          :paddingX="paddingX"
          :disabled="false"
          v-model="videoCodecIndex"
          :options="videoCodecList"
        />
      </template>
    </PropertyItemEdit>
    <PropertyItem
      v-else
      :label="$t('device_param_video_code_codec' /*影像編碼*/)"
      :value="videoCodecLabel"
    />
    <template v-if="videoCodecIndex === 1">
      <!-- H.265 -->
      <PropertyItemEdit
        v-if="bEdit"
        :label="
          $t('device_param_video_quality' /*品質*/, { codec: videoCodecLabel })
        "
      >
        <template #property-value>
          <SingleSelect
            class="drop-down"
            :paddingX="paddingX"
            :disabled="false"
            v-model="profileIndex265"
            :options="profileList265"
          />
        </template>
      </PropertyItemEdit>
      <PropertyItem
        v-else
        :label="
          $t('device_param_video_quality' /*品質*/, { codec: videoCodecLabel })
        "
        :value="valueLabel(profileList265, profileIndex265)"
      />
    </template>
    <template v-else>
      <!-- H.264 -->
      <PropertyItemEdit
        v-if="bEdit"
        :label="
          $t('device_param_video_quality' /*品質*/, { codec: videoCodecLabel })
        "
      >
        <template #property-value>
          <SingleSelect
            class="drop-down"
            :paddingX="paddingX"
            :disabled="false"
            v-model="profileIndex"
            :options="profileList"
          />
        </template>
      </PropertyItemEdit>
      <PropertyItem
        v-else
        :label="
          $t('device_param_video_quality' /*品質*/, { codec: videoCodecLabel })
        "
        :value="valueLabel(profileList, profileIndex)"
      />
    </template>
    <PropertyItemEdit
      v-if="bEdit"
      :label="$t('device_param_video_bitrate_mode' /*位元控制率模式*/)"
    >
      <template #property-value>
        <SingleSelect
          class="drop-down"
          :paddingX="paddingX"
          :disabled="false"
          v-model="bitrateModeIndex"
          :options="bitrateModeList"
        />
      </template>
    </PropertyItemEdit>
    <PropertyItem
      v-else
      :label="$t('device_param_video_bitrate_mode' /*位元控制率模式*/)"
      :value="valueLabel(bitrateModeList, bitrateModeIndex)"
    />
  </fragment>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} from 'vuex'
// import { 
//   // formatTime,
//   getResolution,
//   formatBitrate,
// } from '@/utils/lib.js'

import {
  switchIcon,
  videoCodecList,
  parseQuality
} from '@/utils/parameter.js'
import PropertyItem from '@/components/AccountManagement/tools/PropertyItem.vue'
import PropertyItemEdit from '@/components/AccountManagement/tools/PropertyItemEdit.vue'
import ToggleSwitch from '@/components/tools/ToggleSwitch.vue'
import SingleSelect from '@/components/Base/SingleSelect.vue'
// import DeviceParamInputRange from '@/components/DeviceParam/DeviceParamInputRange.vue'

export default {
  name: 'DeviceParamVideoPatrol',
  components: {
    PropertyItem,
    PropertyItemEdit,
    ToggleSwitch,
    SingleSelect,
    // DeviceParamInputRange,
  },
  props: {
    bEdit: {
      type: Boolean,
      default: false
    },
    account: {
      type: Object,
      default: null
    },
    // aiModelType: {
    //   type: String,
    //   default: null
    // },
    config: {
      type: Object,
      default: null
    },
    deviceModelId: {
      type: Number,
      default: 0
    },
    // ui
    paddingX: {
      type: Number,
      default: 20
    }
  },
  data() {
    return {
      // 發布 => deviceConfig.publish: {}
      channelIndex: 0, // 發布>發布頻道: 0(頻道一),1(頻道二)
      isAutoStart: 1, // 發布>自動發布: 0(否),1(是)
      isAudioMute: 0, // 發布>靜音(發布時麥克風靜音): 0(否),1(是)
      videoCodecIndex: 0, // 發布>影像編碼: 0(H264),1(H265)
      profileIndex: 1, // 發布>品質(H264直播影像品質): 依據 profileList
      profileIndex265: 1, // 發布>品質(H265直播影像品質): 依據 profileList265
      bitrateModeIndex: 0, // 發布>位元控制率模式: 依據 bitrateModeList
    }
  },
  computed: {
    ...mapGetters([]),
    ...mapState([]), // state 放這裡可以維持唯一性, 不能用 this.comp = xxx 做修改
    // UI ---
    // data ---
    setting() {
      if (!this.config) return null

      return this.config.setting
    },
    deviceConfig() {
      if (!this.setting) return null

      return this.setting.deviceConfig
    },
    publish() {
      if (!this.deviceConfig) return null

      return this.deviceConfig.publish
    },
    channelList() { // 頻道列表
      if (!this.setting) return []

      return this.setting.channel.map((item, idx) => {
        return {
          id: idx+1,
          label: this.channelLabel(idx+1),
          value: idx,
          disabled: false,
          // icon: this.enabledIcon
        }
      })
    },
    videoCodecList() { // 解碼列表
      return videoCodecList.map((codec, idx) => {
        return {
          id: idx,
          label: codec,
          value: idx,
          disabled: false,
          // icon: this.enabledIcon
        }
      })
    },
    videoCodecLabel() {
      let noData = this.videoCodecIndex === undefined
      let idx = !noData ? this.videoCodecIndex : 0 // 處理沒資料的顯示
      let label = this.valueLabel(this.videoCodecList, idx)
      return `${label}`
    },
    profileList() { // H.264 品質清單
      if (!this.publish || !this.publish.profileList) return []

      return this.publish.profileList.map((item, idx) => {
        return {
          id: idx,
          label: this.parseQuality(item),
          value: idx,
          disabled: false,
          // icon: this.enabledIcon
        }
      })
    },
    profileList265() { // H.265 品質清單
      if (!this.publish || !this.publish.profileList265) return []

      return this.publish.profileList265.map((item, idx) => {
        return {
          id: idx,
          label: this.parseQuality(item),
          value: idx,
          disabled: false,
          // icon: this.enabledIcon
        }
      })
    },
    bitrateModeList() { // 位元率控制模式清單
      // console.log(`[VideoPatrol.bitrateModeList] publish:`, JSON.stringify(this.publish, '', 2))
      if (!this.publish || !this.publish.bitrateModeList) return []

      return this.publish.bitrateModeList.map((item, idx) => {
        return {
          id: idx,
          label: item,
          value: idx,
          disabled: false,
          // icon: this.enabledIcon
        }
      })
    },
  },
  watch: {
    bEdit() {
      if (!this.bEdit) {
        this.rollback()
      }
    }
  },
  methods: {
    ...mapActions([]), // API query and update
    ...mapMutations([]),
    // ui
    channelLabel(idx) {
      return `CH${idx}`
    },
    valueLabel(list, value) {
      if (!list || list.length <= 0) return this.$t('no_data')

      const option = list.find((_o) => _o.value === value)
      return option ? option.label : ''
    },
    parseQuality(quality) {
      return parseQuality(quality)
    },
    switchLabel(value) {
      return value === true ? this.$t('on') : this.$t('off')
    },
    switchIcon,
    // 資料
    init() {
      // console.log(`[VideoPatrol.init] config:`, JSON.stringify(this.config, '', 2))
      // console.log(`[VideoPatrol.init] setting:`, JSON.stringify(this.setting, '', 2))
      // console.log(`[VideoPatrol.init] deviceConfig:`, JSON.stringify(this.deviceConfig, '', 2))
      if (!this.publish) return

      const {
        channelIndex,
        isAutoStart,
        isAudioMute,
        videoCodecIndex,
        profileIndex,
        profileIndex265,
        bitrateModeIndex,
      } = this.publish

      this.channelIndex = channelIndex;
      this.isAutoStart = isAutoStart ? true : false
      this.isAudioMute = isAudioMute ? true : false
      this.videoCodecIndex = videoCodecIndex
      this.profileIndex = profileIndex
      this.profileIndex265 = profileIndex265
      this.bitrateModeIndex = bitrateModeIndex

      console.log(`[init] publish:`, this.publish)
    },
    rollback() {
      this.init()
    },
    // 事件
    get() {
      return {
        channelIndex: this.channelIndex,
        isAutoStart: this.isAutoStart ? 1 : 0,
        isAudioMute: this.isAudioMute ? 1 : 0,
        videoCodecIndex: this.videoCodecIndex,
        profileIndex: this.profileIndex,
        profileIndex265: this.profileIndex265,
        bitrateModeIndex: this.bitrateModeIndex
      }
    }
  },
  created() {},
  mounted() {
    this.init()
  },
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
$LeftRightPadding: px2rem(20);

* {
  box-sizing: border-box;
  // user-select: none;
}
.switch {
  margin-left: $LeftRightPadding;
}
.drop-down {}
.slider {}
</style>
