<template>
  <div class="input-range-wrap" :style="wrapStyle">
    <div v-if="label" class="value-wrap">
      <span>{{ label }}</span>
    </div>
    <div class="slider-wrap">
      <div class="min">
        <span>{{ min }}</span>
      </div>
      <Slider
        v-model="range"
        :disabled="disabled"
        :min="min"
        :max="max"
        :step="step"
      />
      <div class="max">
        <span>{{ max }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} from 'vuex'

import Slider from '@/components/Base/Slider.vue'

export default {
  name: 'DeviceParamInputRange',
  components: {
    Slider,
  },
  props: {
    value: {
      required: true,
      type: Number,
      default: null
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    step: {
      type: Number,
      default: 1
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    paddingX: {
      type: Number,
      default: 16, // 1rem
    },
    // paddingY: { // TODO
    //   type: Number,
    //   default: 0,
    // },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([]),
    ...mapState([]), // state 放這裡可以維持唯一性, 不能用 this.comp = xxx 做修改
    range: {
      get() {
        return Number(this.value)
      },
      set(value) {
        this.$emit('input', Number(value))
      }
    },
    wrapStyle() {
      return {
        '--paddingX': `${this.paddingX}px`,
        // '--paddingY': `${this.paddingY}px`,
      }
    },
  },
  watch: {
  },
  methods: {
    ...mapActions([]), // API query and update
    ...mapMutations([]),
  },
  created() {},
  mounted() {},
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
// $PaddingX: var(--paddingX);
* {
  box-sizing: border-box;
  // user-select: none;
}

.input-range-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: var(--paddingX);
  padding-right: var(--paddingX);
  width: 100%;
  // background-color: #0f0;

  .value-wrap {
    // background-color: #f00;
  }

  .slider-wrap {
    display: flex;
    align-items: center;
    // background-color: #00f;

    .min {
      padding-right: 0.5rem;
      font-size: 0.75rem;
      line-height: 1rem;
    }
    .max {
      padding-left: 0.5rem;
      font-size: 0.75rem;
      line-height: 1rem;
    }
  }
}
</style>
