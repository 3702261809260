<template>
  <fragment>
    <!-- <template v-if="staff">
      <PropertyItem v-if="bDevMode" :label="'影像編碼'" :value="videoCodec" />
    </template> -->
    <!-- 發布 -->
    <PropertyItem :title="$t('device_param_video_publish' /*發布*/)" />
    <PropertyItemEdit v-if="bEdit" :label="$t('enable' /*啟用*/)">
      <template #property-value>
        <ToggleSwitch
          class="switch"
          v-model="publish.isEnabled"
          :id="`publishIsEnabled`"
        />
        <!-- {{ `publish.isEnabled: ${publish.isEnabled}` }} -->
      </template>
    </PropertyItemEdit>
    <PropertyItem
      v-else
      :label="$t('enable' /*啟用*/)"
      :value="switchLabel(publish.isEnabled)"
      :valueIcon="switchIcon(publish.isEnabled)"
    />
    <PropertyItemEdit
      v-if="bEdit"
      :label="$t('device_param_video_audio_mute' /*靜音*/)"
    >
      <template #property-value>
        <ToggleSwitch
          class="switch"
          v-model="publish.isAudioMute"
          :id="`publishIsAudioMute`"
        />
        <!-- {{ `publish.isAudioMute: ${publish.isAudioMute}` }} -->
      </template>
    </PropertyItemEdit>
    <PropertyItem
      v-else
      :label="$t('device_param_video_audio_mute' /*靜音*/)"
      :value="switchLabel(publish.isAudioMute)"
      :valueIcon="switchIcon(publish.isAudioMute)"
    />
    <PropertyItemEdit
      v-if="bEdit"
      :label="$t('device_param_video_osd') /*OSD*/"
    >
      <template #property-value>
        <ToggleSwitch
          class="switch"
          v-model="publish.isOsdEnabled"
          :id="`publishIsOsdEnabled`"
        />
        <!-- {{ `publish.isOsdEnabled: ${publish.isOsdEnabled}` }} -->
      </template>
    </PropertyItemEdit>
    <PropertyItem
      v-else
      :label="$t('device_param_video_osd') /*OSD*/"
      :value="switchLabel(publish.isOsdEnabled)"
      :valueIcon="switchIcon(publish.isOsdEnabled)"
    />
    <template v-if="publish.isOsdEnabled">
      <PropertyItemEdit
        v-if="bEdit"
        :label="$t('device_param_video_osd_date_enabled') /*時間*/"
      >
        <template #property-value>
          <ToggleSwitch
            class="switch"
            v-model="osd.isDateEnabled"
            :id="`osdIsDateEnabled`"
          />
        </template>
      </PropertyItemEdit>
      <PropertyItem
        v-else
        :label="$t('device_param_video_osd_date_enabled') /*時間*/"
        :value="switchLabel(osd.isDateEnabled)"
        :valueIcon="switchIcon(osd.isDateEnabled)"
      />
      <!-- isAiCamBFV -->
      <template v-if="isAiCamBFV">
        <PropertyItemEdit
          v-if="bEdit"
          :label="$t('device_param_video_osd_decibel_enabled' /*分貝計數值*/)"
        >
          <template #property-value>
            <ToggleSwitch
              class="switch"
              v-model="osd.isDecibelEnabled"
              :id="`osdIsDecibelEnabled`"
            />
          </template>
        </PropertyItemEdit>
        <PropertyItem
          v-else
          :label="$t('device_param_video_osd_decibel_enabled' /*分貝計數值*/)"
          :value="switchLabel(osd.isDecibelEnabled)"
          :valueIcon="switchIcon(osd.isDecibelEnabled)"
        />
      </template>
      <PropertyItemEdit
        v-if="bEdit"
        :label="$t('device_param_video_osd_signal_info_enabled' /*訊號強度*/)"
      >
        <template #property-value>
          <ToggleSwitch
            class="switch"
            v-model="osd.isSignalInfoEnabled"
            :id="`osdIsSignalInfoEnabled`"
          />
        </template>
      </PropertyItemEdit>
      <PropertyItem
        v-else
        :label="$t('device_param_video_osd_signal_info_enabled' /*訊號強度*/)"
        :value="switchLabel(osd.isSignalInfoEnabled)"
        :valueIcon="switchIcon(osd.isSignalInfoEnabled)"
      />
      <PropertyItemEdit
        v-if="bEdit"
        :label="
          $t(
            'device_param_video_osd_battery_level_percentage_enabled' /*電池電量(百分比)*/
          )
        "
      >
        <template #property-value>
          <ToggleSwitch
            class="switch"
            v-model="osd.isBatteryLevelPercentageEnabled"
            :id="`osdIsBatteryLevelPercentageEnabled`"
          />
        </template>
      </PropertyItemEdit>
      <PropertyItem
        v-else
        :label="
          $t(
            'device_param_video_osd_battery_level_percentage_enabled' /*電池電量(百分比)*/
          )
        "
        :value="switchLabel(osd.isBatteryLevelPercentageEnabled)"
        :valueIcon="switchIcon(osd.isBatteryLevelPercentageEnabled)"
      />
      <PropertyItemEdit
        v-if="bEdit"
        :label="
          $t(
            'device_param_video_osd_battery_voltage_enabled' /*電池電量(電壓值)*/
          )
        "
      >
        <template #property-value>
          <ToggleSwitch
            class="switch"
            v-model="osd.isBatteryVoltageEnabled"
            :id="`osdIsBatteryVoltageEnabled`"
          />
        </template>
      </PropertyItemEdit>
      <PropertyItem
        v-else
        :label="
          $t(
            'device_param_video_osd_battery_voltage_enabled' /*電池電量(電壓值)*/
          )
        "
        :value="switchLabel(osd.isBatteryVoltageEnabled)"
        :valueIcon="switchIcon(osd.isBatteryVoltageEnabled)"
      />
    </template>

    <template v-if="staff">
      <PropertyItemEdit
        v-if="bEdit"
        :label="$t('device_param_video_customize_quality' /*自訂品質*/)"
      >
        <template #property-value>
          <ToggleSwitch
            class="switch"
            v-model="publish.isCustomProfileEnabled"
            :id="`publishIsCustomProfileEnabled`"
          />
          <!-- {{ `publish.isCustomProfileEnabled: ${publish.isCustomProfileEnabled}` }} -->
        </template>
      </PropertyItemEdit>
      <PropertyItem
        v-else
        :label="$t('device_param_video_customize_quality' /*自訂品質*/)"
        :value="switchLabel(publish.isCustomProfileEnabled)"
        :valueIcon="switchIcon(publish.isCustomProfileEnabled)"
      />
    </template>
    <template v-if="staff && publish.isCustomProfileEnabled">
      <!-- {{ `publish.customProfile: ${publish.customProfile}` }} -->
      <PropertyItemEdit
        v-if="bEdit"
        :label="$t('device_param_video_quality_w' /*寬度*/)"
      >
        <template #property-value>
          <input
            class="number"
            type="number"
            v-model="publishQuality.w"
            :min="resolutionLimit.min"
            :max="resolutionLimit.max"
          />
        </template>
      </PropertyItemEdit>
      <PropertyItem
        v-else
        :label="$t('device_param_video_quality_w' /*寬度*/)"
        :value="publishQuality.w"
      />
      <PropertyItemEdit
        v-if="bEdit"
        :label="$t('device_param_video_quality_h' /*長度*/)"
      >
        <template #property-value>
          <input
            class="number"
            type="number"
            v-model="publishQuality.h"
            :min="resolutionLimit.min"
            :max="resolutionLimit.max"
          />
        </template>
      </PropertyItemEdit>
      <PropertyItem
        v-else
        :label="$t('device_param_video_quality_h' /*長度*/)"
        :value="publishQuality.h"
      />
      <PropertyItemEdit
        v-if="bEdit"
        :label="$t('device_param_video_quality_framerate' /*幀率(fps)*/)"
      >
        <template #property-value>
          <input
            class="number"
            type="number"
            v-model="publishQuality.fr"
            :min="framerateLimit.min"
            :max="framerateLimit.max"
          />
        </template>
      </PropertyItemEdit>
      <PropertyItem
        v-else
        :label="$t('device_param_video_quality_framerate' /*幀率(fps)*/)"
        :value="publishQuality.fr"
      />
      <PropertyItemEdit
        v-if="bEdit"
        :label="$t('device_param_video_quality_bitrate' /*位元率(Kbps)*/)"
      >
        <template #property-value>
          <input
            class="number"
            type="number"
            v-model="publishQuality.br"
            :min="bitrateLimit.min"
            :max="bitrateLimit.max"
          />
        </template>
      </PropertyItemEdit>
      <PropertyItem
        v-else
        :label="$t('device_param_video_quality_bitrate' /*位元率(Kbps)*/)"
        :value="publishQuality.br"
      />
    </template>
    <template v-else>
      <PropertyItemEdit
        v-if="bEdit"
        :label="
          $t('device_param_video_quality' /*品質*/, { codec: videoCodec })
        "
      >
        <template #property-value>
          <SingleSelect
            v-if="videoCodecIndex === 1"
            class="drop-down"
            :paddingX="paddingX"
            :disabled="false"
            v-model="publish.profileIndex265"
            :options="profileList265"
          />
          <SingleSelect
            v-else
            class="drop-down"
            :paddingX="paddingX"
            :disabled="false"
            v-model="publish.profileIndex"
            :options="profileList"
          />
        </template>
      </PropertyItemEdit>
      <PropertyItem
        v-else
        :label="
          $t('device_param_video_quality' /*品質*/, { codec: videoCodec })
        "
        :value="
          optionLabel(
            videoCodecIndex === 1 ? profileList265 : profileList,
            videoCodecIndex === 1
              ? publish.profileIndex265
              : publish.profileIndex
          )
        "
      />
    </template>
    <!-- 錄影 -->
    <PropertyItem :title="$t('device_param_video_record' /*錄影*/)" />
    <PropertyItemEdit v-if="bEdit" :label="$t('enable' /*啟用*/)">
      <template #property-value>
        <ToggleSwitch
          class="switch"
          v-model="record.isEnabled"
          :id="`recordIsEnabled`"
        />
        <!-- {{ `record.isEnabled: ${record.isEnabled}` }} -->
      </template>
    </PropertyItemEdit>
    <PropertyItem
      v-else
      :label="$t('enable' /*啟用*/)"
      :value="switchLabel(record.isEnabled)"
      :valueIcon="switchIcon(record.isEnabled)"
    />
    <template v-if="staff">
      <PropertyItemEdit
        v-if="bEdit"
        :label="$t('device_param_video_customize_quality' /*自訂品質*/)"
      >
        <template #property-value>
          <ToggleSwitch
            class="switch"
            v-model="record.isCustomProfileEnabled"
            :id="`publishIsCustomProfileEnabled`"
          />
          <!-- {{ `publish.isCustomProfileEnabled: ${publish.isCustomProfileEnabled}` }} -->
        </template>
      </PropertyItemEdit>
      <PropertyItem
        v-else
        :label="$t('device_param_video_customize_quality' /*自訂品質*/)"
        :value="switchLabel(record.isCustomProfileEnabled)"
        :valueIcon="switchIcon(record.isCustomProfileEnabled)"
      />
    </template>
    <template v-if="record.isCustomProfileEnabled">
      <!-- {{ `record.customProfile: ${record.customProfile}` }} -->
      <PropertyItemEdit
        v-if="bEdit"
        :label="$t('device_param_video_quality_w' /*寬度*/)"
      >
        <template #property-value>
          <input
            class="number"
            type="number"
            v-model="recordQuality.w"
            :min="resolutionLimit.min"
            :max="resolutionLimit.max"
          />
        </template>
      </PropertyItemEdit>
      <PropertyItem
        v-else
        :label="$t('device_param_video_quality_w' /*寬度*/)"
        :value="recordQuality.w"
      />
      <PropertyItemEdit
        v-if="bEdit"
        :label="$t('device_param_video_quality_h' /*長度*/)"
      >
        <template #property-value>
          <input
            class="number"
            type="number"
            v-model="recordQuality.h"
            :min="resolutionLimit.min"
            :max="resolutionLimit.max"
          />
        </template>
      </PropertyItemEdit>
      <PropertyItem
        v-else
        :label="$t('device_param_video_quality_h' /*長度*/)"
        :value="recordQuality.h"
      />
      <PropertyItemEdit
        v-if="bEdit"
        :label="$t('device_param_video_quality_framerate' /*幀率(fps)*/)"
      >
        <template #property-value>
          <input
            class="number"
            type="number"
            v-model="recordQuality.fr"
            :min="framerateLimit.min"
            :max="framerateLimit.max"
          />
        </template>
      </PropertyItemEdit>
      <PropertyItem
        v-else
        :label="$t('device_param_video_quality_framerate' /*幀率(fps)*/)"
        :value="recordQuality.fr"
      />
      <PropertyItemEdit
        v-if="bEdit"
        :label="$t('device_param_video_quality_bitrate' /*位元率(Kbps)*/)"
      >
        <template #property-value>
          <input
            class="number"
            type="number"
            v-model="recordQuality.br"
            :min="bitrateLimit.min"
            :max="bitrateLimit.max"
          />
        </template>
      </PropertyItemEdit>
      <PropertyItem
        v-else
        :label="$t('device_param_video_quality_bitrate' /*位元率(Kbps)*/)"
        :value="recordQuality.br"
      />
    </template>
    <template v-else>
      <PropertyItemEdit
        v-if="bEdit"
        :label="
          $t('device_param_video_quality' /*品質*/, { codec: videoCodec })
        "
      >
        <template #property-value>
          <SingleSelect
            v-if="videoCodecIndex === 1"
            class="drop-down"
            :paddingX="paddingX"
            :disabled="false"
            v-model="record.profileIndex265"
            :options="profileList265"
          />
          <SingleSelect
            v-else
            class="drop-down"
            :paddingX="paddingX"
            :disabled="false"
            v-model="record.profileIndex"
            :options="profileList"
          />
        </template>
      </PropertyItemEdit>
      <PropertyItem
        v-else
        :label="
          $t('device_param_video_quality' /*品質*/, { codec: videoCodec })
        "
        :value="
          optionLabel(
            videoCodecIndex === 1 ? profileList265 : profileList,
            videoCodecIndex === 1 ? record.profileIndex265 : record.profileIndex
          )
        "
      />
    </template>
    <template v-if="staff">
      <PropertyItemEdit
        v-if="bEdit"
        :label="$t('device_param_video_record_duration' /*錄影檔案長度*/)"
      >
        <template #property-value>
          <DeviceParamInputRange
            class="slider"
            v-model="record.duration"
            :paddingX="paddingX"
            :disabled="false"
            :label="`${$t('n_min', { min: record.duration })}`"
            :min="1"
            :max="15"
            :step="1"
          />
        </template>
      </PropertyItemEdit>
      <PropertyItem
        v-if="!bEdit"
        :label="$t('device_param_video_record_duration' /*錄影檔案長度*/)"
        :value="$t('n_min', { min: record.duration })"
      />
    </template>
  </fragment>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} from 'vuex'

import {
  isDevMode,
  // formatTime,
} from '@/utils/lib.js'
import {
  switchIcon,
  videoCodecList,

  defQuality,
  qualityToObj,
  parseQuality,

  resolutionLimit,
  framerateLimit,
  bitrateLimit,
} from '@/utils/parameter.js'

import PropertyItem from '@/components/AccountManagement/tools/PropertyItem.vue'
import PropertyItemEdit from '@/components/AccountManagement/tools/PropertyItemEdit.vue'
import ToggleSwitch from '@/components/tools/ToggleSwitch.vue'
import SingleSelect from '@/components/Base/SingleSelect.vue'
import DeviceParamInputRange from '@/components/DeviceParam/DeviceParamInputRange.vue'

const euQuality = {
  customize: -1,
  default: 0,
}

export default {
  name: 'DeviceParamVideoAicam',
  components: {
    PropertyItem,
    PropertyItemEdit,
    ToggleSwitch,
    SingleSelect,
    DeviceParamInputRange,
  },
  props: {
    bEdit: {
      type: Boolean,
      default: false
    },
    account: {
      type: Object,
      default: null
    },
    aiModelType: {
      type: String,
      default: null
    },
    config: {
      type: Object,
      default: null
    },
    deviceModelId: {
      type: Number,
      default: 0
    },
    isAiCamBFV: {
      type: Boolean,
      default: false
    },
    // ui
    paddingX: {
      type: Number,
      default: 20
    }
  },
  data() {
    return {
      // ...mapState([]),
      resolutionLimit,
      framerateLimit,
      bitrateLimit,

      // 發布
      publish: {
        isEnabled: false,
        isAudioMute: false,
        isOsdEnabled: false,
        isCustomProfileEnabled: false,
        customProfile: '',
        profileIndex: 0,
        profileIndex265: 0,
      },
      publishQuality: {
        w: defQuality.w,
        h: defQuality.h,
        fr: defQuality.fr,
        br: defQuality.br
      },
      osd: {
        isDateEnabled: true, // 時間
        isDecibelEnabled: false, // 分貝計數器
        isSignalInfoEnabled: true, // 訊號強度
        isBatteryLevelPercentageEnabled: true, // 電池電量(百分比)
        isBatteryVoltageEnabled: true, // 電池電量(電壓)
      },

      // 錄影
      record: {
        isEnabled: false,
        isCustomProfileEnabled: false,
        customProfile: '',
        profileIndex: 0,
        profileIndex265: 0,
        duration: 3,
      },
      recordQuality: {
        w: defQuality.w,
        h: defQuality.h,
        fr: defQuality.fr,
        br: defQuality.br
      }
    }
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['staff']), // state 放這裡可以維持唯一性, 不能用 this.comp = xxx 做修改
    bDevMode() {
      return isDevMode()
    },
    // data ---
    setting() {
      if (!this.config) return null

      return this.config.setting
    },
    // deviceConfig ...
    deviceConfig() {
      if (!this.setting) return null

      return this.setting.deviceConfig
    },
    videoCodecIndex() {
      if (!this.deviceConfig || !this.deviceConfig.publish) return 0

      return this.deviceConfig.publish.videoCodecIndex
    },
    videoCodec() {
      return videoCodecList[this.videoCodecIndex]
    },
    // channel ...
    channel() {
      if (!this.setting) return null

      return this.setting.channel.find((_ch) => _ch.account === this.account.id)
    },
    profileList() {
      if (!this.channel) return []

      let list = []

      if (this.channel.publish.profileList) {
        list = [...this.channel.publish.profileList]
      } else {
        list = [
          "640x480@30$500",
          "640x480@30$1000",
          "1280x720@15$1000",
          "1280x720@30$1000",
          "1280x720@30$2000",
          "1920x1080@15$3000",
          "1920x1080@30$6000",
          "4K@15$10000",
          "4K@30$20000"
        ]
      }

      return list.map((item, idx) => {
        return {
          id: idx,
          label: parseQuality(item),
          value: idx,
          disabled: false,
          // icon: this.enabledIcon
        }
      })
    },
    profileList265() {
      if (!this.channel) return []

      let list = []

      if (this.channel.publish.profileList) {
        list = [...this.channel.publish.profileList]
      } else {
        list = [
          "640x480@30$500",
          "640x480@30$768",
          "1280x720@15$768",
          "1280x720@30$1000",
          "1280x720@30$1500",
          "1920x1080@15$2000",
          "1920x1080@30$3000",
          "4K@15$6000",
          "4K@30$8000"
        ]
      }

      return list.map((item, idx) => {
        return {
          id: idx,
          label: parseQuality(item),
          value: idx,
          disabled: false,
          // icon: this.enabledIcon
        }
      })
    },
    // 錄影 ---
    recordCustomizeQuality() {
      return euQuality.customize
    },
    isCustomProfileEnabled() {
      return this.recordCustomizeQuality === euQuality.customize
    }
  },
  watch: {
    bEdit() {
      if (!this.bEdit) {
        this.rollback()
      }
    }
  },
  methods: {
    ...mapActions([]), // API query and update
    ...mapMutations([]),
    // UI
    switchIcon,
    switchLabel(value) {
      return value === true ? this.$t('on') : this.$t('off')
    },
    optionLabel(list, value) {
      if (!list || list.length <= 0) return this.$t('no_data')

      const option = list.find((_o) => _o.value === value)
      return option ? option.label : ''
    },
    // 資料
    init() {
      // console.log(`[VideoAicam.init] config:`, JSON.stringify(this.config, '', 2))
      // console.log(`[VideoAicam.init] setting:`, JSON.stringify(this.setting, '', 2))
      // console.log(`[VideoAicam.init] deviceConfig:`, JSON.stringify(this.deviceConfig, '', 2))
      console.log(`[DeviceParamVideoAicam.init]`)
      if (!this.channel) return

      this.initPublish()
      this.initRecord()
      this.initOsd()
    },
    initPublish() {
      let {
        isEnabled,
        isAudioMute,
        isOsdEnabled,
        isCustomProfileEnabled,
        customProfile,
        profileIndex,
        profileIndex265,
      } = this.channel.publish

      console.log(`[DeviceParamVideoAicam.initPublish]`, {
        isEnabled,
        isAudioMute,
        isOsdEnabled,
        isCustomProfileEnabled,
        customProfile,
        profileIndex,
        profileIndex265
      })

      this.publish = {
        isEnabled: isEnabled ? true : false,
        isAudioMute: isAudioMute ? true : false,
        isOsdEnabled: isOsdEnabled ? true : false,
        isCustomProfileEnabled:
          isCustomProfileEnabled === undefined
            ? false
            : isCustomProfileEnabled
            ? true
            : false,
        customProfile: customProfile === undefined ? '' : customProfile,
        profileIndex,
        profileIndex265: profileIndex265 === undefined ? 0 : profileIndex265
      }

      if (this.publish.isCustomProfileEnabled) {
        const qObj = qualityToObj(this.publish.customProfile)
        this.publishQuality = {
          w: qObj.w ? qObj.w : defQuality.w,
          h: qObj.h ? qObj.h : defQuality.h,
          br: qObj.br ? qObj.br : defQuality.br,
          fr: qObj.fr ? qObj.fr : defQuality.fr
        }
      }

      // console.log(`[DeviceParamVideoAicam.initPublish]`, this.publish )
    },
    initOsd() {
      if (!this.deviceConfig) return

      // 處理 osd 不存在
      if (!Object.keys(this.deviceConfig).includes('osd')) {
        if (!this.isAiCamBFV) {
          delete this.osd.isDecibelEnabled
        }
        return
      }

      try {
        const {
          isDateEnabled,
          isDecibelEnabled, // 分貝計數器
          isSignalInfoEnabled,
          isBatteryLevelPercentageEnabled,
          isBatteryVoltageEnabled,
        } = this.deviceConfig.osd

        console.log(`[DeviceParamVideoAicam.initOsd]`, {
          isDateEnabled,
          isDecibelEnabled, // 分貝計數器
          isSignalInfoEnabled,
          isBatteryLevelPercentageEnabled,
          isBatteryVoltageEnabled,
        })

        let osd = {
          isDateEnabled: isDateEnabled ? true : false,
          isSignalInfoEnabled: isSignalInfoEnabled ? true : false,
          isBatteryLevelPercentageEnabled: isBatteryLevelPercentageEnabled
            ? true
            : false,
          isBatteryVoltageEnabled: isBatteryVoltageEnabled ? true : false,
        }

        if (!this.isAiCamBFV) {
          delete this.osd.isDecibelEnabled
        } else {
          osd = {
            ...osd,
            ...{ isDecibelEnabled: isDecibelEnabled ? true : false }
          }
        }

        this.osd = { ...osd }
      } catch (err) {
        console.error(`Aicam.video.initOsd fail`, err)
      }
    },
    initRecord() {
      let {
        isEnabled,
        isCustomProfileEnabled,
        customProfile,
        profileIndex,
        profileIndex265,
        duration,
      } = this.channel.record

      console.log(`[DeviceParamVideoAicam.initRecord]`, {
        isEnabled,
        isCustomProfileEnabled,
        customProfile,
        profileIndex,
        profileIndex265,
        duration
      })

      this.record = {
        isEnabled: isEnabled ? true : false,
        isCustomProfileEnabled:
          isCustomProfileEnabled === undefined
            ? false
            : isCustomProfileEnabled
            ? true
            : false,
        customProfile: customProfile === undefined ? '' : customProfile,
        profileIndex,
        profileIndex265: profileIndex265 === undefined ? 0 : profileIndex265,
        duration,
      }

      if (this.record.isCustomProfileEnabled) {
        const qObj = qualityToObj(this.record.customProfile)
        this.recordQuality = {
          w: qObj.w ? qObj.w : defQuality.w,
          h: qObj.h ? qObj.h : defQuality.h,
          fr: qObj.fr ? qObj.fr : defQuality.fr,
          br: qObj.br ? qObj.br : defQuality.br
        }
      }

      console.log(`[DeviceParamVideoAicam.initRecord]`, this.record)
    },
    rollback() {
      this.init()
    },
    // 事件
    get() {
      console.log(`[DeviceParamVideoAicam.get]`)

      // 發布
      this.publish.isEnabled = this.publish.isEnabled ? 1 : 0
      this.publish.isAudioMute = this.publish.isAudioMute ? 1 : 0
      this.publish.isOsdEnabled = this.publish.isOsdEnabled ? 1 : 0
      const publishOldQuality = qualityToObj(this.publish.customProfile)
      const publishNewQuality = this.publishQuality
      if (
        this.publish.isCustomProfileEnabled &&
        JSON.stringify(publishOldQuality) !== JSON.stringify(publishNewQuality)
      ) {
        const { w, h, fr, br } = publishNewQuality
        this.publish.customProfile = `${w}x${h}@${fr}$${br}`
      }
      this.publish.isCustomProfileEnabled = this.publish.isCustomProfileEnabled
        ? 1
        : 0

      // OSD
      this.osd.isBatteryLevelPercentageEnabled = this.osd
        .isBatteryLevelPercentageEnabled
        ? 1
        : 0
      this.osd.isBatteryVoltageEnabled = this.osd.isBatteryVoltageEnabled
        ? 1
        : 0
      this.osd.isDateEnabled = this.osd.isDateEnabled ? 1 : 0
      this.osd.isSignalInfoEnabled = this.osd.isSignalInfoEnabled ? 1 : 0

      if (this.isAiCamBFV) {
        // 分貝計數器
        this.osd.isDecibelEnabled = this.osd.isDecibelEnabled ? 1 : 0
      }

      // 錄影
      this.record.isEnabled = this.record.isEnabled ? 1 : 0
      const recordOldQuality = qualityToObj(this.record.customProfile)
      const recordNewQuality = this.recordQuality
      if (
        this.record.isCustomProfileEnabled &&
        JSON.stringify(recordOldQuality) !== JSON.stringify(recordNewQuality)
      ) {
        const { w, h, fr, br } = recordNewQuality
        this.record.customProfile = `${w}x${h}@${fr}$${br}`
      }
      this.record.isCustomProfileEnabled = this.record.isCustomProfileEnabled
        ? 1
        : 0

      return {
        publish: this.publish,
        record: this.record,
        osd: this.osd
      }
    },
  },
  created() {},
  mounted() {
    this.init()
    // console.log(`[mounted] staff:`, this.staff)
  },
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
$LeftRightPadding: px2rem(20);

* {
  box-sizing: border-box;
  // user-select: none;
}

.switch {
  margin-left: $LeftRightPadding;
}
.drop-down {}
.slider {}
.number {
  padding-left: $LeftRightPadding;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  width: 100%;
  height: 100%;
  font-size: 1rem;
  // background-color: #f00;

  // How TO - Hide Arrows From Input Number
  // https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type=number] {
    -moz-appearance: textfield;
  }
}
</style>
