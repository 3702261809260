<template>
  <fragment>
    <PropertyItem :title="$t('device_param_setting_locate' /*定位*/)" />
    <PropertyItemEdit v-if="bEdit" :label="$t('enable' /*啟用*/)">
      <template #property-value>
        <ToggleSwitch
          class="switch"
          v-model="gps.isEnabled"
          id="gpsIsEnabled"
        />
      </template>
    </PropertyItemEdit>
    <PropertyItem
      v-else
      :label="$t('enable' /*啟用*/)"
      :value="switchLabel(gps.isEnabled)"
      :valueIcon="switchIcon(gps.isEnabled)"
    />

    <!-- <PropertyItem :title="$t('device_param_setting_ai' /*AI*/)" />
    <PropertyItemEdit
      v-if="bEdit"
      :label="$t('device_param_setting_ai_hidden' /*沉默模式*/)"
    >
      <template #property-value>
        <SingleSelect
          class="drop-down"
          :paddingX="paddingX"
          :disabled="false"
          v-model="system.isAiHidden"
          :options="isAiHiddenList"
        />
      </template>
    </PropertyItemEdit>
    <PropertyItem
      v-else
      :label="$t('device_param_setting_ai_hidden' /*沉默模式*/)"
      :value="optionLabel(isAiHiddenList, system.isAiHidden)"
    /> -->

    <!-- 事件 -->
    <PropertyItem :title="$t('event' /*事件*/)" />
    <PropertyItemEdit
      v-if="bEdit"
      :label="$t('device_param_setting_notify_in_app' /*App事件通知*/)"
    >
      <template #property-value>
        <SingleSelect
          class="drop-down"
          :paddingX="paddingX"
          :disabled="false"
          v-model="notify.notifyInApp"
          :options="notifyInAppList"
        />
      </template>
    </PropertyItemEdit>
    <PropertyItem
      v-else
      :label="$t('device_param_setting_notify_in_app' /*App事件通知*/)"
      :value="optionLabel(notifyInAppList, notify.notifyInApp)"
    />
    <PropertyItemEdit
      v-if="bEdit"
      :label="$t('device_param_setting_wake_up_screen' /*事件觸發畫面*/)"
    >
      <template #property-value>
        <ToggleSwitch
          class="switch"
          v-model="notify.wakeUpScreen"
          :id="'notifyWakeUpScreen'"
        />
      </template>
    </PropertyItemEdit>
    <PropertyItem
      v-else
      :label="$t('device_param_setting_wake_up_screen' /*事件觸發畫面*/)"
      :value="switchLabel(notify.wakeUpScreen)"
      :valueIcon="switchIcon(notify.wakeUpScreen)"
    />
    <PropertyItemEdit
      v-if="bEdit"
      :label="$t('device_param_setting_audio_notify_type' /*提示音*/)"
    >
      <template #property-value>
        <SingleSelect
          class="drop-down"
          :paddingX="paddingX"
          :disabled="false"
          v-model="notify.audioNotifyType"
          :options="audioNotifyList"
        />
      </template>
    </PropertyItemEdit>
    <PropertyItem
      v-else
      :label="$t('device_param_setting_audio_notify_type' /*提示音*/)"
      :value="optionLabel(audioNotifyList, notify.audioNotifyType)"
    />
    <PropertyItemEdit
      v-if="bEdit"
      :hint="null"
      :hintTooltiop="null"
      :labelIcon="null"
      :label="$t('device_param_setting_enable_vibration' /*振動提示*/)"
      :helper="null"
      :error="null"
    >
      <template #property-value>
        <ToggleSwitch
          class="switch"
          v-model="notify.enableVibration"
          :id="'notifyEnableVibration'"
        />
      </template>
    </PropertyItemEdit>
    <PropertyItem
      v-else
      :label="$t('device_param_setting_enable_vibration' /*振動提示*/)"
      :value="switchLabel(notify.enableVibration)"
      :valueIcon="switchIcon(notify.enableVibration)"
    />

    <!-- FR -->
    <!-- <PropertyItem :title="$t('device_param_setting_fr' /*人物*/)" /> -->
    <!-- <PropertyItemEdit
      v-if="bEdit"
      :label="$t('device_param_setting_jpg_quality' /*JPG畫質*/)"
    >
      <template #property-value>
        <DeviceParamInputRange
          class="slider"
          v-model="fr.jpgQuality"
          :paddingX="paddingX"
          :disabled="false"
          :label="`${fr.jpgQuality}`"
          :min="jpgQualityRange.min"
          :max="jpgQualityRange.max"
          :step="jpgQualityRange.step"
        />
      </template>
    </PropertyItemEdit>
    <PropertyItem
      v-else
      :label="$t('device_param_setting_jpg_quality' /*JPG畫質*/)"
      :value="`${fr.jpgQuality}`"
    /> -->
    <PropertyItemEdit
      v-if="bEdit"
      :label="$t('device_param_setting_crop_jpg_quality' /*截圖JPG畫質*/)"
    >
      <template #property-value>
        <DeviceParamInputRange
          class="slider"
          v-model="fr.cropJpgQuality"
          :paddingX="paddingX"
          :disabled="false"
          :label="`${fr.cropJpgQuality}`"
          :min="cropJpgQualityRange.min"
          :max="cropJpgQualityRange.max"
          :step="cropJpgQualityRange.step"
        />
      </template>
    </PropertyItemEdit>
    <PropertyItem
      v-else
      :label="$t('device_param_setting_crop_jpg_quality' /*截圖JPG畫質*/)"
      :value="`${fr.cropJpgQuality}`"
    />
  </fragment>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} from 'vuex'

import {
  switchIcon,
  cropJpgQualityRange,
  // jpgQualityRange,
  // lprOrder,
  // gpsPostDurations,
  audioNotifyList,
} from '@/utils/parameter.js'

import PropertyItem from '@/components/AccountManagement/tools/PropertyItem.vue'
import PropertyItemEdit from '@/components/AccountManagement/tools/PropertyItemEdit.vue'
import ToggleSwitch from '@/components/tools/ToggleSwitch.vue'
import SingleSelect from '@/components/Base/SingleSelect.vue'
import DeviceParamInputRange from '@/components/DeviceParam/DeviceParamInputRange.vue'

export default {
  name: 'DeviceParamSettingBovicast',
  components: {
    PropertyItem,
    PropertyItemEdit,
    ToggleSwitch,
    SingleSelect,
    DeviceParamInputRange
  },
  props: {
    bEdit: {
      type: Boolean,
      default: false
    },
    account: {
      type: Object,
      default: null
    },
    config: {
      type: Object,
      default: null
    },
    // ui
    paddingX: {
      type: Number,
      default: 20
    }
  },
  data() {
    return {
      cropJpgQualityRange,
      gps: {
        isEnabled: false,
        // postDuration: 0, // 上傳間隔
      },
      system: {
        isAiHidden: 0,
      },
      notify: {
        notifyInApp: 0, // 0: 永不, 1: 總是, 2: 畫面隱藏時, 3: 畫面顯示時
        wakeUpScreen: false,
        audioNotifyType: 0, // 0: 無, 1: 提示音
        enableVibration: 0, // 0: 無, 1: 開啟震動
      },
      fr: {
        // jpgQuality: 0.9,
        cropJpgQuality: 0.95,
      }
    }
  },
  computed: {
    ...mapGetters([]),
    ...mapState([]), // state 放這裡可以維持唯一性, 不能用 this.comp = xxx 做修改
    // data ---
    setting() {
      if (!this.config) return null

      return this.config.setting
    },
    // deviceConfig ...
    deviceConfig() {
      if (!this.setting) return null

      return this.setting.deviceConfig
    },
    // isAiHiddenList() {
    //   // 系統>沉默模式: 0(AI關閉), 1(AI開啟-一般模式), 2(AI開啟-沉默模式)
    //   return [0, 1, 2].map((item) => {
    //     return {
    //       id: item,
    //       label: this.$t(`device_param_setting_ai_hidden_${item}`),
    //       value: item,
    //       disabled: false,
    //       // icon: this.enabledIcon
    //     }
    //   })
    // },
    notifyInAppList() {
      // 事件>App觸發事件: 0(永不), 1(總是), 2(畫面隱藏時), 3(畫面顯示時)
      return [0, 1, 2, 3].map((item) => {
        return {
          id: item,
          label: this.$t(`device_param_setting_notify_in_app_${item}`),
          value: item,
          disabled: false,
          // icon: this.enabledIcon
        }
      })
    },
    audioNotifyList() {
      // 事件>提示音: 0(無),1(警示音),2(語音提示)
      return audioNotifyList.map((item) => {
        return {
          id: item,
          label: this.$t(`device_param_setting_audio_notify_type_${item}`),
          value: item,
          disabled: false,
          // icon: this.enabledIcon
        }
      })
    },
  },
  watch: {},
  methods: {
    ...mapActions([]), // API query and update
    ...mapMutations([]),
    // ui ---
    switchIcon,
    switchLabel(value) {
      return value === true ? this.$t('on') : this.$t('off')
    },
    optionLabel(list, value) {
      if (!list || list.length <= 0) return null

      return list.find((_o) => _o.value === value)?.label
    },
    // 資料
    initGps() {
      if (!this.deviceConfig) return

      const {
        isEnabled,
        // postDuration
      } = this.deviceConfig.gps
      this.gps = {
        isEnabled: isEnabled ? true : false,
        // postDuration,
      }
    },
    // initSystem() {
    //   if (!this.deviceConfig) return

    //   const { isAiHidden } = this.deviceConfig.system
    //   this.system = {
    //     isAiHidden
    //   }
    // },
    iniNotify() {
      if (!this.deviceConfig) return

      const { notifyInApp, wakeUpScreen, audioNotifyType, enableVibration } =
        this.deviceConfig.notify

      console.log(`[iniNotify] `, {
        notifyInApp,
        wakeUpScreen,
        audioNotifyType,
        enableVibration
      })
      this.notify = {
        notifyInApp,
        wakeUpScreen: wakeUpScreen ? true : false,
        audioNotifyType,
        enableVibration: enableVibration ? true : false
      }
    },
    initFr() {
      if (!this.deviceConfig) return

      const {
        cropJpgQuality,
        // jpgQuality
      } = this.deviceConfig.fr

      console.log(`[initFr] `, {
        cropJpgQuality,
        // jpgQuality
      })

      this.fr = {
        cropJpgQuality,
        // jpgQuality
      }
    },
    init() {
      console.log(`[DeviceParamSettingBivicast.init]`)
      if (!this.setting) return

      this.initGps()
      // this.initSystem()
      this.iniNotify()
      this.initFr()
    },
    rollback() {
      this.init()
    },
    // 事件
    get() {
      return {
        gps: {
          ...this.gps,
          ...{ isEnabled: this.gps.isEnabled ? 1 : 0 }
        },
        // system: { ...this.system },
        notify: {
          ...this.notify,
          ...{
            wakeUpScreen: this.notify.wakeUpScreen ? 1 : 0,
            enableVibration: this.notify.enableVibration ? 1 : 0
          }
        },
        fr: { ...this.fr }
      }
    },
  },
  created() {},
  mounted() {
    this.init()
  },
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
$LeftRightPadding: px2rem(20);
* {
  box-sizing: border-box;
  // user-select: none;
}

.switch {
  margin-left: $LeftRightPadding;
}
.drop-down {}
.slider {}
</style>
