<template>
  <div>
    <portal to="editpsd-modal" >
      <div class="edit-modal" @click="closeModal"></div>
      <div class="edit-psd-wrap">
        <div class="top">
          <div class="title">{{ $t('left_ber_userinfo_change_password') }}</div>
          <!-- <i class="el-icon-close" style="cursor: pointer;" @click="closeModal"/> -->
        </div>
        <hr />
        <div class="middle">
          <div class="input-title">{{ $t('account_new_pw') }}</div>
          <form style="display: flex; align-items: center">
            <input v-model="newPsd" :type="newPsdType" autocomplete="false" />
            <div class="eye show" @click="hideNewPsd = !hideNewPsd">
              <img :src="newPsdEyeImg" />
            </div>
          </form>
          <div v-if="errorPassword" class="error">
            {{ $t(errorPassword.key, errorPassword?.param) }}
            <el-tooltip v-if="errorPassword.info" popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="pswSpecialChars">
              <img class="psw-err-i" src="@/assets/icons/info.svg" alt="" />
            </el-tooltip>
          </div>

          <div class="input-title">{{ $t('account_reenter_pw') }}</div>
          <form style="display: flex; align-items: center">
            <input v-model="checkPsd" :type="checkPsdType" autocomplete="false"/>
            <div class="eye hide" @click="hideCheckPsd = !hideCheckPsd">
              <img :src="checkPsdEyeImg" />
            </div>
          </form>
          <div v-if="errorConfirmPassword" class="error">
            {{ $t(errorConfirmPassword.key, errorConfirmPassword?.param) }}
          </div>

        </div>
        <div class="bottom">
          <div class="btn-wrap cancel-btn" @click="closeModal">{{ $t('cancel') }}</div>
          <div class="btn-wrap confirm-btn" @click="changePsd">{{ $t('save') }}</div>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import {
  apiCheckStatus, apiErrorMsg,
  apiEditUser
} from '@/api/index.js'
import {
  validatePwsLen,
  validatePswChar,
  validatePswSpecialChar,
  pswLen,
  pswSpecialChars
} from '@/utils/lib.js'

export default {
  name: 'EditPsd',
  data(){
    return {
      newPsd: null,
      checkPsd: '',
      showEye: require('@/assets/icons/EyeOpen.svg'),
      hideEye: require('@/assets/icons/EyeClose.svg'),
      hideNewPsd: true,
      hideCheckPsd: true,

      pswSpecialChars,
      errorPassword: null,
      errorConfirmPassword: null
    }
  },
  computed: {
    ...mapGetters(['getUserInfo']),
    newPsdEyeImg() {
      if (this.hideNewPsd) {
        return this.hideEye
      }
      return this.showEye
    },
    checkPsdEyeImg(){
      if (this.hideCheckPsd) {
        return this.hideEye
      }
      return this.showEye
    },
    newPsdType(){
      if(this.hideNewPsd){
        return 'password'
      }
      return 'text'
    },
    checkPsdType(){
      if(this.hideCheckPsd){
        return 'password'
      }
      return 'text'
    },
  },
  methods: {
    ...mapActions(['logout']),
    closeModal() {
      this.newPsd = ''
      this.checkPsd = ''
      this.$emit('closemodal')
    },
    checkPassword() {
      try {
        this.errorPassword = null
        this.errorConfirmPassword = null
        if (!validatePwsLen(this.newPsd)) {
          this.errorPassword = {
            key: 'account_pw_length_error',
            param: {
              min: pswLen.min,
              max: pswLen.max
            }
          }
          return false
        } else if (!validatePswChar(this.newPsd)) {
          this.errorPassword = {
            key: 'account_pw_char_error',
            info: true
          }
          return false
        } else if (!validatePswSpecialChar(this.newPsd)) {
          this.errorPassword = {
            key: 'account_pw_special_char_error',
            info: true
          }
          return false
        }

        if (this.checkPsd != this.newPsd) {
          this.errorConfirmPassword = {
            key: 'account_pw_error',
          }
          return false
        }

        if (this.checkPsd == '' && this.newPsd == '') {
          this.errorConfirmPassword = {
            key: 'account_pw_null',
          }
          return false
        }
      } catch (err) {
        console.error(`[checkPassword]`, err)
        return false
      }
      return true
    },
    async changePsd() {
      if (!this.checkPassword()) {
        return
      }

      try {
        let data = {
          id: this.getUserInfo.id,
          password: this.newPsd,
        }

        let res = await apiEditUser(data)
        if (!apiCheckStatus(res)) throw res

        // console.log('changePsd', res)

        this.$notify({
          type: 'success',
          title: this.$t('account_edit_password'),
          message: this.$t('account_pw_success'),
        })

        this.logOut()
        this.closeModal()
      } catch (err) {
        // console.log('changePsd', err)
        this.$notify({
          type: 'error',
          title: this.$t('account_edit_password'),
          message: err ? this.$t(apiErrorMsg(err)) : this.$t('api_error')
        })
      }
    },
    logOut() {
      try {
        // this.logout()
        this.$router.push('/login')
      } catch (err) {
        console.log(err)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.edit-modal {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(32, 44, 57, 0.6);
  z-index: 3;
}

.edit-psd-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 32%;
  padding: 20px;
  border: 1px solid #4A5C78;
  background: #282942;
  color: #fff;
  z-index: 3;
}

hr {
  margin: 10px 0px;
  border: 1px solid #4A5C78;
}

.top {
  display: flex;
  justify-content: space-between;
  /* color: #343B5E; */
  /* font: bold 16px Arial; */
  font-size: 16px;
}

input {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 15px;
  padding: 0.7rem;
  padding-right: calc(44px + 2px);
  border-radius: 8px;
  border: 0;
  outline: 0;

  font-size: 1rem;
  font-weight: 300;
}

.middle {
  padding-left: 20px;
}

.bottom {
  display: flex;
  justify-content: flex-end;
}

.btn-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  width: 100px;
  height: 36px;
  box-sizing: border-box;
  font-size: 14px;
  color: #282942;
}
.btn-wrap:hover {
  background: #ffd231;
}

.confirm-btn {
  margin-left: 5px;
  background-color: #ffc600;
}

.cancel-btn {
  border: 1px solid #ffc600;
  color: #ffc600;
}

.cancel-btn:hover {
  background: rgba(255, 198, 0, 0.2);
}

.eye {
  position: relative;
  right: 44px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 44px;
  height: 1rem;
  /* background-color: #fff; */
  border-radius: 0 8px 8px 0;
  margin-top: -10px;
  cursor: pointer;
}

.error {
  margin-bottom: 0.5rem;
  color: $color_F94144;
  // background-color: #00f;

  .psw-err-i {
    @include img_icon_16x16;
    @include filter_F94144_50;
    &:hover {
      @include filter_F94144;
    }
  }
}
</style>
