<template>
  <input
    type="range"
    v-model="range"
    :style="sliderStyle"
    :disabled="disabled"
    :min="min"
    :max="max"
    :step="step"
  >
</template>

<script>
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} from 'vuex'

export default {
  name: 'Slider',
  components: {
  },
  props: {
    value: {
      required: true,
      type: Number,
      default: null
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    step: {
      type: Number,
      default: 1
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([]),
    ...mapState([]), // state 放這裡可以維持唯一性, 不能用 this.comp = xxx 做修改
    range: {
      get() {
        return Number(this.value)
      },
      set(value) {
        this.$emit('input', Number(value))
      }
    },
    sliderStyle() {
      const full = this.max - this.min
      const value = this.value - this.min

      return {
        '--range': `${value  / full * 100}%`,
      }
    },
  },
  watch: {
  },
  methods: {
    ...mapActions([]), // API query and update
    ...mapMutations([]),
  },
  created() {},
  mounted() {},
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
$TrackBorderRadius: 0.25rem;
$TrackH: 0.5rem;
* {
  box-sizing: border-box;
  // user-select: none;
}

input[type=range] {
  position: relative;
  outline: none;
  border-radius: $TrackBorderRadius;
  accent-color: $color_slider_thumb;
  background: none;
  width: 100%;
  appearance: none;

  &:not(:disabled) {
    cursor: pointer;
  }
  &:disabled {
    // @include disabled; // 0.2 太淺了
    opacity: 0.4;
  }

  @mixin golden_ball {
    background: radial-gradient(circle at 65% 30%, white 2px, rgb(235, 231, 18) 3%, rgb(127, 127, 141) 80%, rgb(18, 235, 235) 100%);
  }

  @mixin thumb {
    position: relative;
    top: -50%;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    border: 1px solid $color_C9C9C9;
    border-radius: 50%;
    height: 1rem;
    width: 1rem;
    appearance: none;
    background-color: $color_FFF;
    &:active {
      outline: none;
    }
    &:hover {
      border-color: $color_slider_focus_bdc;
      @include golden_ball;
      // background-color: $color_slider_thumb;
    }
  }

  @mixin track {
    border: 1px solid transparent;
    border-radius: $TrackBorderRadius;
    // width: 100%;
    height: $TrackH;
    background-color: $color_slider_leave;
    background: linear-gradient(
      to right,
      $color_slider_value 0%,
      $color_slider_value var(--range),
      $color_slider_leave var(--range),
      $color_slider_leave 100%
    );
  }

  // Chrome
  &::-webkit-slider-thumb {
    @include thumb;
    top: calc(-50% - 0.125rem);
  }
  &:focus::-webkit-slider-thumb {
    border-color: $color_slider_focus_bdc;
    @include golden_ball;
    // background-color: $color_slider_thumb;
  }
  &::-webkit-slider-runnable-track {
    @include track;
  }
  &:focus::-webkit-slider-runnable-track {
    border-color: $color_slider_focus_bdc;
  }

  // Firefox
  &::-moz-range-thumb {
    @include thumb;
  }
  &:focus::-moz-range-thumb {
    border-color: $color_slider_focus_bdc;
    @include golden_ball;
    // background-color: $color_slider_thumb;
  }
  &::-moz-range-track {
    @include track;
  }
  &:focus::-moz-range-track {
    border-color: $color_slider_focus_bdc;
  }
}
</style>
