<template>
  <div class="time-lapse-export-wrap">
    <div class="time-lapse-export-top">
      <!-- {{ `TIME LAPSE EXPORT: ${userId}` }} -->
    </div>
    <div class="time-lapse-export-content">
      <div class="time-lapse-export-table">
        <div class="time-lapse-export-table-head">
          <div class="table-row header">
            <div class="table-col no">{{ '#' }}</div>
            <div class="table-col taskname">{{ '任務名稱' }}</div>
            <div class="table-col filename">{{ '檔名' }}</div>
            <div class="table-col devicename">{{ '設備名稱' }}</div>
            <div class="table-col timerange">{{ '時間區間' }}</div>
            <div class="table-col ratio-timelapse">{{ '倍率' }}</div>
            <div class="table-col filesize">{{ '檔案大小' }}</div>
            <div class="table-col control">
              <el-tooltip
                popper-class="el-tooltip"
                effect="dark"
                v-delTabIndex
                :content="'重新整理'"
              >
                <button class="btn reload" @click="onReload">
                  <img src="@/assets/icons/refresh-white.svg" alt="" />
                </button>
              </el-tooltip>
            </div>
          </div>
        </div>
        <div class="time-lapse-export-table-body">
          <div v-if="fileList === null && loading">
            <span class="hint-msg">{{ '載入中...' }}</span>
          </div>
          <div v-else-if="fileList === null">
            <span class="hint-msg">{{ '請重新裡' }}</span>
          </div>
          <div v-else-if="fileList && fileList.length <= 0">
            <span class="hint-msg">{{ '查無資料' }}</span>
          </div>
          <template v-else>
            <div
              class="table-row"
              v-for="(file, idx) in fileList"
              :key="`${file.id}_${idx}`"
            >
              <div class="table-col no">{{ `#${file.id}` }}</div>
              <div class="table-col taskname">
                {{ taskName(file) }}
              </div>
              <div class="table-col filename">
                {{ fileName(file) }}
              </div>
              <div class="table-col devicename">
                {{ `${file.user.info.name}(${file.user.id})` }}
              </div>
              <div class="table-col timerange">
                <div class="range-wrap" v-for="(range, idx) in file.range" :key="`${Date.now()}_${idx}`">
                  <span v-if="file.range.length > 1" class="range-no">
                    {{ `#${idx + 1}` }}
                  </span>
                  <span class="range-content">
                    <div>{{ `${videoStartTime(range)} ~` }}</div>
                    <div>{{ videoEndTime(range) }}</div>
                  </span>
                </div>
              </div>
              <div class="table-col ratio-timelapse">
                <span>{{ ratio(file) }}</span>
              </div>
              <div class="table-col filesize">
                {{ videoSize(file) }}
              </div>
              <div class="table-col control">
                <el-tooltip
                  popper-class="el-tooltip"
                  effect="dark"
                  v-delTabIndex
                  :disabled="disabledDownloadTooltip(file)"
                  :content="downloadTooltip(file)"
                >
                  <button
                    class="btn download"
                    :disabled="disabledDownload(file)"
                    @click="onDownload(file)"
                  >
                    <img src="@/assets/icons/file_download.svg" alt="" />
                    <span>{{ '下載' }}</span>
                  </button>
                </el-tooltip>
                <el-tooltip
                  popper-class="el-tooltip"
                  effect="dark"
                  v-delTabIndex
                  :content="'刪除'"
                >
                  <button
                    class="btn remove"
                    :disabled="!file.doneTime"
                    @click="onRemove(file)"
                  >
                    <img src="@/assets/icons/TrashCan.svg" alt="" />
                  </button>
                </el-tooltip>
              </div>
            </div>
          </template>
        </div>
      </div>
      <!-- <div class="time-lapse-export-footer">
      </div> -->
    </div>
    <MessageModal
      v-if="bConfirm"
      :header="confirm.header"
      :title="confirm.title"
      :btns="confirm.btns"
      @close="onCloseConfirm"
      @cancel="onCloseConfirm"
      @delete="confirm.confirmFunc"
      @confirm="() => null"
    />
  </div>
</template>

<script>
import {
  apiCheckStatus,
  apiErrorMsg,
  apiGetExportVideoTasks,
  apiDeleteExportVideoTask
} from '@/api'
import { formatBytes, formatTime } from '@/utils/lib.js'

import MessageModal from '@/components/Base/MessageModal.vue'

const empty = '...'
const initConfirm = {
  header: null,
  title: null,
  btns: { cancel: true, delete: true },
  confirmFunc: () => null,
}

export default {
  name: 'TimeLapseExport',
  components: { MessageModal },
  props: {
    userId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      fileList: null, // [] || null,
      loading: false,

      deleteFile: null,
      bConfirm: false,
      confirm: { ...initConfirm },
    }
  },
  computed: {
  },
  watch: {},
  methods: {
    taskName(file) {
      if (file.description === undefined) return empty

      return file.description
    },
    fileName(file) {
      if (!file.videoName) return empty

      return file.videoName?.split('/').pop()
    },
    videoStartTime(range) {
      return formatTime(range.startTime)
    },
    videoEndTime(range) {
      return formatTime(range.endTime)
    },
    ratio(file) {
      return file.ratio ? file.ratio : 1
    },
    videoSize(file) {
      console.log(`[videoSize] #${file.id} size:`, file.videoSize)
      return formatBytes(file.videoSize)
    },
    downloadTooltip(file) {
      // console.log(`[downloadTooltip] #${file.id} file:`, file)
      const doneMsg = !file.doneTime ? '影片尚未處理完成' : null
      let sizeMsg = null

      if(!doneMsg) {
        if (file.videoSize <= 0) {
          sizeMsg = '檔案大小為 0'
        } else if (!file.videoSize) {
          sizeMsg = '無檔案'
        }
      }

      return doneMsg || sizeMsg
    },
    disabledDownloadTooltip(file) {
      const doneRet = file.doneTime ? true : false
      const sizeRet = file.videoSize ? true : false

      return doneRet && sizeRet
    },
    disabledDownload(file) {
      const doneRet = !file.doneTime ? true : false
      const sizeRet = !file.videoSize ? true : false

      return doneRet || sizeRet
    },
    async onApiGetFileList() {
      const title = `取得影像列表`

      this.onSetLoad(true)

      try {
        const res = await apiGetExportVideoTasks()
        if (!apiCheckStatus(res)) throw res

        const sortedList = JSON.parse(JSON.stringify(res.data))
        // 依據 id 排序
        sortedList.sort((a, b) => {
          return Number(b.id) - Number(a.id)
        })
        this.fileList = sortedList
        // console.log(`[TimeLapseExport.onApiGetFileList] fileList:`, this.fileList)
      } catch (err) {
        // console.error(`[TimeLapseExport.onApiGetFileList] getFileList fail.`, err)
        this.$notify({
          type: 'error',
          title,
          message:
            this.$t('fail') + err
              ? this.$t(apiErrorMsg(err))
              : this.$t('api_error')
        })
      } finally {
        this.onSetLoad(false)
      }
    },
    onSetLoad(loading) {
      this.loading = loading
    },
    onReload() {
      this.fileList = null
      this.onApiGetFileList()
    },
    onDownload(file) {
      console.log(`[onDownload] #${file.id}`)
      // console.log(`[onDownload] #${file.id} `, file.videoUrl)
      window.open(file.videoUrl)
    },
    onCloseConfirm() {
      this.bConfirm = false
      this.confirm = { ...initConfirm }

      this.deleteFile = null
    },
    async onApiRemove() {
      const file = this.deleteFile
      const title = `刪除影像`
      try {
        const param = { id: `${file.id}` }
        console.log(`[onRemove] param:`, JSON.stringify(param, '', 2))
        const res = await apiDeleteExportVideoTask(param)
        if (!apiCheckStatus(res)) throw res

        this.$notify({
          type: 'success',
          title,
          message: this.$t('success'),
        })
      } catch (err) {
        // console.error(`[TimeLapseExport] onRemove fail.`, err)
        this.$notify({
          type: 'error',
          title,
          message:
            this.$t('fail') + err
              ? this.$t(apiErrorMsg(err))
              : this.$t('api_error')
        })
      } finally {
        this.onApiGetFileList()
        this.onCloseConfirm()
      }
    },
    onRemove(file) {
      this.deleteFile = file

      this.confirm = {
        ...initConfirm,
        ...{
          header: this.$t('delete_data'),
          title: `確定刪除該影像嗎？`,
          confirmFunc: this.onApiRemove
        }
      }
      this.bConfirm = true
    }
  },
  created() {},
  mounted() {
    this.onApiGetFileList()
  },
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
$TopH: 0.25rem;
$NoW: 4%;
$TimeRangeW: 16%;
// $TimelapseW: 5%;
// $RatioW: 5%;
$RatioTimelapseW: 5%;
$FilesizeW: 8%;
$ControlW: 12%;
$Leaved: calc(
  100% - $NoW - $TimeRangeW - /*$TimelapseW - $RatioW*/ $RatioTimelapseW - $FilesizeW - $ControlW
);
$TasknameW: calc($Leaved / 3);
$FilenameW: $TasknameW;
$DeviceNameW: $TasknameW;

$FooterH: px2rem(36);
* {
  box-sizing: border-box;
  margin: 0;
  border: 0;
  padding: 0;
  user-select: none;
}
.time-lapse-export-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;

  .time-lapse-export-top {
    height: $TopH;
  }

  .time-lapse-export-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: calc(100% - $TopH);
    // background-color: #f00;
    .time-lapse-export-table {
      display: flex;
      flex-direction: column;
      width: 100%;
      .time-lapse-export-table-head {
        padding-right: 0.75rem;
        width: 100%;
        font-size: 1.25rem;
        @include font_bold;
        color: $color_FFF_50;
      }
      .time-lapse-export-table-body {
        width: 100%;
        height: 57.5vh; // calc(100% - $FooterH - 0);
        overflow-y: auto;
        scrollbar-gutter: stable;

        .hint-msg {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-top: 2rem;
          color: $color_FFF_50;
        }
      }

      .table-row {
        display: flex;
        align-items: center;
        border-bottom: 1px solid $color_divider;
        padding-top: 1rem;
        padding-bottom: 1rem;
        width: 100%;

        &:not(.header) {
          align-items: flex-start;
          color: $color_FFF;
          &:hover {
            background-color: $color_4A5C78_40;
          }
          // &:last-child {
          //   border-color: transparent;
          // }
        }

        .table-col {
          display: flex;
          padding: 0 0.25rem;
          word-break: break-all;
          &.no {
            width: $NoW;
            // background-color: red;
          }

          &.taskname {
            width: $TasknameW;
          }
          &.filename {
            width: $FilenameW;
            // background-color: orange;
          }
          &.devicename {
            width: $DeviceNameW;
            // background-color: yellow;
          }
          &.timerange {
            display: flex;
            flex-direction: column;
            width: $TimeRangeW;
            // background-color: green;

            .range-wrap {
              display: flex;
              font-size: 1rem;
              line-height: 1.5;
              .range-no {
                margin-right: 0.25rem;
                @include font_bold;
                color: $color_FFE99F;
              }
            }
          }
          &.ratio-timelapse {
            display: flex;
            align-items: center;
            width: $RatioTimelapseW;
            // color: #f94144;
            .slash {
              margin-left: 0.25rem;
              margin-right: 0.25rem;
            }
            img {
              width: 1rem;
              height: 1rem;
              // vertical-align: bottom;
            }
          }
          // &.timelapse {
          //   display: flex;
          //   &:has(img) {
          //     padding-left: 1rem;
          //   }
          //   width: $TimelapseW;
          //   // background-color: palevioletred;
          //   img {
          //     width: 1rem;
          //     height: 1rem;
          //   }
          // }
          // &.ratio {
          //   width: $RatioW;
          // }
          &.filesize {
            width: $FilesizeW;
            // background-color: blue;
          }
          &.control {
            display: flex;
            justify-content: flex-end;
            width: $ControlW;
            // background-color: purple;

            :not(:first-child) {
              margin-left: 0.5rem;
            }
            .btn {
              border-radius: 0.5rem;
              padding: 0 px2rem(12);
              min-height: px2rem(36);

              &:disabled {
                @include disabled;
              }
              a {
                color: $color_FFF;
                text-decoration: none;
              }

              img {
                width: 1rem;
                height: 1rem;
              }
              span {
                margin-left: 0.5rem;
              }
            }

            .reload {
              &:hover {
                background-color: $color_39425D;
              }
            }
            .download {
              background-color: $color_8CC24D;
            }

            .remove {
              background-color: $color_F94144;
            }
          }

          &.illegal {
            @include disabled;
          }
        }
      }
    }

    // .time-lapse-export-footer {
    //   display: flex;
    //   align-items: flex-end;
    //   justify-content: center;
    //   height: $FooterH;
    //   // background-color: #00f;

    //   .copyright {
    //     color: $color_divider;
    //   }
    // }
  }

}
</style>
