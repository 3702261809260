<template>
  <transition name="modal">
    <div class="device-param-wrap">
      <div class="device-param-modal" @click="onClose"></div>
      <div class="device-param-block">
        <div class="device-param-header">
          <span>{{ $t('device_param_title') }}</span>
        </div>
        <div class="device-param-common-wrap">
          <div class="common-left-wrap">
            <div class="device-param-common-item group">
              <img src="@/assets/icons/group-icon.svg" alt="">
              <span>{{ groupName }}</span>
            </div>
            <div class="device-param-common-item">
              <el-tooltip
                popper-class="el-tooltip"
                effect="dark"
                v-delTabIndex
                :disabled="!accountIcon.name ? true : false"
                :content="accountIcon.name"
              >
                <img :src="accountIcon.img" alt="" />
              </el-tooltip>
              <span>{{ accountLabel }}</span>
            </div>
            <div v-if="isAicam" class="device-param-common-item second-camera">
              <!-- <el-tooltip
                popper-class="el-tooltip"
                effect="dark"
                v-delTabIndex
                :disabled="!account2Icon.name ? true : false"
                :content="account2Icon.name"
              > -->
                <img src="" alt="" />
              <!-- </el-tooltip> -->
              <span>{{ account2Label }}</span>
            </div>
          </div>
          <div class="common-right-wrap">
            <!-- 若該設備帳號的 參數來源 為 設備客製, 才能被編輯-->
            <SmallTextBtn
              v-show="!isStatus && !bEdit"
              :active="bEdit"
              :disabled="isEmptyConfig || editBtnTooltip ? true : false"
              :label="$t('edit') /*編輯*/"
              :tooltip="editBtnTooltip"
              @click="onEdit"
            />
            <div v-if="bDevMode" class="setting-version">
              <span>{{ settingVersion }}</span>
            </div>
          </div>
        </div>
        <TabList
          class="device-param-tab"
          :tabList="tabList"
          :tabIdx="tabIdx"
          :disabledList="disabledTabList"
          @select="onSelectTab"
        />
        <div class="device-param-content">
          <div v-if="config === null" class="config loading">
            <div class="loading"></div>
            <span>{{ $t('loading') }}</span>
          </div>
          <div v-else-if="JSON.stringify(config) === '{}'" class="config empty">
            <span>{{ `該設備未支援參數設定` /*`該設備類型（${accountIcon.name}）未支援參數設定`*/ }}</span>
          </div>
          <template v-else>
            <!-- <transition> -->
            <component
              ref="param"
              :is="tabView"
              :bEdit="bEdit"
              :account="account"
              :status="status"
              :config="config"
              :deviceModel="deviceModel"
              :deviceModelId="deviceModelId"
              :isAiCamBFV="isAiCamBFV"
              :aiModelType="aiModelType"
            ></component>
            <!-- </transition> -->
          </template>
        </div>
        <div class="device-param-footer">
          <template v-if="bEdit">
            <button class="btn cancel" :disabled="false" @click="onCancel">
              {{ $t('cancel') }}
            </button>
            <button class="btn" :disabled="false" @click="onOpenConfirm">
              {{ $t('confirm') }}
            </button>
          </template>
          <button v-else class="btn" :disabled="false" @click="$emit('close')">
            {{ $t('close') }}
          </button>
        </div>
      </div>
      <MessageModal
        v-if="bConfirm"
        :header="`修改設備參數`"
        :title="`您確定要修改參數嗎？`"
        :btns="{ cancel: true, confirm: true }"
        @close="onCloseConfirm"
        @cancel="onCloseConfirm"
        @confirm="onSave"
      />
    </div>
  </transition>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} from 'vuex'

// import { YYYYMMDD, HHmmss, ms2hhmmss } from '@/utils/lib.js'
import {
  apiCheckStatus,
  apiErrorMsg,

  apiGetDefaultSetting,
  apiGetUserSetting,
  apiPutUserSetting,
} from '@/api/index.js'
import { mergeNameId } from '@/utils/index.js'
import {
  isDevMode,
} from '@/utils/lib.js'
import {
  deviceModelClass,
  iosBitrateModeList,
  androidBitrateModeList,
} from '@/utils/parameter.js'
import {
  getDeviceModelIcon,

  euDeviceModelId,
  patrolDeviceModelId,
  bovicastDeviceModelId,
  aicamDeviceModelId,
  virtualDeviceModelId,

  euDefaultSetting,
} from '@/config/account.js'

import SmallTextBtn from '@/components/Base/SmallTextBtn.vue'
import TabList from '@/components/Base/TabList.vue'
import DeviceParamStatus from '@/components/DeviceParam/DeviceParamStatus.vue'
import DeviceParamVideo from '@/components/DeviceParam/DeviceParamVideo.vue'
import DeviceParamSetting from '@/components/DeviceParam/DeviceParamSetting.vue'
import MessageModal from '@/components/Base/MessageModal.vue'

const DeviceParamTab = {
  status: 0, // 設備狀態
  video: 1, // 影像設定
  setting: 2, // 參數設定
}

export default {
  name: 'DeviceParamModal',
  components: {
    SmallTextBtn,
    TabList,

    DeviceParamStatus,
    DeviceParamVideo,
    DeviceParamSetting,

    MessageModal
  },
  props: {
    account: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      // ...mapState([]),
      tabIdx: DeviceParamTab.video,
      bEdit: false,

      defSetting: null, // null:尚未取得過; {}:取得過但沒資料
      userSetting: null, // null:尚未取得過; {}:取得過但沒資料
      config: null,

      bConfirm: false,
    }
  },
  computed: {
    ...mapGetters([]),
    ...mapState('global', ['deviceModels', 'groupList', 'accList']),
    bDevMode() {
      return isDevMode()
    },
    settingVersion() {
      if (
        this.isEmptyData(this.defSetting) ||
        this.isEmptyObject(this.defSetting)
      )
        return null

      let version = 'v'
      let range = []
      if (this.isDefaultSetting) {
        range = [...this.defSetting.setting.versionRange]
      } else {
        if (
          !this.isEmptyData(this.userSetting) &&
          !this.isEmptyObject(this.userSetting)
        ) {
          range = [...this.userSetting.setting.versionRange]
        } else {
          range = [...this.defSetting.setting.versionRange]
        }
      }

      // console.log(`[settingVersion] range:`, range)
      version += range.join('.')

      return version
    },
    deviceModelId() {
      return this.account ? this.account.deviceModelId : 0
    },
    isDefaultSetting() {
      return this.account
        ? this.account.defaultSetting === euDefaultSetting.global
        : true
    },
    status() {
      // TODO
      return null
    },
    editBtnTooltip() {
      if (this.isDefaultSetting) {
        return '非設備客製參數' // TODO
      } else if (this.isEmptyConfig) {
        return `該設備未支援參數設定` /*`該設備類型（${this.accountIcon.name}）未支援參數設定`*/ // TODO
      }
      return null
    },
    isStatus() {
      return this.tabIdx === DeviceParamTab.status
    },
    deviceModel() {
      if (!this.account) return null

      let deviceModelId = this.deviceModelId
      // deviceModelId = 4000 // DBG

      if (patrolDeviceModelId.includes(deviceModelId)) {
        return deviceModelClass.patrol
      } else if (bovicastDeviceModelId.includes(deviceModelId)) {
        return deviceModelClass.bovicast
      } else if (aicamDeviceModelId.includes(deviceModelId)) {
        return deviceModelClass.aicam
      } else if (virtualDeviceModelId.includes(deviceModelId)) {
        return deviceModelClass.virtual
      } else {
        return null
      }
    },
    isAicam() {
      return this.deviceModel === deviceModelClass.aicam
    },
    isAiCamBFV() { // 分貝計
      if (!this.config) return false

      // 3/27 改用 userConfig.setting.type || defaultConfig.setting.type 判斷
      // console.log(`[C.isAiCamBFV] config.setting.type:`, this.config.setting.type)
      return this.config.setting.type === euDeviceModelId.AiCam_BFV

      // return this.deviceModelId === euDeviceModelId.AiCam_BFV
    },
    groupName() {
      const group = this.groupList.find((_g) => _g.id === this.account.groupId)
      return group ? group.name : null
    },
    accountIcon() {
      if (this.deviceModelId === null) {
        return {
          img: require(`@/assets/icons/device_model_0.svg`),
          name: `0`
        }
      }

      return {
        img: require(`@/assets/icons/device_model_${getDeviceModelIcon(this.deviceModelId)}.svg`),
        name: this.deviceModelId !== null ? this.deviceModels.find((model) => model.id === this.deviceModelId)?.name : this.deviceModelId
      }
    },
    accountLabel() {
      if (!this.account) return ''

      const name = this.account.video.title || this.account.info.name
      return mergeNameId(name, this.account.id) //`${name}(${this.account.id})`
    },
    account2() {
      if (
        !this.isAicam ||
        this.isEmptyData(this.config) ||
        this.isEmptyObject(this.config)
      )
        return null

      console.log(`[account2] `, this.config.setting.channel.map((ch) => ch.account))
      // Note: 若 CH1 & CH2 給了相同 設備帳號, 會找不到 CH2 的帳號
      const accCh = this.config.setting.channel.find(
        (_ch) => _ch.account !== this.account.id
      )

      if (accCh) {
        const acc = this.accList.find((_a) => _a.id === accCh.account)
        return acc ? acc : null
      }

      return null
    },
    // account2Icon() {
    //   // TODO
    //   if (!this.isAicam || !this.account2) {
    //     return {
    //       img: require(`@/assets/icons/device_model_${getDeviceModelIcon(
    //         this.deviceModelId // 用當下帳號類型, 取代 ch2 帳號類型
    //       )}.svg`),
    //       name: `0`
    //     }
    //   }

    //   return {
    //     img: require(`@/assets/icons/device_model_${getDeviceModelIcon(this.account2.deviceModelId)}.svg`),
    //     name: this.account2.deviceModelId !== null ? this.deviceModels.find((model) => model.id === this.account2.deviceModelId)?.name : this.account2.deviceModelId
    //   }
    // },
    account2Label() {
      if (!this.isAicam || !this.account2) {
        return ''
      }

      const name = this.account2.video.title || this.account2.info.name
      return mergeNameId(name, this.account2.id) // `${name}(${this.account2.id})`
    },
    tabList() {
      let list = []
      const labels = Object.keys(DeviceParamTab)

      // 設備狀態 ---
      list.push({
        id: DeviceParamTab.status,
        // icon,
        label: this.$t(`device_param_${labels[DeviceParamTab.status]}`),
        // count: 10
      })

      // 影像設定 ---
      if (this.deviceModel) {
      // switch (this.deviceModel) {
      //   case deviceModelClass.patrol:
      //   case deviceModelClass.bovicast:
      //   case deviceModelClass.aicam:
      //   case deviceModelClass.virtual:
        list.push({
          id: DeviceParamTab.video,
          // icon,
          label: this.$t(`device_param_${labels[DeviceParamTab.video]}`),
          // count: 10
        })
      }

      // 參數設定 ---
      if (this.deviceModel && this.deviceModel !== deviceModelClass.virtual) {
      // switch (this.deviceModel) {
      //   case deviceModelClass.patrol:
      //   case deviceModelClass.bovicast:
      //   case deviceModelClass.aicam:
      //   case deviceModelClass.virtual:
        list.push({
          id: DeviceParamTab.setting,
          // icon,
          label: this.$t(`device_param_${labels[DeviceParamTab.setting]}`),
          // count: 10
        })
      }

      // console.log(`[tabList] list:`, list)
      return list
    },
    disabledTabList() {
      const list = [DeviceParamTab.status] // TODO：設備狀態 暫時還沒有資料
      if (!this.bEdit) return list

      return [...list, ...this.tabList.filter((tab) => tab.id !== this.tabIdx).map((tab) => tab.id)]
    },
    tabView() {
      switch(this.tabIdx) {
        case DeviceParamTab.video:
          return 'DeviceParamVideo'
        case DeviceParamTab.setting:
          return 'DeviceParamSetting'
        case DeviceParamTab.status:
        default:
          return 'DeviceParamStatus'
      }
    },
    aiModelType() { // 國家
      // Note: 留意各設備類型的 aiModelType 是否都已存在
      // console.log(`[DeviceParamModal.aiModelType] config:`, this.config)
      if (this.deviceModel === deviceModelClass.patrol) {
        if (!this.config) return 'Tw'

        const type = this.config.setting.aiModelType
        return `${type.charAt(0).toUpperCase()}${type.slice(1)}`
      }
      return null
    },
    isEmptyConfig() {
      return this.isEmptyObject(this.config)
    }
  },
  watch: {},
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    // UI
    // function
    isEmptyData(obj) {
      return obj === undefined || obj === null
    },
    isEmptyObject(obj) {
      return JSON.stringify(obj) === '{}'
    },
    // API
    async fetchSetting() {
      if (this.deviceModelId !== 0) {
        await this.fetchDefaultSetting()
      }

      if (!this.isDefaultSetting) {
        await this.fetchUserSetting()
      }
    },
    async fetchDefaultSetting() {
      const title = `取得系統預設參數` // TODO
      let config = null
      try {
        const res = await apiGetDefaultSetting(this.deviceModelId)
        if (!apiCheckStatus(res)) throw res

        config = JSON.parse(JSON.stringify(res.data))
      } catch (err) {
        this.$notify({
          type: 'error',
          title,
          message: err ? this.$t(apiErrorMsg(err)) : this.$t('api_error')
        })
      } finally {
        this.defSetting = config ? config : {}
        // console.log(`[fetchDefaultSetting] defSetting:`, JSON.stringify(this.defSetting, '', 2))
        console.log(`[fetchDefaultSetting] defSetting:`, this.defSetting)
      }
    },
    async fetchUserSetting() {
      const title = `取得設備客製參數` // TODO
      let config = null
      try {
        const res = await apiGetUserSetting(this.account.index)
        if (!apiCheckStatus(res)) throw res

        config = JSON.parse(JSON.stringify(res.data))
      } catch (err) {
        this.$notify({
          type: 'error',
          title,
          message: err ? this.$t(apiErrorMsg(err)) : this.$t('api_error')
        })
      } finally {
        this.userSetting = config ? config : {}
        // console.log(`[fetchUserSetting] userSetting:`, JSON.stringify(this.userSetting, '', 2))
        console.log(`[fetchUserSetting] userSetting:`, this.userSetting)
      }
    },
    // patchBitrateMode(defCfg, userCfg) {},
    mergeUserSetting(defCfg, userCfg) {
      // 補足需要的參數
      // ex: bitrateModeList

      if (this.isEmptyData(defCfg) || this.isEmptyObject(defCfg)) return defCfg

      const config =
        this.isEmptyData(userCfg) || this.isEmptyObject(userCfg)
          ? defCfg
          : userCfg

      // console.log(`[mergeUserSetting] defCfg:`, defCfg)
      // console.log(`[mergeUserSetting] userCfg:`, userCfg)
      // console.log(`[mergeUserSetting] config:`, config)
      // 補足
      // setting.deviceConfig.publish.bitrateModeList
      // setting.deviceConfig.publish.bitrateModeIndex
      if (this.deviceModel === deviceModelClass.patrol) {
        if (!config.setting.deviceConfig.publish.bitrateModeList) {
          config.setting.deviceConfig.publish.bitrateModeList =
            this.deviceModelId === euDeviceModelId.BoviCast_Android
              ? [...androidBitrateModeList]
              : [...iosBitrateModeList]
          config.setting.deviceConfig.publish.bitrateModeIndex = 0 // 第一個
        }
      } else if (this.deviceModel === deviceModelClass.bovicast) {
        if (!config.setting.deviceConfig.publish.bitrateModeList) {
          config.setting.deviceConfig.publish.bitrateModeList =
            this.deviceModelId === euDeviceModelId.BoviCast_Android
              ? [...androidBitrateModeList]
              : [...iosBitrateModeList]
          config.setting.deviceConfig.publish.bitrateModeIndex = 0 // 第一個
        }
      } else if (this.deviceModel === deviceModelClass.aicam) {
        const chIdx = config.setting.channel.findIndex(
          (_ch) => _ch.account === this.account.id
        )
        if (chIdx >= 0) {
          if (!config.setting.channel[chIdx].publish.bitrateModeList) {
            userCfg.setting.channel[chIdx].publish.bitrateModeList = [
              ...androidBitrateModeList
            ]
            userCfg.setting.channel[chIdx].publish.bitrateModeIndex = 0 // 第一個
          }
        }
      }

      // 完整解析 defaultSetting, userSetting, 以 defaultSetting 為基底;
      // 但以 defaultSetting 為基底, 會漏掉 userSetting 原有參數
      // for (const key in defCfg) {
      //   if (userCfg[key] === undefined) {
      //     continue
      //   } else if (Array.isArray(defCfg[key])) {
      //     if (typeof(defCfg[key][0]) === 'object') {
      //       for (const idx in defCfg[key]) {
      //         this.mergeUserSetting(defCfg[key][idx], userCfg[key][idx])
      //       }
      //     } else {
      //       defCfg[key] = [...userCfg[key]]
      //     }
      //   } else if (typeof(defCfg[key]) === 'object') {
      //     this.mergeUserSetting(defCfg[key], userCfg[key])
      //   } else {
      //     defCfg[key] = userCfg[key]
      //   }
      // }
      // return defCfg

      return config
    },
    // 資料
    init() {
      const defSetting = JSON.parse(JSON.stringify(this.defSetting))
      const userSetting = JSON.parse(JSON.stringify(this.userSetting))

      this.config = this.isDefaultSetting
        ? defSetting
        : this.mergeUserSetting(defSetting, userSetting)

      // console.log(`[DeviceParamModal.init] config:`, JSON.stringify(this.config, '', 2))
    },
    // 事件
    onEdit() {
      this.bEdit = true
    },
    onSelectTab(tIdx) {
      if (this.tabIdx !== tIdx) {
        this.tabIdx = tIdx
      }
    },
    onClose() {
      if (this.bEdit) {
        // TODO
        this.$notify({
          type: 'warning',
          title: `提醒`,
          message: `您已離開編輯設備參數`
        })
      }
      this.$emit('close')
    },
    onCancel() {
      this.bEdit = false
    },
    async onSaveVideoAicam() {
      const title = this.$t('device_param_aicam_save_title' /*設定 AiCam 參數*/)

      try {
        const payload = this.$refs.param.get()
        console.log(`[onSaveVideoAicam] payload:`, payload)

        let setting = JSON.parse(JSON.stringify(this.config.setting))
        const chIdx = setting.channel.findIndex(
          (_ch) => _ch.account === this.account.id
        )

        setting.channel[chIdx].publish = {
          ...setting.channel[chIdx].publish,
          ...payload.publish
        }
        setting.channel[chIdx].record = {
          ...setting.channel[chIdx].record,
          ...payload.record
        }

        setting.deviceConfig.osd = {
          ...setting.deviceConfig.osd,
          ...payload.osd
        }

        let param = {
          userId: `${this.account.index}`,
          setting,
        }
        console.log(`[onSaveVideoAicam] param:`, param)
        const res = await apiPutUserSetting(param)
        if (!apiCheckStatus(res)) throw res

        this.$notify({
          type: 'success',
          title,
          message: this.$t('success'),
        })
      } catch (err) {
        // console.error(`[onSaveVideoAicam] err`, err)
        this.$notify({
          type: 'error',
          title,
          message: err ? this.$t(apiErrorMsg(err)) : this.$t('api_error'),
        })
      } finally {
        // Get new setting
        await this.fetchUserSetting()
        this.init()
      }
    },
    async onSaveVideoBovicast() {
      const title = `設定 Bovicast 參數`

      try {
        const payload = this.$refs.param.get()
        console.log(`[onSaveVideoBovicast] payload`, payload)

        const { isAutoStart, bitrateModeIndex } = payload
        let setting = JSON.parse(JSON.stringify(this.config.setting))
        setting.deviceConfig.publish = {
          ...setting.deviceConfig.publish,
          ...{ isAutoStart, bitrateModeIndex }
        }

        let param = {
          userId: `${this.account.index}`,
          setting,
        }
        // console.log(`[onSaveVideoBovicast] param:`, JSON.stringify(param, '', 2))

        const res = await apiPutUserSetting(param)
        if (!apiCheckStatus(res)) throw res

        this.$notify({
          type: 'success',
          title,
          message: this.$t('success'),
        })
      } catch (err) {
        // console.error(`[onSaveVideoBovicast] err:`, err)
        this.$notify({
          type: 'error',
          title,
          message: err ? this.$t(apiErrorMsg(err)) : this.$t('api_error'),
        })
      } finally {
        // Get new setting
        await this.fetchUserSetting()
        this.init()
      }
    },
    async onSaveVideoPatrol() {
      const title = `設定 Patrol 參數`

      try {
        const payload = this.$refs.param.get()
        const {
          channelIndex,
          isAutoStart,
          isAudioMute,
          videoCodecIndex,
          profileIndex,
          profileIndex265,
          bitrateModeIndex
        } = payload

        // console.log(`[onSaveVideoPatrol] payload:`, payload)

        let setting = JSON.parse(JSON.stringify(this.config.setting))
        setting.deviceConfig.publish = {
          ...this.config.setting.deviceConfig.publish,
          ...{
            channelIndex,
            isAutoStart,
            isAudioMute,
            videoCodecIndex,
            profileIndex,
            profileIndex265,
            bitrateModeIndex
          }
        }
        let param = {
          userId: `${this.account.index}`,
          setting,
        }
        console.log(`[onSaveVideoPatrol] param:`, JSON.stringify(param, '', 2))
        const res = await apiPutUserSetting(param)
        if (!apiCheckStatus(res)) throw res

        this.$notify({
          type: 'success',
          title,
          message: this.$t('success'),
        })
      } catch (err) {
        this.$notify({
          type: 'error',
          title,
          message: err ? this.$t(apiErrorMsg(err)) : this.$t('api_error'),
        })
      } finally {
        // Get new setting
        await this.fetchUserSetting()
        this.init()
      }
    },
    async onSaveVideo() {
      console.log(`[onSaveVideo] `, this.deviceModel)
      switch (this.deviceModel) {
        case deviceModelClass.aicam:
          await this.onSaveVideoAicam()
          break
        case deviceModelClass.bovicast:
          await this.onSaveVideoBovicast()
          break
        case deviceModelClass.patrol:
          await this.onSaveVideoPatrol()
          break
        case deviceModelClass.virtual:
          // TODO
          break
        default:
          break
      }
      this.bEdit = false
    },
    async onSaveSettingAicam() {
      const title = this.$t('device_param_aicam_save_title' /*設定 AiCam 參數*/)

      try {
        const payload = this.$refs.param.get()
        console.log(`[onSaveSettingAicam] payload:`, payload)

        let setting = JSON.parse(JSON.stringify(this.config.setting))
        const keys = Object.keys(payload)

        for (const key of keys) {
          setting.deviceConfig[key] = {
            ...setting.deviceConfig[key],
            ...payload[key]
          }
        }

        let param = {
          userId: `${this.account.index}`,
          setting,
        }
        // console.log(`[onSaveSettingAicam] param:`, JSON.stringify(param, '', 2))

        const res = await apiPutUserSetting(param)
        if (!apiCheckStatus(res)) throw res

        this.$notify({
          type: 'success',
          title,
          message: this.$t('success'),
        })
      } catch (err) {
        this.$notify({
          type: 'error',
          title,
          message: err ? this.$t(apiErrorMsg(err)) : this.$t('api_error'),
        })
      } finally {
        // Get new setting
        await this.fetchUserSetting()
        this.init()
      }
    },
    async onSaveSettingBovicast() {
      const title = `設定 Bovicast 參數`

      try {
        const payload = this.$refs.param.get()
        console.log(`[onSaveSettingBovicast] payload:`, payload)

        let setting = JSON.parse(JSON.stringify(this.config.setting))
        const keys = Object.keys(payload)

        for (const key of keys) {
          setting.deviceConfig[key] = {
            ...setting.deviceConfig[key],
            ...payload[key]
          }
        }

        let param = {
          userId: `${this.account.index}`,
          setting,
        }
        // console.log(`[onSaveSettingAicam] param:`, JSON.stringify(param, '', 2))

        const res = await apiPutUserSetting(param)
        if (!apiCheckStatus(res)) throw res

        this.$notify({
          type: 'success',
          title,
          message: this.$t('success'),
        })
      } catch (err) {
        // console.err(`[onSaveSettingBovicast] err:`, err)
        this.$notify({
          type: 'error',
          title,
          message: err ? this.$t(apiErrorMsg(err)) : this.$t('api_error'),
        })
      } finally {
        // Get new setting
        await this.fetchUserSetting()
        this.init()
      }
    },
    async onSaveSettingPatrol() {
      const title = `設定 Patrol 參數`

      try {
        const payload = this.$refs.param.get()
        const {
          // 充電狀態連動控制
          isChargingControl,
          // 介面
          btnLongPressTime,
          // 語音通話
          isAutoAnswer,
          // 公告
          announcementNotifyType,
          announcementDisplayType,
          // 事件
          audioNotifyType,
          isAnnounceMissionNote,
          isAnnounceTagNote,
          chaseEventPopupTime,
          jpgQuality,
          // 診斷
          minAudioVolume,
        } = payload
        // console.log(`[onSaveSettingPatrol] payload:`, payload)

        let setting = JSON.parse(JSON.stringify(this.config.setting))

        const lprType = `lpr${this.aiModelType}`
        setting.deviceConfig[lprType].isChargingControl = isChargingControl
        setting.deviceConfig[lprType].announcementNotifyType = announcementNotifyType
        setting.deviceConfig[lprType].announcementDisplayType = announcementDisplayType
        setting.deviceConfig[lprType].isAnnounceMissionNote = isAnnounceMissionNote
        setting.deviceConfig[lprType].isAnnounceTagNote = isAnnounceTagNote
        setting.deviceConfig[lprType].chaseEventPopupTime = chaseEventPopupTime
        setting.deviceConfig[lprType].jpgQuality = jpgQuality
        setting.deviceConfig[lprType].minAudioVolume = minAudioVolume
        setting.deviceConfig.system.btnLongPressTime = btnLongPressTime
        setting.deviceConfig.webrtc.isAutoAnswer = isAutoAnswer
        setting.deviceConfig.notify.audioNotifyType = audioNotifyType

        let param = {
          userId: `${this.account.index}`,
          setting,
        }
        console.log(`[onSaveSettingPatrol] param:`, JSON.stringify(param, '', 2))
        const res = await apiPutUserSetting(param)
        if (!apiCheckStatus(res)) throw res

        this.$notify({
          type: 'success',
          title,
          message: this.$t('success'),
        })
      } catch (err) {
        this.$notify({
          type: 'error',
          title,
          message: err ? this.$t(apiErrorMsg(err)) : this.$t('api_error'),
        })
      } finally {
        // Get new setting
        await this.fetchUserSetting()
        this.init()
      }
    },
    async onSaveSetting() {
      console.log(`[onSaveSetting] `, this.deviceModel)
      switch (this.deviceModel) {
        case deviceModelClass.aicam:
          await this.onSaveSettingAicam()
          break
        case deviceModelClass.bovicast:
          await this.onSaveSettingBovicast()
          break
        case deviceModelClass.patrol:
          await this.onSaveSettingPatrol()
          break
        case deviceModelClass.virtual:
        default:
          break
      }
      this.bEdit = false
    },
    async onSave() {
      switch (this.tabIdx) {
        case DeviceParamTab.video:
          await this.onSaveVideo()
          break
        case DeviceParamTab.setting:
          await this.onSaveSetting()
          break
        case DeviceParamTab.status:
        default:
          break
      }

      this.onCloseConfirm()
    },
    onOpenConfirm() {
      this.bConfirm = true
    },
    onCloseConfirm() {
      this.bConfirm = false
    },
  },
  // async created() {},
  async mounted() {
    // console.log(`[DeviceParamModal.mounted] account:`, this.account)
    if (this.account) {
      await this.fetchSetting()
      this.init()
    }
  },
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
$YGap: px2rem(32);
// $ItemH: px2rem(36);
$MgrW: calc(660 / 1280 * 100%);
$HeaderH: px2rem(48);
$TabH: px2rem(48);
* {
  box-sizing: border-box;
  // user-select: none;
}
.device-param-wrap {
  @include modal-wrap;
  z-index: 4;

  .device-param-modal {
    @include modal-bg;
  }

  .device-param-block {
    @include modal-content;
    left: calc((100vw - $MgrW) / 2);
    margin: $YGap auto;
    border: 1px solid $color_4A5C78;
    width: $MgrW;
    height: calc(100vh - $YGap - $YGap/2);
    background-color: $color_282942;
    color: $color_FFF;
    // background-color: #f00;

    .device-param-header {
      padding-top: px2rem(32);
      font-size: 2rem;
      @include font_bold;
      text-align: center;
    }
    .device-param-common-wrap {
      display: flex;
      justify-content: space-between;
      padding-top: px2rem(16);
      padding-right: px2rem(32);
      padding-left: px2rem(32);

      .common-left-wrap {
        // background-color: #0f0;
      }
      .common-right-wrap {
        display: flex;
        align-self: flex-end;
        // background-color: #00f;

        .setting-version {
          align-self: flex-end;
          margin-left: 0.5rem;
          font-size: px2rem(12);
          color: $color_FFF_50;
        }
      }
      .device-param-common-item {
        display: flex;
        align-items: center;
        line-height: 1.5;
        &.group {
          color: $color_group;
        }
        img {
          width: 1rem;
          height: 1rem;
        }
        span {
          margin-left: 0.5rem;
        }

        &.second-camera {
          font-size: px2rem(12);
          line-height: 1.5;
          color: $color_FFF_50;

          img {
            visibility: hidden;
          }
        }
      }
    }
    .device-param-tab {
      border-bottom: 1px solid $color_divider;
      padding-left: px2rem(32);
    }

    .device-param-content {
      flex-grow: 1;
      // background-color: #f00;
      min-height: 0;

      .config {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        // background-color: #f00;

        &.loading {
          color: $color_FFF_50;
          .loading {
            @include loading(1rem, $color_FFF_50);
          }
          span {
            margin-left: 0.5rem;
          }
        }
      }
    }
    .device-param-footer {
      display: flex;
      justify-content: center;
      margin-bottom: px2rem(12);
      border-top: 1px solid $color_divider;
      padding-top: 1rem;

      .btn {
        margin-right: px2rem(12);
        border: 1px solid $color_FFC600;
        border-radius: 0.5rem;
        width: px2rem(114);
        height: px2rem(44);
        color: $color_282942;
        background-color: $color_FFC600;

        &:hover:not(:disabled) {
          background-color: $color_FFD133;
        }
        &:disabled {
          @include disabled;
        }

        &.cancel {
          border-color: $color_E6E6E6;
          color: $color_E6E6E6;
          background-color: transparent;

          &:hover:not(:disabled) {
            background-color: $color_E6E6E6_10;
          }
          &:disabled {
            @include disabled;
          }
        }

      }
    }
  }
}
</style>