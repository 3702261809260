<template>
  <div class="wrap-group-user-tree" v-show="leftMenuStatus">
    <div class="search-box">
      <input
        type="text"
        v-model="searchInput"
        class="search-input"
        :placeholder="$t('tree_search')"
        @keyup.enter="reloadGroupUserTree"
      />
      <span
        v-if="searchInput.length > 0"
        class="clear"
        @click="clearSearchInput"
      ></span>
      <button class="search-button" @click="reloadGroupUserTree">
        <img src="@/assets/icons/search.svg" alt="" />
      </button>
    </div>

    <div class="online-wrap">
      <ToggleSwitch
        class="online-switch"
        :suffix="$t('tree_online') /*上線/關注設備*/"
        v-model="showOnline"
      />
      <div class="online-count">
        {{ $t('tree_online_all') }}：<span>{{ onlineNumber }}</span> /
        {{ userList.length }}
      </div>
    </div>

    <div class="wrap-tree" ref="wrapTree">
      <el-tree
        ref="tree"
        :data="treeData"
        :props="defaultProps"
        show-checkbox
        :empty-text="$t('tree_processing_info')"
        node-key="id"
        :default-expanded-keys="expandKeys"
        default-expand-all
        @check="handleCheckChange"
        @node-contextmenu="handleRightClick"
      >
        <div
          :id="data.id"
          class="custom-tree-node"
          slot-scope="{ node, data }"
          :class="[
            getOnlineStatus(data.id) ? 'online' : 'offline',
            data.id === openPopUpMenuUser?.id ? 'selected' : ''
          ]"
        >
          <div class="icons" v-if="!data.children" @click="showLiveVideo(data)">
            <div class="icon" v-if="getSosStatus(data.id)">
              <img src="@/assets/icons/sos.svg" alt="" />
            </div>
            <div class="icon" v-else-if="getChasingStatus(data.id)">
              <img src="@/assets/icons/urgent.svg" alt="" />
            </div>
            <div v-else-if="getLiveStatus(data.id)" class="icon live">
              <img src="@/assets/icons/live.svg" alt="" />
            </div>
          </div>
          <div
            class="label"
            :class="{
              group: data.children,
              account: !data.children,
              track: data.id == trackUser.id
            }"
            @click="handleNodeClick(data)"
          >
            <img
              v-if="data.children"
              src="@/assets/icons/Group-yellow.svg"
              alt=""
            />
            <div
              :id="data.id + '_id'"
              contenteditable="false"
              @keyup.enter="changeName"
              draggable
              @dragstart="dragStart(data)"
            >
              {{ node.label }}
            </div>
            <span class="count" v-if="data.children">
              (<span>{{ getChildrenOnlineNumber(data.children) }}</span
              >/{{ getChildrenUserNumber(data.groupId) }})
            </span>
          </div>
        </div>
      </el-tree>
      <div class="pop-up-menu" ref="popUpMenu" v-if="openPopUpMenu">
        <div v-if="popUpMenuMode !== ''" class="popup-wrap">
          <!-- <i class="el-icon-close" @click="closePopUpMenu"></i> -->
          <button
            v-if="popUpMenuMode == 'user' && permissionV2.deviceCtrl > 0"
            :disabled="false"
            class="popup-option"
            @click="modifyName"
          >
            <div><img src="@/assets/icons/pen.svg" /></div>
            <span>{{ $t('tree_change_video_title' /*修改影片標題*/) }}</span>
          </button>
          <button
            v-else-if="popUpMenuMode == 'group' && permissionV2.group > 1"
            :disabled="false"
            class="popup-option"
            @click="modifyName"
          >
            <div><img src="@/assets/icons/pen.svg" /></div>
            <span>{{ $t('tree_change_group_name' /*修改群組名稱*/) }}</span>
          </button>
          <button class="popup-option" @click="handleCallModal" :disabled="false">
            <div><img src="@/assets/icons/phone.svg" alt="" /></div>
            <span>
              {{
                popUpMenuMode == 'group'
                  ? $t('tree_push_talk' /*PTT群組通話*/)
                  : $t('tree_voice_call' /*一對一通話*/)
              }}
            </span>
          </button>
          <button class="popup-option" @click="onlyChecked" :disabled="false">
            <div><img src="@/assets/icons/check.svg" /></div>
            <span>
              {{
                popUpMenuMode == 'group'
                  ? $t('tree_select_group' /*只勾選此群組*/)
                  : $t('tree_select_account' /*只勾選此設備*/)
              }}
            </span>
          </button>
          <button
            v-if="popUpMenuMode == 'user' && permissionV2.deviceAISetting > 0"
            :disabled="disableRecgSetting"
            class="popup-option"
            @click="openAiBox"
          >
            <div><img src="@/assets/icons/recognition.svg" /></div>
            <div>{{ $t('ai_recognition_setting' /*辨識設定*/) }}</div>
          </button>
          <button
            v-if="popUpMenuMode == 'user' && permissionV2.deviceCtrl > 2"
            :disabled="disableDeviceParam"
            class="popup-option"
            @click="openDeviceParam"
          >
            <div><img src="@/assets/icons/topbar-setting.svg" /></div>
            <div>{{ $t('device_param_title' /*設備參數*/) }}</div>
          </button>
          <button
            v-if="popUpMenuMode == 'user' /*&& permissionV2.parking*/ && bDevMode"
            :disabled="false"
            class="popup-option"
            @click="openKanban"
          >
            <div><i class="fa fa-parking" /></div>
            <div>{{ kanbanEntry }}</div>
          </button>
          <button
            v-if="popUpMenuMode === 'user'"
            :disabled="false"
            class="popup-option"
            @click="openAccountModal"
          >
            <div><img src="@/assets/icons/info.svg" alt="" /></div>
            <span>{{ /*showAccountTitle*/ $t('tree_account_info' /*帳號資訊*/) }}</span>
          </button>
          <button
            v-if="permissionV2.videoManagement && popUpMenuMode !== 'group'"
            :disabled="false"
            class="popup-option"
            @click="onOpenVideoEditor"
          >
            <div><img src="@/assets/icons/clock.svg" /></div>
            <div>{{ $t('tree_video_export') }}</div>
            <!-- <div>{{ $t('tree_video_edit') }}</div> -->
          </button>
        </div>
      </div>
    </div>
    <DeviceParamModal
      v-if="showDeviceParam"
      :account="rightClickUser"
      @close="showDeviceParam = false"
    />
    <VideoEditor
      v-if="bVideoEditor"
      :account="rightClickUser"
      @close="bVideoEditor = false"
    />
    <Kanban v-if="showKanban" :account="rightClickUser" @close="closeKanban" />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import {
  apiGetUserList,
  apiGetGroupTree,
  getUserChannedId,
  apiEditUser,
  apiEditGroup
} from '@/api/index.js'
import { isDevMode } from '@/utils/lib.js'
import {
  // patrolDeviceModelId,
  // bovicastDeviceModelId,
  aicamDeviceModelId,
  // virtualDeviceModelId,
} from '@/config/account.js'

import ToggleSwitch from '@/components/Base/ToggleSwitch.vue'
import VideoEditor from '@/components/VideoEditor.vue'
import DeviceParamModal from '@/components/DeviceParamModal.vue'
import Kanban from '@/components/Kanban.vue'

export default {
  name: 'Sidebar',
  components: {
    ToggleSwitch,
    VideoEditor,
    DeviceParamModal,
    Kanban,
  },
  data() {
    return {
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      expandKeys: [],
      searchInput: '',
      openPopUpMenu: false,
      popUpMenuMode: '',
      openPopUpMenuUser: null,
      clicks: 0,
      clickTimeoutId: null,
      tempCallAccount: '',
      tempCallId: '',
      targetData: {},
      rightClickUser: {},
      isEditMode: false,
      timer: null,
      isClickShowLiveVideo: false,

      bVideoEditor: false,
      showDeviceParam: false,
      showKanban: false,
    }
  },
  computed: {
    ...mapState([
      'connectionList',
      'liveList',
      'eventList',
      'LeftMenuOpen',
      'selectedUsers',
      'trackUser',
      'TopMenuHeightLevel',
      'singleUrlUserID',
      'userList',
      'groupTree',
      'staff',
      'permissionV2'
    ]),
    ...mapState('account', ['privateDeviceList']),
    bDevMode() {
      return isDevMode() // && false
    },
    disableRecgSetting() {
      // 若不是可視範圍的設備，不開放辨識設定
      return this.privateDeviceList.findIndex(
        (device) => device.id === this.rightClickUser.id
      ) === -1
    },
    disableDeviceParam() {
      const disabled = !this.rightClickUser.deviceModelId ? true : false
      // TODO：只開放開發好的機種
      const isDone = aicamDeviceModelId.includes(
        this.rightClickUser.deviceModelId
      )

      return this.bDevMode ? disabled : disabled || !isDone
    },
    showOnline: {
      get() {
        return this.$store.state.showOnlineOnly
      },
      set(value) {
        this.$store.commit('updateShowOnlineOnly', value)
      }
    },
    callImgUrl() {
      if (this.popUpMenuMode == 'group') {
        return require('@/assets/icons/join-call.svg')
      }
      return require('@/assets/icons/phone.svg')
    },
    onlineNumber() {
      // 計算連線數目：包含liveList & connectionList
      let count = this.connectionList.length
      this.liveList.forEach((live) => {
        let idx = this.connectionList.findIndex(
          (conn) => conn.userAccount == live.id
        )
        if (idx == -1) count += 1
      })
      return count
    },
    publicParam() {
      let param = 1
      switch (this.permissionV2.dashboard) {
        case 1:
          param = 0
          break
        case 2:
          param = null
          break
      }
      return param
    },
    leftMenuStatus() {
      // 如果不是dashboard頁面就關閉left menu, LeftMenuOpen 紀錄離開dashboard時 left menu 的狀態
      return this.$route.path !== '/dashboard' ? false : this.LeftMenuOpen
    },
    // showAccountTitle() {
    //   // 0: default, 1: SSO(單簽), 2: device, 3: user
    //   let title = ''
    //   switch (this.rightClickUser.kind) {
    //     case 1:
    //     case 3:
    //       title += this.$t('tree_view_user') /*檢視使用者*/
    //       break
    //     case 2:
    //       title += this.$t('tree_view_device') /*檢視設備*/
    //       break
    //     default:
    //       title += this.$t('tree_view_account') /*檢視帳號*/
    //       break
    //   }
    //   return title
    // }
    kanbanEntry() {
      return '車輛看板'
    }
  },
  watch: {
    // 在el-tree裡面滾動就關掉pop up menu
    openPopUpMenu() {
      if (this.openPopUpMenu) {
        this.$refs.wrapTree.addEventListener('scroll', this.closePopUpMenu)
        // 監聽window  click就關掉left bar pop up menu
        window.addEventListener('click', this.closePopUpMenu)
      } else {
        this.$refs.wrapTree.removeEventListener('scroll', this.closePopUpMenu)
        window.removeEventListener('click', this.closePopUpMenu)
        this.openPopUpMenuUser = null
      }
    },
    'liveList.length'() {
      this.reloadGroupUserTree()
    },
    'connectionList.length'() {
      this.reloadGroupUserTree()
    },
    'selectedUsers.length'() {
      this.reloadGroupUserTree()

      // 更新 singleUrlUserID
      if (
        this.selectedUsers.length > 0 &&
        !this.selectedUsers.find((user) => user.id === this.singleUrlUserID)
      ) {
        this.$store.commit('updateSingleUrlUserID', this.selectedUsers[0].id)
      }

      if (this.selectedUsers.length === 0) {
        this.$store.commit('updateSingleUrlUserID', '')
        this.$store.dispatch('resetVideoGpsMarkerPath')
      }
    },
    'trackUser.id'() {
      if (this.trackUser.id)
        document
          .getElementById(this.trackUser.id)
          .scrollIntoView({ behavior: 'smooth', block: 'center' })
    },
    showOnline() {
      this.reloadGroupUserTree()
    }
  },
  created() {
    window.addEventListener('click', (e) => {
      if (!this.$el.contains(e.target)) {
        this.leaveModifyName()
      }
    })
  },
  async mounted() {
    await this.getGroupTree() // tree僅需在ㄧ開始取一次
    // 在dashboard頁面才執行每分鐘更新
    if (this.$route.path === '/dashboard')
      this.recursiveUpdateTree() // 每一分鐘取userList，更新user tree data
    else this.reloadGroupUserTree()
  },
  beforeDestroy() {
    this.$refs.wrapTree.removeEventListener('scroll', this.closePopUpMenu)
    if (this.timer) clearTimeout(this.timer)
  },
  methods: {
    ...mapMutations(['updateRightClickUser', 'updateShowAccountInfoModal']),
    ...mapMutations('aibox', ['updateShowAiBox']),
    recursiveUpdateTree() {
      try {
        this.reloadGroupUserTree()
      } catch (err) {
        console.log(err)
      } finally {
        // 1分鐘後再重複執行reloadGroupUserTree
        this.timer = setTimeout(() => {
          this.recursiveUpdateTree()
        }, 60000)
      }
    },
    reloadGroupUserTree() {
      this.getGroupUserTree()
      // 重新把先前選擇的帳號selectedUsers勾選起來
      this.reCheckSelectedUsers()
    },
    handleCallModal() {
      this.openPopUpMenu = false
      this.$emit(
        'assignCallInfo',
        this.popUpMenuMode,
        this.tempCallAccount,
        this.tempCallId
      )
    },
    openAccountModal() {
      this.openPopUpMenu = false
      this.updateShowAccountInfoModal(true)
    },
    openAiBox() {
      this.updateShowAiBox(true)
      this.openPopUpMenu = false
    },
    openDeviceParam() {
      // TODO
      this.showDeviceParam = true
      this.openPopUpMenu = false
    },
    closePopUpMenu() {
      this.openPopUpMenu = false
    },
    async getChannelId(id) {
      try {
        let res = await getUserChannedId(id)
        this.tempCallId = res.data.index
      } catch (e) {
        console.log('channesid 失敗QQ')
      }
    },
    async handleRightClick(e, data, node) {
      this.popUpMenuMode = ''
      this.openPopUpMenu = false
      if ('children' in data) {
        this.popUpMenuMode = 'group'
        this.tempCallAccount = data.label
        this.tempCallId = data.groupId
      } else {
        this.popUpMenuMode = 'user'
        this.tempCallAccount = data.label
        this.rightClickUser = this.userList.find((user) => user.id === data.id)
        this.updateRightClickUser(this.rightClickUser)
        await this.getChannelId(data.id)
      }
      this.openPopUpMenu = true
      this.openPopUpMenuUser = data

      let left = e.pageX
      let top = e.pageY + 5
      this.$nextTick(() => {
        if (this.$refs.tree) {
          this.$refs.tree?.setCurrentKey(node)
        }

        this.leaveModifyName()
        this.targetData = data
        // console.log(this.$refs)
        // let cTop = window.innerHeight - top
        const popUpMenu = this.$refs.popUpMenu.getBoundingClientRect()
        if (window.innerHeight - top < popUpMenu.height)
          top = window.innerHeight - (popUpMenu.height + 5)
        this.$refs.popUpMenu.style.left = left + `px`
        this.$refs.popUpMenu.style.top = top + `px`
        // console.log(this.$refs.popUpMenu.style.top, cTop)
      })
    },
    changeName() {
      let id = this.targetData.id + '_id'
      let el = document.getElementById(id)
      if (this.targetData.children) {
        if (
          el.textContent != this.targetData.label &&
          el.textContent.length > 0
        ) {
          this.editGroup(
            this.targetData.id,
            el.textContent,
            this.targetData.parent
          )
          el.setAttribute('contenteditable', false)
        } else this.leaveModifyName()
      } else {
        if (
          el.textContent != this.targetData.name &&
          el.textContent.length > 0
        ) {
          this.editUser(this.targetData.id, el.textContent)
          el.setAttribute('contenteditable', false)
        } else this.leaveModifyName()
      }
    },
    dragStart(nodeData) {
      this.$store.commit('setDragUser', nodeData)
    },
    async editGroup(userId, userName, parentId) {
      const data = {
        id: userId,
        name: userName,
        parent: parentId
      }
      try {
        const res = await apiEditGroup(data)
        if (res.status == 200) {
          this.reloadGroupUserTree()
          this.targetData.label = userName
          this.leaveModifyName()
        }
      } catch (err) {
        // console.log(err)
        // this.$message.warning("Permission Denied !")
        this.$notify({
          type: 'warning',
          message: 'Permission Denied !'
        })
        this.leaveModifyName()
      }
    },
    async editUser(userId, userName) {
      const data = {
        id: userId,
        videoTitle: userName
      }
      try {
        const res = await apiEditUser(data)
        if (res.status == 200) {
          this.reloadGroupUserTree()
          this.targetData.label = userName + ' (' + this.targetData.id + ')'
          this.targetData.name = userName
          this.leaveModifyName()
        }
      } catch (err) {
        // console.log(err)
        // this.$message.warning("Permission Denied !")
        this.$notify({
          type: 'warning',
          message: 'Permission Denied !'
        })
        this.leaveModifyName()
      }
    },
    leaveModifyName() {
      if (this.targetData.id) {
        let id = this.targetData.id + '_id'
        let el = document.getElementById(id)
        if (el) {
          if (el.hasAttribute('contenteditable')) {
            if (el.getAttribute('contenteditable')) {
              el.setAttribute('contenteditable', false)
              el.textContent = this.targetData.label
              this.isEditMode = false
            }
          }
        }
      }
    },
    modifyName() {
      // 修改名稱
      let id = this.targetData.id + '_id'
      let el = document.getElementById(id)
      el.setAttribute('contenteditable', true)
      if (this.targetData.children) {
        el.textContent = this.targetData.label
      } else {
        el.textContent = this.targetData.name
      }
      el.focus()
      let char = el.textContent.length,
        sel // character at which to place caret
      if (document.selection) {
        sel = document.selection.createRange()
        sel.moveStart('character', char)
        sel.collapse(true)
        sel.select()
      } else {
        sel = window.getSelection()
        sel.collapse(el.lastChild, char)
      }
      this.isEditMode = true
    },
    getChildren(userList, data) {
      // 遞回抓取 子層所有USER
      let tmpUsers = []
      tmpUsers = tmpUsers.concat(
        this.getUserListByGroupId(userList, data.groupId, data.groupName)
      )
      data.children.forEach((item) => {
        if (item.children)
          tmpUsers = tmpUsers.concat(this.getChildren(userList, item))
      })
      return tmpUsers
    },
    onlyChecked() {
      let tmpUsers = []
      if (this.targetData.children) {
        tmpUsers = tmpUsers.concat(
          this.getChildren(this.userList, this.targetData)
        )
      } else {
        // 點在子節點
        tmpUsers.push(this.targetData)
      }
      this.$store.commit('setSelectedUsers', tmpUsers)
      this.reloadGroupUserTree()
    },
    handleCheckChange(data) {
      let tmpUsers = []
      if (data.children) {
        // 點在父節點
        let checkedNodes = this.$refs.tree.getCheckedNodes()
        checkedNodes.forEach((node) => {
          if (!node.children) tmpUsers.push(node)
        })
      } else {
        // 點在子節點
        tmpUsers = [...this.selectedUsers]
        let idx = tmpUsers.findIndex((user) => user.id == data.id)
        if (idx == -1) tmpUsers.push(data)
        else tmpUsers.splice(idx, 1)
      }

      this.$store.commit('setSelectedUsers', tmpUsers)

      // 該裝置是已勾選且也被設為trackUser的情況下，若被取消勾選也要同時取消trackUser的設定
      let user = tmpUsers.find((user) => user.id === this.trackUser.id)
      if (!user) {
        this.$store.commit('setTrackUser', {}) // 取消選取
        // this.closeVideo() // 關閉video
      }

      // 當show online only時，取消勾選非連線裝置，重新取得tree
      if (this.showOnline && !this.getOnlineStatus(data.id)) {
        this.reloadGroupUserTree()
      }
    },
    handleNodeClick(nodeData) {
      this.closePopUpMenu()
      // 點擊群組時，返回
      if (nodeData.children) return
      if (this.isEditMode) {
        // console.log(this.targetData)
        // console.log(nodeData)
        if (this.targetData.id == nodeData.id) return
        else this.leaveModifyName()
      }
      this.clicks++
      if (this.clicks === 1) {
        this.clickTimeoutId = setTimeout(() => {
          // single click: 點選設定為trackUser(地圖上顯示軌跡), 再點一次則取消設定(不顯示其軌跡)
          this.nodeSingleClick(nodeData)
          this.clicks = 0
        }, 600)
      } else {
        clearTimeout(this.clickTimeoutId)
        this.clickTimeoutId = null
        // double click：顯示該帳號即時video, 設定為trackUser, 顯示軌跡
        this.showLiveVideo(nodeData)
        this.clicks = 0
      }
    },
    closeVideo() {
      // 關閉live video, 清空EventCardDetailObj
      this.$store.commit('updateEventCardDetailObj', {})
      this.$store.commit('updateLiveEventOn', {
        sts: false,
        url: '',
        origin: ''
      })
      this.$store.commit('updateTopMenuHeightLevel', 1)
    },
    nodeSingleClick(nodeData) {
      // 單擊：先關閉live video, 清空EventCardDetailObj
      // this.closeVideo()

      // 將該帳號加入selectedUsers(addSelectedUser函式會進行判斷，若該帳號不存在時，則將其加入並新增該帳號marker)
      this.$store.commit('addSelectedUser', nodeData)

      // 設定trackUser
      if (nodeData.id == this.trackUser.id) {
        this.$store.commit('setTrackUser', {}) // 取消選取
      } else {
        this.$store.commit('setTrackUser', nodeData)
      }
    },
    showLiveVideo(nodeUser) {
      if (this.isClickShowLiveVideo) return // 避免user快速點擊重複觸發
      this.isClickShowLiveVideo = true
      setTimeout(() => {
        this.isClickShowLiveVideo = false
      }, 2000)

      this.$store.commit('updateVideoViewMode', 1) // 切換單路模式
      // 若再次點擊相同帳號，利用emit播放單路video
      if (nodeUser.id == this.singleUrlUserID) {
        this.$bus.$emit('playVideoAgain')
      }

      let live = this.liveList.find((item) => item.id == nodeUser.id)
      // if (!live) return

      // 將該帳號加入selectedUsers(addSelectedUser函式會進行判斷，若該帳號不存在時，則將其加入並新增該帳號marker)
      this.$store.commit('addSelectedUser', nodeUser)
      this.$store.commit('setTrackUser', nodeUser)

      this.$bus.$emit('panToTrackUser') // 地圖移動到TrackUser最新的位置

      // 22.02.07 tingyu 把單路userID放到vuex變數
      if (this.selectedUsers.length > 0) {
        this.$store.commit('updateSingleUrlUserID', nodeUser.id)
      } else {
        this.$store.commit('updateSingleUrlUserID', null)
      }

      let typ = 'live'
      typ = this.getChasingStatus(nodeUser.id) ? 'chasing' : typ
      typ = this.getSosStatus(nodeUser.id) ? 'sos' : typ
      this.$store.dispatch('switchLeftAndRightLiveEvent', {
        id: nodeUser.id,
        url: live ? live.mseUrl : null,
        typ: typ
      })

      // 若是SOS事件則開啟群組通話
      if (typ == 'sos') {
        this.$bus.$emit('sosCall', 'group', nodeUser.id, nodeUser.groupId)
      }
    },
    clearSearchInput() {
      this.searchInput = ''
      this.reloadGroupUserTree()
    },
    async getGroupTree() {
      const res = await apiGetGroupTree()
      this.$store.commit('setGroupTree', res.data)
    },
    async getUserList() {
      const res = await apiGetUserList('device', this.publicParam)
      if (res && res.data) this.$store.commit('setUserList', res.data)

      // 同步 selectedUsers 是否有不存在的帳號，若有則移除
      this.syncSelectedUsers()
    },
    syncSelectedUsers() {
      // 同步selectedUsers
      let tmpUsers = []
      this.selectedUsers.forEach((user) => {
        let idx = this.userList.findIndex((item) => item.id === user.id)
        if (idx >= 0) tmpUsers.push(user)
      })
      this.$store.commit('setSelectedUsers', tmpUsers)
    },
    async getGroupUserTree() {
      await this.getGroupTree()
      await this.getUserList()
      this.expandKeys = []
      this.treeData = []

      let arrUsers = this.getUserListByGroupId(
        this.userList,
        this.groupTree.group.id,
        this.groupTree.group.name
      )
      let arrChildren = this.getChildrenData(
        this.groupTree.children,
        this.userList,
        this.groupTree.group.id
      )
      let tmp = {
        id: this.groupTree.group.id,
        groupId: this.groupTree.group.id, // tree的資料須有一樣的結構，故將id設給groupId
        label: this.groupTree.group.name, // 群組只顯示群組名稱
        children: arrUsers.concat(arrChildren),
        parent: 0
      }
      this.treeData.push(tmp)
    },
    getUserListByGroupId(userList, NodeGroupId, NodeGroupName) {
      let fItem = this.searchInput.toLowerCase() // 轉換為小寫
      // 先檢查NodeGroupName是否含有fItem，若有則返回此節點的所有user;若無則進行search的比對
      let bGroupMatch
      if (NodeGroupName)
        bGroupMatch = NodeGroupName.toLowerCase().includes(fItem)
      let users = userList.filter((user) => {
        if (this.showOnline) {
          if (this.searchInput && !bGroupMatch) {
            return (
              user.groupId == NodeGroupId &&
              this.getOnlineStatus(user.id) &&
              (user.video.title.toLowerCase().includes(fItem) ||
                user.id.toLowerCase().includes(fItem))
            )
          } else {
            return (
              user.groupId == NodeGroupId &&
              (this.getOnlineStatus(user.id) || this.checkUserSelected(user.id))
            )
          }
        } else {
          if (this.searchInput && !bGroupMatch) {
            return (
              user.groupId == NodeGroupId &&
              (user.video.title.toLowerCase().includes(fItem) ||
                user.id.toLowerCase().includes(fItem))
            )
          } else {
            return user.groupId == NodeGroupId
          }
        }
      })

      let arrUsers = []
      users.forEach((user) => {
        arrUsers.push({
          id: user.id,
          name: user.video.title,
          groupId: user.groupId,
          label: user.video.title + ' (' + user.id + ')',
          index: user.index
        })
      })
      return arrUsers
    },
    getChildrenData(data, userList, groupId) {
      let arrChildren = []
      if (data) {
        data.forEach((item) => {
          let arrUsers = this.getUserListByGroupId(
            userList,
            item.group.id,
            item.group.name
          )

          let arrNode = item.children
            ? this.getChildrenData(item.children, userList, item.group.id)
            : []

          let fItem = this.searchInput.toLowerCase() // 轉換為小寫
          let bInclude =
            fItem != '' &&
            (item.group.id.toLowerCase().includes(fItem) ||
              item.group.name.toLowerCase().includes(fItem))

          if (arrUsers.length > 0 || arrNode.length > 0 || bInclude) {
            this.expandKeys.push(item.group.id)
            arrChildren.push({
              id: item.group.id,
              groupId: item.group.id,
              label: item.group.name, // 群組只顯示群組名稱
              children: arrUsers.concat(arrNode),
              parent: groupId
            })
          }
        })
      }
      return arrChildren
    },
    getOnlineStatus(userId) {
      // 若userId在connectionList中表示有連結，返回true，反之返回false
      // 2022.05.14 若裝置有在connectionList或是在liveList中，表示online
      let idxConn = this.connectionList.findIndex(
        (item) => item.userAccount == userId
      )
      let idxLive = this.liveList.findIndex((item) => item.id == userId)
      return idxConn >= 0 || idxLive >= 0 ? true : false
    },
    getSosStatus(userId) {
      // connections API 中 SOS 欄位只要 > 0, 就表示有 SOS 事件 (>0 的數字是 SOS 的事件 id)
      let idx = this.connectionList.findIndex(
        (conn) => conn.sos > 0 && conn.userAccount == userId // 注意user資料的id對應connection的account
      )
      return idx >= 0 ? true : false
    },
    getChasingStatus(userId) {
      // 若userId在eventList中表示正在追車，返回true，反之返回false
      // let idx = this.eventList.findIndex(
      //   (event) => event.user.id == userId && event.chasing == 1
      // )
      // return idx >= 0 ? true : false
      let idx = this.connectionList.findIndex(
        (conn) => conn.lprChasing > 0 && conn.userAccount == userId
      )
      return idx >= 0 ? true : false
    },
    getLiveStatus(userId) {
      return this.liveList.findIndex((item) => item.id == userId) >= 0
        ? true
        : false
    },
    reCheckSelectedUsers() {
      let arrCheckedKeys = []
      this.selectedUsers.forEach((user) => {
        arrCheckedKeys.push(user.id)
      })
      this.$refs.tree.setCheckedKeys(arrCheckedKeys, true)
    },
    getChildrenOnlineNumber(arrChildren) {
      let count = 0
      arrChildren.forEach((item) => {
        if (this.getOnlineStatus(item.id)) {
          count += 1
        }
      })
      return count
    },
    getChildrenUserNumber(groupId) {
      let count = 0
      this.userList.forEach((user) => {
        if (user.groupId == groupId) {
          count += 1
        }
      })
      return count
    },
    checkUserSelected(userId) {
      let idx = this.selectedUsers.findIndex((user) => user.id == userId)
      return idx >= 0 ? true : false
    },
    // onGoToTimeLapse() {
    //   const hrefs = window.location.href.split('/')
    //   hrefs.pop()

    //   const newHref = `${hrefs.join('/')}/time-lapse?id=${this.rightClickUser.index}&account=${this.rightClickUser.id}`
    //   window.open(newHref, '_blank')
    // },
    onOpenVideoEditor() {
      this.bVideoEditor = true
    },
    openKanban() {
      this.showKanban = true
    },
    closeKanban() {
      console.log(`[GroupUserTree.closeKanban]`)
      this.showKanban = false
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap-group-user-tree {
  background-color: #151b35;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.search-box {
  // width: 100%;
  height: 35px;
  position: relative;
  display: flex;
}
.search-input {
  width: 100%;
  flex: 1;
  padding: 3px 10px;
  outline: none;
  border: 1px solid #000000;
  font-size: 16px;
  color: #f3f4f4;
  background: #000000;
  box-sizing: border-box;
}
.search-button {
  text-align: center;
  width: 38px;
  outline: none;
  border: 1px solid #32385e;
  padding: 0px;
  cursor: pointer;
  background: #32385e;
  color: #ffffff;
  font-size: 16px;
}

::placeholder {
  // font: normal normal normal 16px/21px Segoe UI;
  font-size: 16px;
  line-height: 21px;
  color: #646464;
}

.clear {
  position: absolute;
  right: 42px;
  top: 6px;
}
.clear::after {
  position: absolute;
  content: '\2715';
  font-size: 14px;
  right: 2px;
  top: 2px;
  color: #a5a5a5;
  cursor: pointer;
}
.clear:hover::after {
  color: #f3f4f4;
}

.online-wrap {
  padding-left: 12px;
}

.online-switch:deep {
  margin-bottom: 4px;

  &.switch {
    align-items: unset;
    height: unset;

    & > div {
      display: flex;
      align-items: center;
    }
  }
}

.online-count {
  color: #ffffff;
  font-size: 16px;
  line-height: 24px;
}

.online-count span {
  color: #8cc24d;
}

.wrap-tree {
  padding: 6px 8px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: overlay;
  position: relative;
}

::v-deep .el-tree {
  background: #151b35;
  min-width: 276px;
  display: inline-block;
}

::v-deep .el-tree-node {
  white-space: normal;
}

::v-deep .el-tree-node__content {
  height: 100%;
  padding: 6px 0;
  align-items: center;

  &:hover {
    background-color: unset;
  }
  &:has(.el-checkbox) {
    background-color: unset;
  }

  &:has(.selected) {
    // color: #2a303e;
    // background: #151b35;
    background-color: #4a5c78;

    .el-tree-node__expand-icon,
    .el-tree-node__expand-icon:hover {
      background-color: #4a5c78;
    }
  }
}

// ::v-deep .el-tree-node .el-tree-node__children {
//   overflow: visible;
// }

// ::v-deep .el-tree-node__label {
//   // font: normal normal normal 16px/27px Segoe UI;
//   font-size: 16px;
//   line-height: 27px;
//   color: #e9ecef;
// }

/* 設定點擊才不會閃白底 */
::v-deep .el-tree .el-tree-node .el-tree-node__content {
  background: #151b35;
  &:has(.selected) {
    background-color: #4a5c78;
  }
}

// ::v-deep .el-tree-node__content .is-leaf {
//   color: transparent;
//   background: #151b35;
//   cursor: default;
// }

// ::v-deep .el-tree-node__content:hover .el-tree-node__expand-icon .is-leaf {
//   cursor: default;
// }

// ::v-deep .el-tree-node__expand-icon {
//   color: #f3f4f4;
// }

// ::v-deep .el-tree-node__content:hover {
//   color: #2a303e;
//   background: #151b35;
// }

// // ::v-deep .el-tree-node__content:hover .el-tree-node__expand-icon {
// //    color: #2a303e;
// // }

// ::v-deep .el-tree-node.is-current > .el-tree-node__content {
//   color: #2a303e;
//   background: #151b35;
// }

// ::v-deep .el-tree > .el-tree-node.is-expanded > .el-tree-node__content {
//   background: #151b35;
// }
// // ::v-deep
// //   .el-tree-node:focus
// //   > .el-tree-node__content
// //   .el-tree-node__expand-icon {
// //    //color: #2a303e;
// // }

// // ::v-deep
// //   .el-tree-node.is-current
// //   .el-tree-node__content
// //   .el-tree-node__expand-icon {
// //    //color: #2a303e;
// // }

::v-deep .el-tree-node > .el-tree-node__content {
  min-height: px2rem(32);
}

::v-deep .el-tree-node__children > .el-tree-node__content {
  min-height: px2rem(32);
}

.custom-tree-node {
  // font: normal normal normal 20px/22px Segoe UI;
  font-size: 20px;
  line-height: 22px;
  color: #e9ecef;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  width: 100%;

  &.offline .account {
    color: #9d9d9d;
    font-weight: 300;
  }

  &.online .account,
  .switch span {
    color: #8cc24d;
  }

  // &.selected .account {
  //   color: #00f;
  // }

  .icons {
    display: flex;
    // align-items: center;
    .icon {
      margin-left: 2px;
      margin-right: 5px;
      img {
        vertical-align: middle;
        width: 24px;
      }
    }
  }

  .label {
    display: flex;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;

    &.group {
      color: #ffd133;
      font-weight: normal;
    }
    &.track {
      background: #ffffff;
      padding: 3px;
      border-radius: 4px;
    }

    > img {
      width: 20px;
      height: 20px;
      margin-right: 4px;
      margin-top: 3px;
      vertical-align: baseline;
    }
    > div {
      min-width: 20px;
      line-height: 24px;
      outline: none;

      &[contenteditable='true'] {
        padding: 0 4px;
        border: 1px solid #ffffff;
        border-radius: 4px;
      }
    }
  }

  .count {
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    color: #ffffff;
    margin-left: 4px;

    span {
      color: #8cc24d;
    }
  }
}

.ckx-online {
  padding: 30px 0;
}

::v-deep .el-checkbox .el-checkbox__inner {
  background-color: unset;
  border-color: #ffffff;
  // background: #151b35;
  // border: 1px solid #ffffff;
  // display: inline-block;
  // position: relative;
  // border-radius: 2px;
  // box-sizing: border-box;
  // // width: 14px;
  // // height: 14px;
  // // // transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46),
  // // //   background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
  &:hover {
    border-color: #ffffff;
  }
}

::v-deep .el-checkbox .el-checkbox__input.is-checked .el-checkbox__inner,
::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #ffffff;
  border-color: #ffffff;
}

::v-deep .el-checkbox .el-checkbox__inner::after {
  border: 2px solid #151b35;
  border-left: 0;
  border-top: 0;
  width: 4px;
  height: 8px;
  left: 3px;
  top: 0;
}

::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
  background: #151b35;
}

.pop-up-menu {
  position: fixed;
  z-index: 4;
}

.popup-wrap {
  border: 1px solid $color_4A5C78;
  border-radius: 0.5rem;
  // cursor: pointer;
  background-color: $color_151B35;
  color: #fff;
  display: flex;
  flex-direction: column;
  // padding-bottom: 6px;
}

// .popup-wrap i {
//   align-self: flex-end;
//   margin-right: 5px;
//   margin-top: 5px;
// }

.popup-option {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
  width: px2rem(200);
  box-sizing: border-box;
  padding: px2rem(6) px2rem(12);

  &:not(:disabled):hover {
    background-color: #577590;
    cursor: pointer;
  }

  &:disabled {
    @include disabled;
  }

  &:first-child {
    padding-top: px2rem(8);
    &:hover {
      border-radius: 3px 3px 0px 0px;
    }
  }
  &:last-child {
    padding-bottom: px2rem(8);
    &:hover {
      border-radius: 0px 0px 3px 3px;
    }
  }

  img {
    width: 15px;
  }
}

// .popup-option img {
//   width: 15px;
// }

// .popup-option:hover {
//   background-color: #577590;
// }

// .popup-option:first-child:hover {
//   border-radius: 3px 3px 0px 0px;
// }

// .popup-option:last-child:hover {
//   border-radius: 0px 0px 3px 3px;
// }
</style>
