<template>
  <div class="param-status-wrap">
    <PropertyItem
      :labelIcon="null"
      :label="'更新時間'"
      :value="updatedTime"
      :valueIcon="null"
      :error="null"
      :helper="null /*require(`@/assets/icons/refresh-white.svg`)*/"
      @helper="() => null"
    />

    <PropertyItem :title="'連線狀態'" />
    <!-- TODO (i) -->
    <PropertyItem
      :labelIcon="null"
      :label="'MQ'"
      :value="null"
      :valueIcon="null"
      :error="null"
      :helper="null /*require(`@/assets/icons/refresh-white.svg`)*/"
      @helper="() => null"
    />
    <PropertyItem
      :labelIcon="null"
      :label="'VPN'"
      :value="null"
      :valueIcon="null"
      :error="null"
      :helper="require(`@/assets/icons/refresh-white.svg`)"
      :helperActive="false"
      @helper="() => null"
    />

    <PropertyItem :title="'頻道狀態'" />
    <div class="sub-title">{{ 'CH1' }}</div>
    <PropertyItem
      :labelIcon="null"
      :label="'發布'"
      :value="null"
      :valueIcon="null"
      :error="null"
      :helper="null /*require(`@/assets/icons/refresh-white.svg`)*/"
      @helper="() => null"
    />
    <PropertyItem
      :labelIcon="null"
      :label="'鏡頭'"
      :value="null"
      :valueIcon="null"
      :error="null"
      :helper="null /*require(`@/assets/icons/refresh-white.svg`)*/"
      @helper="() => null"
    />
    <PropertyItem
      :labelIcon="null"
      :label="'錄影'"
      :value="null"
      :valueIcon="null"
      :error="null"
      :helper="null /*require(`@/assets/icons/refresh-white.svg`)*/"
      @helper="() => null"
    />
    <PropertyItem
      :labelIcon="null"
      :label="'SD卡'"
      :value="null"
      :valueIcon="null"
      :error="null"
      :helper="null /*require(`@/assets/icons/refresh-white.svg`)*/"
      @helper="() => null"
    />
    <div class="sub-title">{{ 'CH2' }}</div>
    <PropertyItem
      :labelIcon="null"
      :label="'發布'"
      :value="null"
      :valueIcon="null"
      :error="null"
      :helper="null /*require(`@/assets/icons/refresh-white.svg`)*/"
      @helper="() => null"
    />
    <PropertyItem
      :labelIcon="null"
      :label="'鏡頭'"
      :value="null"
      :valueIcon="null"
      :error="null"
      :helper="null /*require(`@/assets/icons/refresh-white.svg`)*/"
      @helper="() => null"
    />
    <PropertyItem
      :labelIcon="null"
      :label="'錄影'"
      :value="null"
      :valueIcon="null"
      :error="null"
      :helper="null /*require(`@/assets/icons/refresh-white.svg`)*/"
      @helper="() => null"
    />
    <PropertyItem
      :labelIcon="null"
      :label="'SD卡'"
      :value="null"
      :valueIcon="null"
      :error="null"
      :helper="null /*require(`@/assets/icons/refresh-white.svg`)*/"
      @helper="() => null"
    />

    <PropertyItem :title="'流量'" />
    <PropertyItem :label="'上傳'" :value="null" />
    <PropertyItem :label="'下載'" :value="null" />

    <PropertyItem :title="'名稱'" />
    <PropertyItem :label="'設備號碼'" :value="null" />
    <PropertyItem :label="'熱點名稱'" :value="null" />
    <PropertyItem :label="'設備名稱'" :value="null" />

    <PropertyItem :title="'感測器'" />
    <PropertyItem :label="'電壓'" :value="null" />
    <PropertyItem :label="'溫度'" :value="null" />

    <PropertyItem :title="'其他'" />
    <PropertyItem :label="'運行時間'" :value="null" />
    <PropertyItem :label="'設備時間'" :value="null" />
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} from 'vuex'
import PropertyItem from '@/components/AccountManagement/tools/PropertyItem.vue'

export default {
  name: 'DeviceParamStatus',
  components: {
    PropertyItem,
  },
  props: {
    account: {
      type: Object,
      default: null
    },
    deviceModelId: {
      type: Number,
      default: 0
    },
    // config: {
    //   type: Object,
    //   default: null
    // },
    status: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      // ...mapState([]),
    }
  },
  computed: {
    ...mapGetters([]),
    ...mapState([]), // state 放這裡可以維持唯一性, 不能用 this.comp = xxx 做修改
    updatedTime() {
      return this.account.updatedTime
    }
  },
  watch: {},
  methods: {
    ...mapActions([]), // API query and update
    ...mapMutations([]),
  },
  created() {},
  mounted() {
    console.log(`[DeviceParamStatus] status:`, this.status)
  },
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
  // user-select: none;
}
.param-status-wrap {
  margin-right: px2rem(2);
  padding-left: px2rem(32);
  padding-right: px2rem(6); // mr-2 + pr-6
  padding-bottom: 0.5rem;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  // background-color: #f00;

  .sub-title {
    font-size: 1rem;
    line-height: 1.5;
    color: $color_FFF_50;
  }
}
</style>