<template>
  <div class="time-lapse-task-wrap">
    <!-- TIME LAPSE SETTING: {{ userId }} -->
    <div class="content">
      <div class="time-lapse-setting">
        <div class="time-lapse-radio-wrap">
          <div class="time-lapse-device-wrap">
            <img src="@/assets/icons/group-icon.svg" alt="">
            <span class="group">{{ userGroupName }}</span>
          </div>
          <div class="time-lapse-device-wrap">
            <el-tooltip
              popper-class="el-tooltip"
              effect="dark"
              v-delTabIndex
              :disabled="!accImgInfo.name ? true : false"
              :content="accImgInfo.name"
            >
              <img :src="accImgInfo.img" alt="">
            </el-tooltip>
            <span>{{ `${userVideoTitle || userAccountName}(${userAccount})` }}</span>
          </div>

          <div class="time-lapse-task-name-wrap">
            <div class="label">
              <span>{{ '任務名稱' }}</span>
              <span class="required">*</span>
            </div>
            <input type="text" v-model="filename" maxlength="50">
            <span v-if="errMsg" class="error">{{ errMsg }}</span>
          </div>

          <label class="enable-time-lapse-wrap">
            <input type="checkbox" id="enable-time-lapse" v-model="timelapse" />
            <img
              v-if="timelapse"
              src="@/assets/icons/checkbox-yellow-checked.svg"
              alt=""
            />
            <img v-else src="@/assets/icons/uncheck.svg" alt="" />
            <span>{{ '縮時匯出' }}</span>
          </label>
          <div class="time-lapse-radio-value">
            <div class="input-item">
              <label for="">{{ '縮時倍率' }}</label>
              <input
                type="text"
                readonly
                :placeholder="'倍率'"
                :disabled="!timelapse"
                :value="ratioValue"
              />
            </div>
            <div class="input-item">
              <label for="">{{ '預估影片長度(MM:SS)' }}</label>
              <input
                type="text"
                readonly
                :placeholder="'時間'"
                :disabled="!timelapse"
                :value="ratioTime"
              />
            </div>
          </div>
          <div class="slider-item">
            <div class="title">{{ '調整縮時倍率' }}</div>
            <div class="slider-wrap">
              <label for="">{{ `低（${minRatio}）` }}</label>
              <input
                type="range"
                v-model="ratioIdx"
                :disabled="!timelapse"
                :min="0"
                :max="ratioList.length - 1"
                step="1"
              />
              <label for="">{{ `高（${maxRatio}）` }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="time-lapse-schedule-wrap">
        <div class="schedule-control">
          <el-tooltip
            popper-class="el-tooltip"
            effect="dark"
            v-delTabIndex
            :disabled="!isOverRange"
            :content="`總時間已超出最大值：${timelapse ? '1 天' : '2 小時'}`"
          >
            <button
              class="add-range-btn"
              :disabled="isOverRange"
              @click="onAddRange"
            >
              {{ '新增時段' }}
            </button>
          </el-tooltip>
        </div>
        <div class="time-lapse-total-time">
          <div class="text-item">
            <label for="">{{ '總時段(HH:MM:SS)' }}</label>
            <span>{{ totalTime }}</span>
          </div>
        </div>
        <div class="time-range-wrap" ref="rangePool">
          <div
            class="time-range-item"
            v-for="(range, idx) in ranges"
            :key="`${range.id}_${idx}`"
          >
            <!-- <span class="time-range-no">{{ `#${idx + 1}` }}</span> -->
            <div class="time-range-head">
              <span>{{ `#${idx + 1}` }}</span>
              <button
                v-if="!range.cannot_remove"
                :disabled="false"
                @click="onRmRange(idx)"
              >
                <img src="@/assets/icons/TrashCan.svg" alt="" />
              </button>
            </div>
            <div class="st-et-wrap">
              <div class="date-item">
                <div class="title">{{ '起始時間' }}</div>
                <div class="date-time-wrap">
                  <input type="date" v-model="range.start.date" pattern="\d{4}-\d{2}-\d{2}"/>
                  <input type="time" v-model="range.start.time" />
                </div>
              </div>
              <div class="date-item">
                <div class="title">{{ '結束時間' }}</div>
                <div class="date-time-wrap">
                  <input type="date" v-model="range.end.date" :max="today.date" pattern="\d{4}-\d{2}-\d{2}"/>
                  <input type="time" v-model="range.end.time" :max="today.time" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <el-tooltip
        popper-class="el-tooltip"
        effect="dark"
        v-delTabIndex
        :disabled="submitToolTip ? false : true"
        :content="submitToolTip"
      >
        <button class="btn" :disabled="isOverRange || isLowerRange || errMsg" @click="onSubmit">
          {{ '送出' }}
        </button>
      </el-tooltip>
    </div>
    <!-- <ExportFileNameModal
      v-if="bFilenameModal"
      :title="'自訂任務'"
      :filename="filename"
      :placeholder="'請輸入檔名'"
      @close="onCloseFilenameModal"
      @confirm="onConfirmFilenameModal"
    /> -->
  </div>
</template>

<script>
import moment from 'moment'
import { mapState /*, mapActions, mapMutations*/ } from 'vuex'

import {
  apiCheckStatus,
  apiErrorMsg,
  apiPostExportVideoTask
} from '@/api'
import { YYYYMMDD, HHmmss, ms2hhmmss } from '@/utils/lib.js'
import { getDeviceModelIcon } from '@/config/account.js'

const oneDayMs = 24 * 60 * 60 * 1000
const twoHrMs = 2 * 60 * 60 * 1000
const ratioList = [30, 60, 120, 240, 480, 900, 1500, 3000]

export default {
  name: 'TimeLapseTask',
  components: {},
  props: {
    account: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      userId: null, // user.index
      userAccount: null, // user.id
      userAccountName: null, // user.info.name
      userVideoTitle: null, // user.info.name
      userGroupName: null, // group.name

      timelapse: false,
      ratioList,
      minRatio: ratioList[0],
      maxRatio: ratioList[ratioList.length - 1],
      ratioIdx: 0,
      today: {
        data: moment().format(YYYYMMDD),
        time: moment().format(HHmmss),
      },
      ranges: [
        {
          id: Date.now(),
          // startTime: new Date(),
          start: {
            date: moment().format(YYYYMMDD),
            time: moment().format(HHmmss),
          },
          // endTime: new Date(),
          end: {
            date: moment().format(YYYYMMDD),
            time: moment().format(HHmmss),
          },
          cannot_remove: true
        }
      ],

      title: '設定任務',
      filename: '',
    }
  },
  computed: {
    ...mapState(['rightClickUser']),
    ...mapState('global', ['deviceModels', 'groupList']),
    accImgInfo() {
      if (!this.account || this.account.deviceModelId === undefined) {
        return {img: null, name: null}
      }

      return {
        img: require(`@/assets/icons/device_model_${getDeviceModelIcon(this.account.deviceModelId)}.svg`),
        name: this.account?.deviceModelId !== 0 ? this.deviceModels.find((model) => model.id === this.account.deviceModelId).name : null
      }
      
    },
    startTime() {
      const sT = Math.min(
        ...this.ranges.map((range) =>
          new Date(`${range.start.date} ${range.start.time}`).getTime()
        )
      )
      // console.log(`[startTime] `, moment(sT).format('YYYY-MM-DD HH:mm:ss'))
      return sT
    },
    endTime() {
      const eT = Math.max(
        ...this.ranges.map((range) =>
          new Date(`${range.end.date} ${range.end.time}`).getTime()
        )
      )
      // console.log(`[endTime] `, moment(eT).format('YYYY-MM-DD HH:mm:ss'))
      return eT
    },
    totalMs() {
      return this.endTime - this.startTime
    },
    totalTime() {
      return ms2hhmmss(this.totalMs)
    },
    ratioValue() {
      return ratioList[this.ratioIdx]
    },
    ratioTime() {
      // * 預估 = 分鐘 / ratioValue
      if (!this.timelapse) return '00:00'

      const [hh, mm, ss] = this.totalTime.split(':').map((num) => Number(num))

      // 換算成分鐘
      const totalMin = hh * 60 + mm + ss / 60

      // 除以倍率
      const estimateMin = totalMin / this.ratioValue

      // 取出秒數
      const estimatesec = Math.floor((estimateMin * 60) % 60) // 取整數
      // console.log(`[ratioTime] estimatesec:`, estimatesec)

      // 轉成 MM:SS
      const MM = `00${Math.floor(estimateMin)}`.slice(-2)
      const SS = `00${estimatesec}`.slice(-2)

      return `${MM}:${SS}`
    },
    isOverRange() {
      if (this.timelapse) {
        return this.totalMs > oneDayMs
      } else {
        return this.totalMs > twoHrMs
      }
    },
    isLowerRange() {
      return this.totalMs <= 0
    },
    errMsg() {
      if (!this.filename) {
        return '必填'
      }
      return null
    },
    submitToolTip() {
      if (this.errMsg) {
        return '缺少必要資訊'
      } else if (this.isOverRange) {
        return `總時間已超出最大值：${this.timelapse ? '1 天' : '2 小時'}`
      } else if (this.isLowerRange) {
        return '請設定時間'
      }
      return null
    }
  },
  watch: {},
  methods: {
    ms2hhmmss,
    getUserInfo() {
      // console.log(`[getUserInfo] account:`, this.account)
      this.userId = `${this.account.index}`
      this.userAccount = this.account.id
      this.userAccountName = this.account.info.name
      this.userVideoTitle = this.account.video.title
      this.userGroupName = this.groupList.find((_g) => _g.id === this.account.groupId).name

      this.filename = `縮時攝影_${this.userAccount}_${moment().format(`YYYYMMDDHHmmss`)}`
    },
    // ---
    // onInputRatio(e) {
    //   const { value } = e.target
    //   console.log(`[onInputRatio] value, ratioIdx:`, value, this.ratioIdx)
    // },
    onAddRange() {
      console.log(`[onAddRange]`)
      this.ranges.push({
        id: Date.now(),
        // startTime: new Date(),
        start: {
          date: moment().format(YYYYMMDD),
          time: moment().format(HHmmss),
        },
        // endTime: new Date(),
        end: {
          date: moment().format(YYYYMMDD),
          time: moment().format(HHmmss),
        },
        // cannot_remove: true
      })

      // this.$refs.rangePool
    },
    onRmRange(idx) {
      console.log(`[onRmRange] idx:`, idx)
      this.ranges.splice(idx, 1)
    },
    async onApiSubmit() {
      // let dev = true
      // if (dev) {
      //   console.log(`[onApiSubmit] filename:`, this.filename)
      //   this.onCloseFilenameModal()
      //   return
      // }

      try {
        let param = {
          userId: this.userId,
          startTime: new Date(this.startTime).toISOString(),
          endTime: new Date(this.endTime).toISOString(),
          range: this.ranges.map((range) => {
            return {
              startTime: new Date(
                `${range.start.date} ${range.start.time}`
              ).toISOString(),
              endTime: new Date(
                `${range.end.date} ${range.end.time}`
              ).toISOString()
            }
          }),
          timelapse: this.timelapse ? 1 : 0,
          description: this.filename
        }
        if (this.timelapse) {
          param = { ...param, ...{ ratio: this.ratioValue } }
        }
        // console.log(`[onSubmit] param:`, JSON.stringify(param, '', 2))

        // TODO: call api
        const res = await apiPostExportVideoTask(param)
        if (!apiCheckStatus(res)) throw res

        this.$notify({
          type: 'success',
          title: this.title,
          message: this.$t('success') + `, 請至${this.$t('time_lapse_export')}查看影像列表`,
        })
      } catch (err) {
        console.error(`[TimeLapseTask] onApiSubmit fail.`, err)
        this.$notify({
          type: 'error',
          title: this.title,
          message:
            this.$t('fail') + err
              ? this.$t(apiErrorMsg(err))
              : this.$t('api_error')
        })
      } finally {
        // TODO: TBD 需要轉跳至下載？
        // this.onCloseFilenameModal()
      }
    },
    onSubmit() {
      if (!this.filename) {
        return
      }

      this.onApiSubmit()
    },
  },
  created() {},
  mounted() {
    // console.log(`[TimeLapseTask.mounted] rightClickUser:`, this.rightClickUser)
    this.getUserInfo()
  },
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
$BlockW: 49.5%;
* {
  box-sizing: border-box;
  margin: 0;
  border: 0;
  padding: 0;
  user-select: none;
  color: $color_FFF;
}
.time-lapse-task-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  .content {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    padding: 0.5rem 0 0.25rem 1rem;
    width: 100%;
    height: 64.58vh; // 抓大概
    // background-color: #0ff;

    .time-lapse-setting {
      width: $BlockW;
      // background-color: #0f0;
      .time-lapse-radio-wrap {
        $GapH: 1.5rem;
        .time-lapse-device-wrap {
          display: flex;
          align-items: center;
          img {
            width: 1.5rem;
            height: 1.5rem;
          }
          span {
            margin-left: 0.5rem;
            font-size: 1.5rem;

            &.group {
              color: $color_group;
            }
            // color: $color_FFE99F;
            // @include font_bold;
          }
        }

        .time-lapse-task-name-wrap {
          display: flex;
          flex-direction: column;
          margin-top: $GapH;
          // background-color: #f00;

          .label {
            .required {
              @include required;
            }
          }

          input[type='text'] {
            border: 1px solid $color_9D9D9D;
            border-radius: 0.5rem;
            padding: 0 px2rem(12);
            width: px2rem(416);
            height: px2rem(36);
            color: $color_black;
          }

          .error {
            font-size: 0.875rem;
            color: $color_warn;
          }
        }
        .time-lapse-total-time {
          // display: flex;
          border-radius: 0.5rem;
          padding: 0.5rem;
          background-color: $color_39425D;
          span {
            margin-left: 0.5rem;
          }
        }
        .enable-time-lapse-wrap {
          display: flex;
          align-items: center;
          margin-top: $GapH;
          input[type='checkbox'] {
            display: none;
          }
          img {
            width: 1rem;
            height: 1rem;
          }
          span {
            margin-left: 0.5rem;
          }
        }
        .time-lapse-radio-value {
          display: flex;
          // justify-content: space-between;
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
          // background-color: #f00;
        }
      }
    }
    .time-lapse-schedule-wrap {
      display: flex;
      flex-direction: column;
      width: $BlockW;
      // height: 100%;
      // background-color: #00f;

      .schedule-control {
        display: flex;
        justify-content: flex-end;
        padding-right: 1rem;
        .add-range-btn {
          // justify-self: center;
          border: 1px solid $color_E6E6E6;
          border-radius: 0.5rem;
          padding: 0 1rem;
          height: px2rem(36);

          &:hover:not(:disabled) {
            background-color: $color_E6E6E6_10;
          }
          &:disabled {
            @include disabled;
          }
        }
      }

      .time-range-wrap {
        display: flex;
        flex-direction: column;
        margin-top: 0.5rem;
        overflow-y: scroll;
        // background-color: #f00;
        .time-range-item {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          border: 1px solid $color_39425D;
          border-radius: 0.5rem;
          padding: 0.5rem;
          background-color: $color_39425D;

          &:not(:first-child) {
            margin-top: 0.5rem;
          }

          .time-range-head {
            display: flex;
            justify-content: space-between;
            span {
              font-size: 1rem;
              line-height: 1.5;
              @include font_bold;
              color: $color_FFE99F;
            }
            button {
              border: 1px solid transparent;
              border-radius: 0.25rem;
              padding: 0.25rem;
              &:hover {
                border-color: $color_FFF;
              }
              img {
                width: 1rem;
                height: 1rem;
              }
            }
          }
          .st-et-wrap {
            display: flex;
            // justify-content: space-between;
          }
        }
      }
    }
  }
  // .horzontal-divider {
  //   @include horzontal_divider;
  // }
  .footer {
    display: flex;
    justify-content: flex-end;

    margin-bottom: px2rem(12);
    border-top: 1px solid $color_divider;
    padding-top: 1rem;
    // min-height: px2rem(53);
    .btn {
      margin-right: px2rem(12);
      border: 1px solid $color_FFC600;
      border-radius: 0.5rem;
      padding: 0 1rem;
      height: px2rem(36);
      color: $color_282942;
      background-color: $color_FFC600;

      &:hover:not(:disabled) {
        background-color: $color_FFD133;
      }
      &:disabled {
        @include disabled;
      }
    }
  }
}

.text-item {
  label {
    margin-bottom: px2rem(4);
    font-size: 0.875rem; // 14px
    line-height: 1.5;
  }
  span {
    margin-left: 0.5rem;
    @include font_bold;
  }
}

.input-item {
  width: px2rem(200);

  &:not(:first-child) {
    margin-left: 1rem;
  }

  label {
    margin-bottom: px2rem(4);
    font-size: 0.875rem; // 14px
    line-height: 1.5;
  }
  input[type='text'] {
    border: 1px solid $color_9D9D9D;
    border-radius: 0.5rem;
    outline: unset;
    padding: 0 px2rem(12);
    height: px2rem(36);
    width: 100%;
    font-size: 1rem;
    line-height: 1.5;
    vertical-align: center;
    color: $color_black;

    &[disabled] {
      @include disabled;
    }
  }
}
.slider-item {
  display: flex;
  flex-direction: column;
  // width: px2rem(400);
  .title {
    margin-bottom: px2rem(4);
    font-size: 0.875rem; // 14px
    line-height: 1.5;
  }

  .date-time-wrap {
    display: flex;
  }

  .slider-wrap {
    display: flex;
    // width: 100%;

    label {
      font-size: 1rem;
      line-height: 1.5;
      &:last-child {
        margin-left: 0.25rem;
      }
    }
    input[type='range'] {
      width: px2rem(200);
      &[disabled] {
        @include disabled;
      }
    }
  }
}

.date-item {
  display: flex;
  flex-direction: column;
  width: px2rem(200);

  &:not(:first-child) {
    margin-left: 1rem;
  }
  .title {
    margin-bottom: px2rem(2);
    font-size: 0.875rem; // 14px
    line-height: 1.5;
  }

  input[type='date'],
  input[type='time'] {
    margin-top: px2rem(2);
    border: 1px solid $color_9D9D9D;
    border-radius: 0.5rem;
    outline: unset;
    padding: 0 px2rem(12);
    width: 100%;
    height: px2rem(36);
    color: $color_black;

    // &[disabled] {
    //   @include disabled;
    // }
  }
}
</style>
