<template>
  <portal to="calling-modal">
    <div class="calling-mask" @click="onClickOutside"></div>
    <transition name="fade">
    <!-- <div class="wrap-calling" :style="cssVars" v-click-outside="onClickOutside"> -->
      <div class="wrap-calling" :style="cssVars">
      <div class="calling-title">
        <div class="calling-title-name">{{ $t('tree_contacts' /*通訊錄*/) }}</div>
        <div class="user-calling-tab" @click="change_tab(1)">
          <img src="@/assets/icons/user-calling-off.svg" alt="">
        </div>
        <div class="group-calling-tab" @click="change_tab(2)">
          <img src="@/assets/icons/group-calling-off.svg" alt="">
        </div>
      </div>
      <div class="calling-search-bar">
        <div
          id="search-input"
          class="calling-search-input"
          contenteditable="true"
          :placeholder="$t('tree_search')"
          @input="onInput"
        />
        <div class="calling-search-btn">
          <img src="@/assets/icons/calling-search-btn.svg" alt="">
        </div>
      </div>
      <div v-if="tabActive" class="show-online-bar">
        <div class="switch">
          <label for="switch-online-calling" class="label">
            {{ $t('top_bar_online_devices' /*上線設備*/) }}
          </label>
          <input
            id="switch-online-calling"
            class="input"
            type="checkbox"
            v-model="showOnline"
            @change="reloadGroupUserTree"
          />
          <label for="switch-online-calling" class="slider"></label>
        </div>
      </div>
      <div class="device-list container" ref="wrapTree" v-if="true">
        <!-- default-expand-all -->
        <el-tree
          ref="tree"
          :data="treeData"
          :props="defaultProps"
          node-key="id"
          :default-expanded-keys="expandKeys"
          @node-click="onNodeClick"
          @node-contextmenu="onNodeRightClick"
        >
          <div
            class="custom-tree-node"
            slot-scope="{ node, data }"
            :id="data.id"
            :class="{
              online: getOnlineStatus(data.id),
              offline: !getOnlineStatus(data.id),
              selected: openContextMenu
            }"
          >
            <div v-if="!node.isLeaf" class="group-node">{{ node.label }}</div>
            <div v-else>{{ node.label }}</div>
            <div class="phone" width="30px" height="20px">
              <img
                v-if="!data.children"
                class="g-phone"
                src="@/assets/icons/green-phone.svg"
                alt=""
                width="20px"
                height="20px"
              />
            </div>
          </div>
        </el-tree>
        <div
          ref="contextMenu"
          class="node-contextmenu"
          :class="{show: openContextMenu}"
          v-click-outside="onCloseContentMenu"
        >
          <div class="close-contextmenu">
            <div @click="onCloseContentMenu">
              <i class="el-icon-close"></i>
              <!-- <i class="fas fa-times"></i> -->
            </div>
          </div>
          <div class="open-account-info" @click="onOpenAccInfo">
            <img src="@/assets/icons/view-device.svg" alt="" />
            <div>{{ $t(infoEntryTitle) }}</div>
          </div>
        </div>

      </div>
    </div>
    </transition>
  </portal>
</template>

<script scoped>
import { mapMutations, mapState } from 'vuex'
import vClickOutside from 'v-click-outside'
import {
  apiGetUserList,
  apiGetGroupTree,
  getUserChannedId,
  callChannelApi,
} from '@/api/index.js'
import { euAccountKind } from '@/utils/global.js'

export default {
  name: 'Calling',
  components: {},
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: ['callingTop'],
  data() {
    return {
      showOnline: true,
      w_top: 0,
      w_height: 730,
      d_height: 606,
      tab1_bcolor: '#FFC600',
      tab2_bcolor: '#9D9D9D',
      searchInput: '',
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      expandKeys: [],
      nodeData: [],
      tabActive: true,
      g_phone: '',
      popUpMenuMode: null,
      userCallAccount: null,
      userCallId: null,

      infoData: null,
      openContextMenu: false,
    }
  },
  computed: {
    ...mapState([
      'liveList',
      'connectionList',
      'selectedUsers',
      'userList',
      'groupTree',
      'permissionV2',
      'showAccountInfoModal'
    ]),
    cssVars() {
      return {
        "--w-top": this.w_top + 'px',
        "--w-height": this.w_height + 'px',
        "--d-height": this.d_height + 'px',
        "--tab1-bcolor": this.tab1_bcolor,
        "--tab2-bcolor": this.tab2_bcolor,
      }
    },
    publicParam() {
      let param = 0
      // 語音對講權限設為 L2時，公開跟私有都要顯示，所以參數要設為null
      if (this.permissionV2.voiceCall > 1) param = null
      return param
    },
    infoEntryTitle() {
      if (this.infoData) {
        const { kind } = this.infoData

        if (kind === euAccountKind.default) {
          return 'tree_view_account'
        } else if (kind === euAccountKind.device) {
          return 'tree_view_device'
        } else {
          return 'tree_view_user'
        }
      }

      return 'tree_view_account'
    }
  },
  methods: {
    ...mapMutations(['updateShowAccountInfoModal', 'updateRightClickUser']),
    checkResize() {
      let element = document.getElementById('callingBtnID')
      this.w_height = 730
      this.d_height = (this.tabActive ? 606 : 641)
      if (element.offsetTop > this.w_height) this.w_top = element.offsetTop - this.w_height + (element.offsetHeight * 2)
      else {
        this.w_top = 50
        this.w_height = element.offsetTop + element.offsetHeight
        this.d_height = this.w_height - (this.tabActive ? 124 : 89)
      }
    },
    async getChannelId(id) {
      try {
        let res = await getUserChannedId(id)
        this.userCallId = res.data.index
      } catch (e) {
        console.log('channesid 失敗QQ')
      }
    },
    async onNodeClick(data /*, node*/) {
      // [user, leeyichung2 (leeyichung2), 746]
      // [group, manager, 1]
      // console.log(node)
      // console.log(data)

      if (this.tabActive) {
        await this.getChannelId(data.id)
        this.popUpMenuMode = 'user'
      } else {
        this.popUpMenuMode = 'group'
        this.userCallId = data.groupId
      }
      this.userCallAccount = data.label
      if (this.userCallId > 0) {
        this.$emit('closeCalling')
        this.$emit(
          'assignCallInfo',
          this.popUpMenuMode,
          this.userCallAccount,
          this.userCallId
        )
      }
    },
    onOpenContentMenu() {
      if (!this.openContextMenu) {
        this.openContextMenu = true
      }
    },
    onCloseContentMenu(needClean = true) {
      if (this.openContextMenu) {
        this.openContextMenu = false
      }

      if (needClean) {
        this.$nextTick(() => {
          if (this.$refs.tree) {
            this.$refs.tree.setCurrentKey(null)
          }
        })
      }
    },
    onOpenAccInfo() {
      this.updateShowAccountInfoModal(true) // 顯示帳號資訊跳窗
      this.updateRightClickUser(this.infoData) // 顯示帳號資訊

      this.onCloseContentMenu(false)
    },
    onNodeRightClick(e, data, node) {
      this.onCloseContentMenu()
      const currUser = this.userList?.find((user) => user.id === data.id)

      // Group
      if (!currUser) {
        return
      }

      this.infoData = { ...data, ...currUser }
      this.onOpenContentMenu()

      let left = e.pageX
      let top = e.pageY + 5
      this.$nextTick(() => {
        if (this.$refs.tree) {
          this.$refs.tree.setCurrentKey(node)
        }
        if (this.$refs.contextMenu) {
          this.$refs.contextMenu.style.left = left + `px`
          this.$refs.contextMenu.style.top = top + `px`
        }
      })
    },
    getNodeData(data) {
      data.forEach((item, index) => {
        if (item.children) {
          // console.log(item.groupId)
          // console.log(item.id)
          // console.log(item.label)
          this.getNodeData(item.children)
          let tmp = {
            id: index, // tree的資料須有一樣的結構，故將name設給id
            groupId: item.groupId,
            label: item.label, // 群組只顯示群組名稱
            children: false
          }
          this.nodeData.push(tmp)
        }
      })
    },
    change_tab(val) {
      if (val==1) {
        if (!this.tabActive) {
          this.tabActive = true
          this.d_height -= 35
          this.tab1_bcolor = "#FFC600"
          this.tab2_bcolor = "#9D9D9D"
          this.getGroupUserTree()
        }
      } else {
        if (this.tabActive) {
          this.tabActive = false
          this.d_height += 35
          this.tab1_bcolor = "#9D9D9D"
          this.tab2_bcolor = "#FFC600"
          this.searchInput = ''
          document.getElementById('search-input').innerText = ''
          this.getChannels()
        }
      }
    },
    async getChannels() {
      try {
        this.treeData = []

        let res = await callChannelApi()
        res.data.forEach((item) => {
          let idx = this.nodeData.findIndex((user) => user.label == item.name)
          if (idx != -1) {
            let ck = true
            if (this.searchInput) {
              let fItem = this.searchInput.toLowerCase() // 轉換為小寫
              ck = item.name.toLowerCase().includes(fItem)
            }
            if (ck) {
              let tmp = {
                id: item.id, // tree的資料須有一樣的結構，故將name設給id
                groupId: this.nodeData[idx].groupId,
                label: item.name, // 群組只顯示群組名稱
                children: false
              }
              this.treeData.push(tmp)
            }
          }
        })
      } catch (e) {
        console.error(`Calling.getChannels e`, e)
      }
    },
    onInput(e) {
      this.searchInput = e.target.innerText
    },
    reloadGroupUserTree() {
      this.getGroupUserTree()
      // 重新把先前選擇的帳號selectedUsers勾選起來
      // this.reCheckSelectedUsers()
    },
    async getGroupTree() {
      const res = await apiGetGroupTree()
      this.$store.commit('setGroupTree', res.data)
    },
    async getUserList() {
      const res = await apiGetUserList('device', this.publicParam)
      if (res && res.data) this.$store.commit('setUserList', res.data)
    },
    async getGroupUserTree() {
      await this.getGroupTree()
      await this.getUserList()

      this.expandKeys = []
      this.treeData = []

      let arrUsers = this.getUserListByGroupId(
        this.userList,
        this.groupTree.group.id,
        this.groupTree.group.name
      )

      let arrChildren = this.getChildrenData(
        this.groupTree.children,
        this.userList,
        this.groupTree.group.id
      )

      let tmp = {
        id: this.groupTree.group.id,
        groupId: this.groupTree.group.id, // tree的資料須有一樣的結構，故將id設給groupId
        label: this.groupTree.group.name, // 群組只顯示群組名稱
        children: arrUsers.concat(arrChildren),
        parent: 0
      }
      this.treeData.push(tmp)
      
      if (this.nodeData.length == 0) {
        this.getNodeData(this.treeData)
      }
    },
    getUserListByGroupId(userList, NodeGroupId, NodeGroupName) {
      let fItem = this.searchInput.toLowerCase() // 轉換為小寫
      // 先檢查NodeGroupName是否含有fItem，若有則返回此節點的所有user;若無則進行search的比對
      let bGroupMatch
      if (NodeGroupName)
        bGroupMatch = NodeGroupName.toLowerCase().includes(fItem)
      let users = userList.filter((user) => {
        if (this.showOnline) {
          if (this.searchInput && !bGroupMatch) {
            return (
              user.groupId == NodeGroupId &&
              this.getOnlineStatus(user.id) &&
              (user.video.title.toLowerCase().includes(fItem) ||
                user.id.toLowerCase().includes(fItem))
            )
          } else {
            return (
              user.groupId == NodeGroupId &&
              (this.getOnlineStatus(user.id) || this.checkUserSelected(user.id))
            )
          }
        } else {
          if (this.searchInput && !bGroupMatch) {
            return (
              user.groupId == NodeGroupId &&
              (user.video.title.toLowerCase().includes(fItem) ||
                user.id.toLowerCase().includes(fItem))
            )
          } else {
            return user.groupId == NodeGroupId
          }
        }
      })

      let arrUsers = []
      users.forEach((user) => {
        arrUsers.push({
          id: user.id,
          name: user.video.title,
          groupId: user.groupId,
          label: user.video.title + ' (' + user.id + ')',
          index: user.index
        })
      })
      return arrUsers
    },
    getChildrenData(data, userList, groupId) {
      let arrChildren = []
      if (data) {
        data.forEach((item) => {
          let arrUsers = this.getUserListByGroupId(
            userList,
            item.group.id,
            item.group.name
          )

          let arrNode = item.children
            ? this.getChildrenData(item.children, userList, item.group.id)
            : []

          let fItem = this.searchInput.toLowerCase() // 轉換為小寫
          let bInclude =
            fItem != '' &&
            (item.group.id.toLowerCase().includes(fItem) ||
              item.group.name.toLowerCase().includes(fItem))

          if (arrUsers.length > 0 || arrNode.length > 0 || bInclude) {
            this.expandKeys.push(item.group.id)
            arrChildren.push({
              id: item.group.id,
              groupId: item.group.id,
              label: item.group.name, // 群組只顯示群組名稱
              children: arrUsers.concat(arrNode),
              parent: groupId
            })
          }
        })
      }
      return arrChildren
    },
    getOnlineStatus(userId) {
      // 若userId在connectionList中表示有連結，返回true，反之返回false
      // 2022.05.14 若裝置有在connectionList或是在liveList中，表示online
      let idxConn = this.connectionList.findIndex(
        (item) => item.userAccount == userId
      )
      let idxLive = this.liveList.findIndex((item) => item.id == userId)
      return idxConn >= 0 || idxLive >= 0 ? true : false
    },
    checkUserSelected(userId) {
      let idx = this.selectedUsers.findIndex((user) => user.id == userId)
      return idx >= 0 ? true : false
    },
    onClickOutside() {
      this.$emit('closeCalling')
    }
  },
  watch: {
    searchInput() {
      if (this.tabActive) this.getGroupUserTree()
      else this.getChannels()
    },
    callingTop() {
      this.checkResize()
    },
    showAccountInfoModal(nVal) {
      if (!nVal) {
        this.infoData = null
      }
    },
    openContextMenu(nVal) {
      if (nVal) {
        this.$refs.wrapTree.addEventListener('scroll', this.onCloseContentMenu)
      } else {
        this.$refs.wrapTree.removeEventListener('scroll', this.onCloseContentMenu)
      }
    }
  },
  created() {
    this.getChannels()
    this.getGroupUserTree()
  },
  mounted() {
    this.checkResize()
  },
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.calling-mask {
  position: absolute;
  top: 0;
  left: px2rem(50);
  width: calc(100% - px2rem(50));
  height: 100%;
  z-index: 3;

  background-color: rgba(0, 0, 0, 0.6);
}
.wrap-calling {
  position: fixed;
  top: var(--w-top);
  left: px2rem(50);
  width: px2rem(276);
  height: var(--w-height);
  background: #4A5C78 0% 0% no-repeat padding-box;
  border-radius: px2rem(6) px2rem(6) px2rem(6) 0px;
  opacity: 1;
  z-index: 10;
}

.calling-title {
  height: px2rem(46);
  display: flex;
  flex-direction: row;
  // border:.1px gray dashed;

  .calling-title-name {
    width: px2rem(122);
    height: px2rem(31);
    text-align: left;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin: px2rem(12) 0 0 px2rem(8);
    display: flex;
    align-items: center;
    // border:.1px gray dashed;
  }
  .user-calling-tab {
    width: px2rem(69);
    height: px2rem(38);
    background: var(--tab1-bcolor) 0% 0% no-repeat padding-box;
    border-radius: px2rem(9) px2rem(9) 0px 0px;
    opacity: 1;
    margin: px2rem(8) 0 0 px2rem(8);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    // border:.1px gray dashed;
  }
  .group-calling-tab {
    width: px2rem(69);
    height: px2rem(38);
    background: var(--tab2-bcolor) 0% 0% no-repeat padding-box;
    border-radius: px2rem(9) px2rem(9) 0px 0px;
    opacity: 1;
    margin: px2rem(8) px2rem(8) 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    // border:.1px gray dashed;
  }
}
.calling-search-bar {
  width: px2rem(260);
  height: px2rem(35);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
  margin: 0 px2rem(8) 0 px2rem(8);
  display: flex;
  align-items: center;
  // border:.1px gray dashed;

  .calling-search-input {
    width: px2rem(214);
    height: px2rem(35);
    border-radius: px2rem(8);
    padding-left: px2rem(12);
    line-height: px2rem(35);
    display: flex;
    align-items: center;
    overflow: auto;
    // border:.1px gray dashed;

    &:focus {
      outline: none;
    }
    &:empty::before {
      content: attr(placeholder);
      width: px2rem(214);
      height: px2rem(35);
      line-height: px2rem(35);
      color: #9D9D9D;
      opacity: 1;
    }
  }
  .calling-search-btn {
    width: px2rem(46);
    height: px2rem(35);
    background: #32385E 0% 0% no-repeat padding-box;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.show-online-bar {
  width: px2rem(260);
  height: px2rem(35);
  padding-top: 0.5rem;
  background: #151B35 0% 0% no-repeat padding-box;
  opacity: 1;
  margin: 0 px2rem(8) 0 px2rem(8);
  display: flex;
  align-items: center;
  justify-content: right;

  .switch {
    display: flex;
    align-items: center;
    margin-right: px2rem(13);
    // height: px2rem(19);

    .label {
      // margin-left: px2rem(20);
      margin-right: px2rem(12);
      padding-left: 0.3rem;
      text-align: left;
      // font: normal normal normal 16px/19px Calibri;
      font-size: px2rem(16);
      line-height: px2rem(19);
      letter-spacing: 0px;
      color: #577590;
      opacity: 1;
    }

    .input {
      display: none;
    }

    .slider {
      position: relative;
      display: inline-block;
      height: px2rem(12);
      width: px2rem(25);
      border-radius: px2rem(20);
      cursor: pointer;
      background: #546267;
      transition: all 0.2s ease;

      &:after {
        background: #fefefe;
        position: absolute;
        left: px2rem(-2);
        top: px2rem(-2);
        display: block;
        width: px2rem(17);
        height: px2rem(17);
        border-radius: 50%;
        box-shadow: 0px px2rem(2) px2rem(2) rgba(0, 0, 0, 0.2);
        content: '';
        transition: all 0.2s ease;
      }
    }

    .input:checked ~ .slider:after {
      left: px2rem(12);
    }
    .input:checked ~ .slider {
      background: #0096c7;
    }
  }
}

// .switch .input:checked ~ .slider:after {
//   left: px2rem(12);
// }
// .switch .input:checked ~ .slider {
//   background: #0096c7;
// }
.device-list {
  width: px2rem(260);
  height: var(--d-height);
  background: #151B35 0% 0% no-repeat padding-box;
  opacity: 1;
  box-sizing: border-box;
  overflow: auto;
  // position: relative;
  margin: 0 px2rem(8) 0 px2rem(8);

  &.container::-webkit-scrollbar {
    width: px2rem(6);
    cursor: pointer;
  }
  &.container::-webkit-scrollbar-track {
    background: #151B35 0% 0% no-repeat padding-box;
    opacity: 1;
  }
  &.container::-webkit-scrollbar-thumb {
    background: #9D9D9D 0% 0% no-repeat padding-box;
    border-radius: 2px;
    opacity: 1;
  }

  // TreeNode
  .custom-tree-node {
    // font: normal normal normal 17px/27px Segoe UI;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    font-size: px2rem(20);
    color: #e9ecef;

    .group-node {
      font-weight: 500;
      color: #FFD133;
    }

    .label {
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
    }

    .count {
      // font: normal normal normal 17px/27px Calibri;
      color: #e9ecef;
      margin-left: px2rem(6);

      span {
        color: #8cc24d;
      }
    }
  }
  // .custom-tree-node .label {
  //   user-select: none;
  //   -moz-user-select: none;
  //   -webkit-user-select: none;
  //   -ms-user-select: none;
  // }
  // .custom-tree-node .count {
  //     // font: normal normal normal 17px/27px Calibri;
  //   color: #e9ecef;
  //   margin-left: 6px;
  // }
  // .custom-tree-node .count span {
  //   color: #8cc24d;
  // }
  .g-phone {
    display: none;
    margin: 0 px2rem(5) 0 px2rem(5);
  }

  .node-contextmenu {
    position: fixed;
    z-index: 4;
    display: flex;
    flex-direction: column;
    // width: px2rem(160);
    padding: 0 0 0.25rem;
    border-radius: px2rem(3);
    border: 1px solid #4A5C78;
    font-size: px2rem(16);
    color: #ffffff;
    background-color: #39425D;
    visibility: hidden;

    &.show {
      visibility: visible;
    }

    .close-contextmenu {
      display: flex;
      align-items: start;
      justify-content: flex-end;
      margin-right: 0.25rem;
      margin-bottom: 0.25rem;
      // background-color: #f00;

      div {
        cursor: pointer;
      }
    }
    .open-account-info {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 0.25rem 0 0.25rem 0;
      padding-right: 1rem;
      padding-bottom: 0.25rem;
      padding-left: 0.5rem;
      cursor: pointer;
      // background-color: #f00;

      &:hover {
        background-color: #4a5c78;
      }

      img {
        margin-right: 0.5rem;
        vertical-align: unset;
      }
    }
  }
}

::v-deep .el-tree {
  background: #151B35 0% 0% no-repeat padding-box;
  min-width: 100%;
  display: inline-block;
}
::v-deep .el-tree-node {
  white-space: normal;
}
::v-deep .el-tree-node__content {
  height: 100%;
  padding: px2rem(6) 0;
  align-items: center;
}
::v-deep .el-tree-node .el-tree-node__children {
  overflow: visible;
}
::v-deep .el-tree-node__label {
  // font-family: 'Segoe UI', 'Noto Sans TC';
  line-height: 1.2;
  font-size: var(--f-size);
  letter-spacing: 0;
  color: #e9ecef;
}
/* 設定點擊才不會閃白底 */
::v-deep .el-tree .el-tree-node .el-tree-node__content {
  background: #151b35;
}
::v-deep .el-tree-node__content .is-leaf {
  color: transparent;
  background: #151b35;
  cursor: default;
}
::v-deep .el-tree-node__content:hover .el-tree-node__expand-icon .is-leaf {
  cursor: default;
}
::v-deep .el-tree-node__expand-icon {
  color: #f3f4f4;
}
::v-deep .el-tree-node__content:hover {
  color: #2a303e;
  background: #151b35;
}
// ::v-deep .el-tree-node__content:hover .el-tree-node__expand-icon {
//   color: #2a303e;
// }
::v-deep .el-tree-node.is-current.is-focusable,
::v-deep .el-tree-node.is-current.is-focusable:hover {
  .el-tree-node__content:has(.is-leaf) {
    color: #2a303e;
    background: #151b35;
    background-color: #4A5C78;

    .el-tree-node__expand-icon,
    .el-tree-node__expand-icon:hover {
      background-color: #4A5C78;
    }
  }
}
::v-deep .el-tree > .el-tree-node.is-expanded > .el-tree-node__content {
  background: #151b35;
}
// ::v-deep
//   .el-tree-node:focus
//   > .el-tree-node__content
//   .el-tree-node__expand-icon {
//    color: #2a303e;
// }
// ::v-deep
//   .el-tree-node.is-current
//   .el-tree-node__content
//   .el-tree-node__expand-icon {
//    color: #2a303e;
// }
::v-deep .el-tree-node > .el-tree-node__content {
  min-height: px2rem(24);
  /* border:.1px gray dashed; */
}
::v-deep .el-tree-node__children > .el-tree-node__content {
  min-height: px2rem(24);
}
::v-deep .el-checkbox .el-checkbox__inner {
  background: #151b35;
  border: 1px solid #ffffff;
  display: inline-block;
  position: relative;
  border-radius: 2px;
  box-sizing: border-box;
  width: px2rem(14);
  height: px2rem(14);
  transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46),
    background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
}
::v-deep .el-checkbox .el-checkbox__input.is-checked .el-checkbox__inner,
::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #ffffff;
  border-color: #ffffff;
}
::v-deep .el-checkbox .el-checkbox__inner::after {
  border: 2px solid #151b35;
  border-left: 0;
  border-top: 0;
  width: px2rem(4);
  height: px2rem(8);
  left: px2rem(3);
  top: 0;
}
::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
  background: #151b35;
}
.online {
  color: #8cc24d !important;
}
::v-deep .el-tree-node > .el-tree-node__content:hover .is-leaf,
::v-deep .el-tree-node > .el-tree-node__content:hover .g-phone,
::v-deep .el-tree-node > .el-tree-node__content:hover {
  height: 100%;
  background: #4A5C78 0% 0% no-repeat padding-box;
  display: flex;
  align-items: center;
}
</style>
