<template>
  <div>
    <portal to="logout-modal">
      <div class="logout-modal" @click="closeModal"></div>
      <div class="logout-wrap">
        <div class="title">
          <div class="icon">
            <i class="fa fa-power-off"></i>
            <!-- <img src="@/assets/icons/account-logout.svg"/> -->
          </div>
          <div>{{ $t('account_logout') }}</div>
        </div>
        <div class="content">
          {{ $t('account_logout_hint') }}
        </div>
        <div class="btm">
          <div class="btn-wrap cancel-btn" @click="closeModal">{{ $t('cancel') }}</div>
          <div class="btn-wrap confirm-btn" @click="logOut">{{ $t('account_logout') }}</div>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: "LogOut",
  methods: {
    ...mapActions(['logout']),
    closeModal() {
      this.$emit('closemodal')
    },
    logOut() {
      try {
        this.closeModal()
        // this.logout()
        this.$router.push('/login')
      }catch(err){
        console.log(err)
      }
    },
  },
}
</script>

<style scoped>
.logout-modal {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(32, 44, 57, 0.6);
  z-index: 10;
}

.logout-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 32%;
  height: 200px;
  padding: 20px;
  border: 1px solid #4A5C78;
  background: #282942;
  border-radius: 3px;
  color: #fff;
  z-index: 10;
}

.title {
  display: flex;
  gap: 5px;
  font-size: 16px;
  font-weight: bold;
}

.title div:nth-child(2) {
  margin-left: 3px;
}

.btm {
  display: flex;
  justify-content: flex-end;
}

.btn-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  width: 100px;
  height: 36px;
  box-sizing: border-box;
  font-size: 14px;
  color: #282942;
}
.btn-wrap:hover {
  background: #ffd231;
}

.confirm-btn {
  margin-left: 5px;
  background-color: #ffc600;
}

.cancel-btn {
  border: 1px solid #ffc600;
  color: #ffc600;
}

.cancel-btn:hover {
  background: rgba(255, 198, 0, 0.2);
}

img {
  width: 20px;
  height: 20px;
}
</style>