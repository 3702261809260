// 設備參數 使用內容

import {
  getResolution,
  formatKiloBitrate,
} from '@/utils/lib.js'

// UI ---
export const enabledIcon = require(`@/assets/icons/success-check.svg`)
export const disabledIcon = require(`@/assets/icons/Error.svg`)
export const deviceModelClass = {
  patrol: 'patrol',
  bovicast: 'bovicast',
  aicam: 'aicam',
  virtual: 'virtual'
}

// Info ---
export const lprOrder = ['lprTw', 'lprJp', 'lprVn'] // 車牌國籍順序
export const videoCodecList = ['H264', 'H265'] // 解碼列表
export const iosBitrateModeList = ['ABR', 'CBR'] // iOS bitrate mode
export const androidBitrateModeList = ['CBR', 'VBR'] // Android bitrate mode
export const cleanupTypeList = [
  0, // 禁止刪檔
  1, // 錄影檔優先刪除
  2, // 辨識事件優先刪除
] // 刪檔順序
export const audioNotifyList = [
  0, // 無
  1, // 警示音
  2, // 語音提示
] // 提示音
export const jpgQualityRange = {
  min: 0.3,
  max: 1,
  step: 0.1
} // JPG畫質
export const cropJpgQualityRange = {
  min: 0.3,
  max: 1,
  step: 0.01,
} // 事件上傳小圖影像品質
export const gpsPostDurations = [
  3, // 3秒
  5,
  10,
  30,
  60 // 1分
] // GPS 上傳時間間隔
export const defQuality = {
  w: 1280,
  h: 720,
  fr: 30,
  br: 3000
}
export const resolutionLimit = {
  min: 200,
  max: 5000
} // 解析度, 寬/高 極大極小值
export const framerateLimit = {
  min: 5,
  max: 30
} // 幀率 極大極小值
export const bitrateLimit = {
  min: 16,
  max: 880 * 1000
} // 位元率(Kbps) 極大極小值

// function ===

// qualityToObj(quality)
// @param quality: String; '640x480@30$500' || '4K@15$10000'
export function qualityToObj(quality) {
  let [resolution, fr, br] = quality.split(/@|\$/)
  const [w, h] = resolution.split(/x/)

  const obj = { fr, br }

  if (h === undefined) {
    obj.resolution = resolution
  } else {
    const resolutionStr = getResolution(w, h)
    obj.resolution = resolutionStr
    obj.w = w
    obj.h = h
  }

  return obj
}
// parseQuality(quality)
// @param quality: String; '640x480@30$500' || '4K@15$10000'
export function parseQuality(quality) {
  const obj = qualityToObj(quality)
  const framerate = `${obj.fr}fps`
  const bitrate = formatKiloBitrate(obj.br)

  return `${obj.resolution} ${framerate} ${bitrate}`
}

// export function switchLabel(value) {
//   return value === true ? 'on' : 'off'
// }

export function switchIcon(value) {
  return value === true ? enabledIcon : disabledIcon
}
