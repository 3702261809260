<template>
  <button class="modal-close-btn" @click="$emit('click')">
    <img src="@/assets/icons/close.svg"/>
  </button>
</template>

<script>

export default {
  name: 'ModalCloseBtn',
  props: {},
}
</script>

<style lang="scss" scoped>
$BtnSize: px2rem(54);
$ImgSize: px2rem(28);
$BtnPad: calc(($BtnSize - $ImgSize) / 2);
* {
  box-sizing: border-box;
}
.modal-close-btn {
  border-radius: 50%;
  padding: $BtnPad;
  height: $BtnSize;
  width: $BtnSize;
  background-color: $color_6E7D93;
  img {
    height: $ImgSize;
    width: $ImgSize;
  }

  &:hover {
    // TODO:沒有更淺的藍色
    background-color: $color_6E7D93;
  }
}
</style>
