<template>
  <transition name="modal">
    <div class="video-editor-wrap">
      <div class="video-editor-modal"></div>
      <div class="video-editor-block">
        <div class="time-lapse-header">
          <div class="time-lapse-title">{{ $t('tree_video_export') }}</div>
          <ModalCloseBtn class="close" @click="$emit('close')"/>
        </div>
        <TabList
          class="time-lapse-tab"
          :tabList="tabList"
          :tabIdx="tabIdx"
          :disabled="false"
          @select="onSelectTab"
        />
        <div class="time-lapse-content">
          <transition>
            <component :is="tabView" :account="account"></component>
          </transition>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState /*, mapActions, mapMutations*/ } from 'vuex'
import ModalCloseBtn from '@/components/Base/ModalCloseBtn.vue'
import TabList from '@/components/Base/TabList.vue'
import TimeLapseTask from '@/components/TimeLapse/TimeLapseTask.vue'
import TimeLapseExport from '@/components/TimeLapse/TimeLapseExport.vue'

const TimeLapseTab = {
  task: 0, // 任務設定
  export: 1 // 任務清單
}

export default {
  name: 'VideoEditor',
  components: { ModalCloseBtn, TabList, TimeLapseTask, TimeLapseExport },
  props: {
    account: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      tabIdx: TimeLapseTab.task,
    }
  },
  computed: {
    ...mapState(['staff', 'permissionV2']),
    tabList() {
      let list = []
      const labels = Object.keys(TimeLapseTab) // ['收看'=out, '發布'=in]
      list = labels.map((label) => {
        return {
          id: TimeLapseTab[label],
          // icon,
          label: this.$t(`time_lapse_${label}`),
          // count: 10
        }
      })

      return list
    },
    tabView() {
      return this.tabIdx === TimeLapseTab.export
        ? 'TimeLapseExport'
        : 'TimeLapseTask'
    }
  },
  watch: {},
  methods: {
    init() {
      // console.log(`[VideoEditor.init] account:`, this.account)
    },
    onSelectTab(tIdx) {
      if (this.tabIdx !== tIdx) {
        this.tabIdx = tIdx
      }
    },
  },
  created() {},
  mounted() {
    this.init()
  },
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
$YGap: px2rem(32);
// $ItemH: px2rem(36);
$MgrW: calc(1220 / 1280 * 100%);
$HeaderH: px2rem(48);
$TabH: px2rem(48);

* {
  box-sizing: border-box;
  margin: 0;
  border: 0;
  padding: 0;
  user-select: none;
}


.video-editor-wrap {
  @include modal-wrap;
  z-index: 4;

  .video-editor-modal{
    @include modal-bg;
  }

  .video-editor-block {
    @include modal-content;
    left: calc((100vw - $MgrW) / 2);
    margin: $YGap auto;
    border: 1px solid $color_4A5C78;
    width: $MgrW;
    height: calc(100vh - $YGap - $YGap/2);
    background-color: $color_282942;
    // background-color: #f00;

    .time-lapse-header {
      position: relative;
      color: $color_FFF;
      height: $HeaderH;
      .time-lapse-title {
        margin-top: px2rem(8);
        margin-left: px2rem(12);
        font-size: 2rem;
        line-height: 1.5;
        @include font_bold;
      }
      .close {
        position: absolute;
        top: calc(-1 * px2rem(27));
        right: calc(-1 * px2rem(27));
      }
    }
    .time-lapse-tab {
      margin-top: px2rem(11); // 12 -1
      border-bottom: 1px solid $color_divider;
      padding-left: px2rem(12);
      height: $TabH;
    }
    .time-lapse-content {
      height: calc(100% - $HeaderH - $TabH);
      // flex-grow: 1;
    }
  }
}

</style>
