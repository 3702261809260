<template>
  <div class="home">
    <LeftBar />
    <router-view />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import LeftBar from '@/components/LeftBar'

export default {
  name: 'Entry',
  components: { LeftBar },
  data() {
    return {}
  },
  mounted() {
    this.fetchDataAfterLogin()
    this.getUser() // for get user lang
  },
  methods: {
    ...mapActions('global', ['fetchDataAfterLogin']),
    ...mapActions(['getUser']),
  },
}
</script>

<style scoped>
.home {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
</style>
