<template>
  <div class="kanban-content" ref="kanban" :style="styleObj">
    <div class="content-wrap" :class="{ 'fade-out': fadeOut }">
      <div class="photo-area">
        <img :src="photoUrl" alt="" @error="onImgErr" />
      </div>
      <div class="content-area">
        <div class="item entry-exit">{{ deviceName }}</div>
        <div class="item msg">訪客您好, 請至警衛室登記</div>
        <div class="item plate">
          <span class="title">車牌</span>
          <span class="value">{{ plate }}</span>
        </div>
        <div class="item time">
          <span class="title">入場時間</span>
          <div class="value">
            <span class="day">{{ enterTime.date }}</span>
            <span class="time">{{ enterTime.time }}</span>
          </div>
        </div>
        <div class="item time">
          <span class="title">現在時間</span>
          <div class="value">
            <span class="day">{{ nowTime.date }}</span>
            <span class="time">{{ nowTime.time }}</span>
          </div>
        </div>
        <!-- <div class="debug">
          <span>{{ `#${event.id}` }}</span>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

import { YYYYMMDDHHmmss } from '@/utils/lib.js'

// 參考 Bootstrap 輪播預設間隔為 5000 毫秒（5秒）
// https://code.z01.com/bootstrap-vue/docs/components/carousel.html
const carouselIntervalMs = 5000 * 2

export default {
  name: 'KanbanAuo',
  components: {},
  props: {
    evtIdx: {
      type: Number,
      default: 0
    },
    event: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      bMounted: false,
      bResize: false,

      now: Date.now(),
      timer: null,
      carouselMs: null,

      fadeOut: false,
      animationSec: 2, // Math.floor(carouselIntervalMs / 1000),
    }
  },
  computed: {
    // UI
    styleObj() {
      const font1Base = 3.3
      const font2Base = 2
      const obj = {
        '--font-size1': `${font1Base}rem`,
        '--font-size2': `${font2Base}rem`,
        '--animation-sec': `${this.animationSec}s`
      }

      if (!this.bMounted) {
        return obj
      }

      if (this.$refs.kanban) {
        const kanbanRect = this.$refs.kanban.getBoundingClientRect()
        // console.log(`[styleObj] (w, h):`, kanbanRect.width, kanbanRect.height)
        const wRatio = kanbanRect.width / 1280
        const hRatio = kanbanRect.height / 720
        const fontRatio = Math.max(wRatio, hRatio)

        return {
          ...obj,
          ...{
            '--font-size1': `${font1Base * fontRatio}rem`,
            '--font-size2': `${font2Base * fontRatio}rem`
          }
        }
      }

      return obj
    },
    nowTime() {
      const [date, time] = moment(this.now).format(YYYYMMDDHHmmss).split(' ')
      return {
        date,
        time
      }
    },
    unknownLprImg() {
      return require(`@/assets/icons/no-image.svg`)
    },
    // data
    enterTime() {
      let now = this.event
        ? moment(this.event.detectTime).format(YYYYMMDDHHmmss)
        : moment(new Date()).format(YYYYMMDDHHmmss)
      const [date, time] = now.split(' ')

      return {
        date,
        time
      }
    },
    photoUrl() {
      return this.event
        ? this.event.photoUrl || this.event.snapshotUrl
        : this.unknownLprImg
    },
    deviceName() {
      if (this.event) {
        const { user } = this.event
        return user?.video?.title || user.info.name || user.id
      }
      return '東出口'
    },
    plate() {
      return this.event
        ? this.event.triggered
          ? this.event.triggered[0].content
          : null
        : null
    }
  },
  watch: {
    evtIdx() {
      // console.log(`[W.evtIdx] event:`, this.event)
      setTimeout(() => {
        this.fadeOut = false
      }, this.animationSec * 1000)
    }
  },
  methods: {
    // data
    createTimer() {
      if (!this.timer) {
        this.timer = setTimeout(() => {
          // 要處理的事情 ---
          const now = Date.now()

          // * 輪播
          if (this.carouselMs === null) {
            this.carouselMs = now
          } else if (Date.now() - this.carouselMs >= carouselIntervalMs) {
            this.fadeOut = true
            this.carouselMs = null

            setTimeout(() => {
              this.$emit('next')
            }, (this.animationSec - 1) * 1000)
          }

          // * 更新時間
          this.now = now

          // 重啟 timer ---
          this.cleanTimer()
          this.createTimer()
        }, 1000)
      }
    },
    cleanTimer() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
    },
    // 事件
    onResize(e) {
      console.log(`[onResize] e.target:`, e.target)
    },
    onImgErr(e) {
      e.target.src = this.unknowFrImg
    }
  },
  // created() {},
  mounted() {
    this.createTimer()
    this.bMounted = true
  },
  beforeDestroy() {
    this.cleanTimer()
    this.bMounted = false
  }
}
</script>

<style lang="scss" scoped>
$auo-padding: 3rem;
$auo-blue: #005087;
$auo-energetic-yellow: #ffe364;

$msg-h: 30%;
$plate-h: 30%;

* {
  box-sizing: border-box;
  user-select: none;
}

.kanban-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: $auo-padding;
  width: 100vw;
  height: 100vh;
  background-color: $auo-blue;
  // background-color: #f00;

  .content-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - $auo-padding * 2);
    height: calc(100% - $auo-padding * 2);
    font-size: var(--font-size1);
    opacity: 1;
    transition: opacity var(--animation-sec);
    // background-color: #00f;

    &.fade-out {
      opacity: 0;
    }

    .photo-area,
    .content-area {
      width: 50%;
      height: 100%;
    }

    .photo-area {
      // background-color: purple;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        background-color: $color_black;
      }
    }

    .content-area {
      display: flex;
      flex-direction: column;
      padding-right: 2rem;
      padding-left: 2rem;
      // background-color: pink;
      .item {
        // height: calc(100% / 4);
        &.msg {
          height: $msg-h;
          color: $auo-energetic-yellow;
        }
        &.plate {
          height: $plate-h;
          .value {
            padding-left: 1rem;
          }
        }

        &.entry-exit {
          color: $color_black;
        }

        &.time {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          height: calc((100% - $msg-h - $plate-h) / 2);
          font-size: var(--font-size2);

          .value {
            .time {
              margin-left: 0.5rem;
            }
          }
        }
      }
    }
  }
  .debug {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    height: $auo-padding;
    font-size: 1rem;
    color: $color_FFF_50;
  }
}
</style>
