<template>
  <fragment>
    <PropertyItem :title="$t('device_param_video_publish' /*發布*/)" />
    <PropertyItemEdit
      v-if="bEdit"
      :label="$t('device_param_video_auto_start' /*自動發布*/)"
    >
      <template #property-value>
        <ToggleSwitch
          class="switch"
          v-model="isAutoStart"
          :id="'isAutoStart'"
        />
      </template>
    </PropertyItemEdit>
    <PropertyItem
      v-else
      :label="$t('device_param_video_auto_start' /*自動發布*/)"
      :value="switchLabel(isAutoStart)"
      :valueIcon="switchIcon(isAutoStart)"
    />
    <PropertyItemEdit
      v-if="bEdit"
      :label="$t('device_param_video_bitrate_mode' /*位元控制率模式*/)"
    >
      <template #property-value>
        <SingleSelect
          class="drop-down"
          :paddingX="paddingX"
          :disabled="false"
          v-model="bitrateModeIndex"
          :options="bitrateModeList"
        />
      </template>
    </PropertyItemEdit>
    <PropertyItem
      v-else
      :label="$t('device_param_video_bitrate_mode' /*位元控制率模式*/)"
      :value="valueLabel(bitrateModeList, bitrateModeIndex)"
    />
  </fragment>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} from 'vuex'

import {
  switchIcon,
} from '@/utils/parameter.js'

import PropertyItem from '@/components/AccountManagement/tools/PropertyItem.vue'
import PropertyItemEdit from '@/components/AccountManagement/tools/PropertyItemEdit.vue'
import ToggleSwitch from '@/components/tools/ToggleSwitch.vue'
import SingleSelect from '@/components/Base/SingleSelect.vue'
// import DeviceParamInputRange from '@/components/DeviceParam/DeviceParamInputRange.vue'

export default {
  name: 'DeviceParamVideoBovicast',
  components: {
    PropertyItem,
    PropertyItemEdit,
    ToggleSwitch,
    SingleSelect,
  },
  props: {
    bEdit: {
      type: Boolean,
      default: false
    },
    account: {
      type: Object,
      default: null
    },
    config: {
      type: Object,
      default: null
    },
    // ui
    paddingX: {
      type: Number,
      default: 20
    }
  },
  data() {
    return {
      // ...mapState([]),
      isAutoStart: false,
      bitrateModeIndex: 0,
    }
  },
  computed: {
    ...mapGetters([]),
    ...mapState([]), // state 放這裡可以維持唯一性, 不能用 this.comp = xxx 做修改
    // data ---
    setting() {
      if (!this.config) return null

      return this.config.setting
    },
    deviceConfig() {
      if (!this.setting) return null

      return this.setting.deviceConfig
    },
    publish() {
      if (!this.deviceConfig) return null

      return this.deviceConfig.publish
    },
    bitrateModeList() { // 位元率控制模式清單
      // console.log(`[VideoPatrol.bitrateModeList] publish:`, JSON.stringify(this.publish, '', 2))
      if (!this.publish || !this.publish.bitrateModeList) return []

      return this.publish.bitrateModeList.map((item, idx) => {
        return {
          id: idx,
          label: item,
          value: idx,
          disabled: false,
          // icon: this.enabledIcon
        }
      })
    },
  },
  watch: {},
  methods: {
    ...mapActions([]), // API query and update
    ...mapMutations([]),
    // ui
    valueLabel(list, value) {
      if (!list || list.length <= 0) return this.$t('no_data')

      const option = list.find((_o) => _o.value === value)
      return option ? option.label : ''
    },
    switchLabel(value) {
      return value === true ? this.$t('on') : this.$t('off')
    },
    switchIcon,
    // 資料
    init() {
      // console.log(`[VideoPatrol.init] config:`, JSON.stringify(this.config, '', 2))
      // console.log(`[VideoPatrol.init] setting:`, JSON.stringify(this.setting, '', 2))
      // console.log(`[VideoPatrol.init] deviceConfig:`, JSON.stringify(this.deviceConfig, '', 2))
      if (!this.publish) return

      const { isAutoStart, bitrateModeIndex } = this.publish

      this.isAutoStart = isAutoStart ? true : false
      this.bitrateModeIndex = bitrateModeIndex
    },
    rollback() {
      this.init()
    },
    // 事件
    get() {
      return {
        isAutoStart: this.isAutoStart ? 1 : 0,
        bitrateModeIndex: this.bitrateModeIndex,
      }
    }
  },
  created() {},
  mounted() {},
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
$LeftRightPadding: px2rem(20);

* {
  box-sizing: border-box;
  // user-select: none;
}
.switch {
  margin-left: $LeftRightPadding;
}
.drop-down {}
.slider {}
</style>
